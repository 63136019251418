import { React, useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import config from '../config';
import DjangoAPIClient from '../client';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ReactComponent as UserIcon } from '../assets/icon04_user.svg';
import { FaAngleLeft } from "react-icons/fa";

const client = new DjangoAPIClient(config);

// props.userData, props.goBackButton, props.loggingButtons
const SFNavbar = (props) => {
    const navigate = useNavigate();

    const goBackButton = props.goBackButton ?? false;
    const loggingButtons = props.loggingButtons ?? false;

    const toLoginPage = () => {
        navigate("/login");
    };

    const toSignupPage = () => {
        navigate("/register");
    };

    const toChangePasswordPage = () => {
        navigate("/change-password");
    };

    const toUserPage = () => {
        navigate("/user-profile");
    };

    const handleLogout = () => {
        client.logout();
        navigate("/");
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <Navbar bg="white" expand="md" className="rounded-top px-3 py-0" style={{ margin: "0 -12px"}}>
                <Container fluid className="border-bottom py-2" style={{minHeight: "50px"}}>
                    {goBackButton &&
                        <Nav className="me-auto" navbarScroll>
                            <Button variant="none" className="btn-dark d-flex align-items-center go-back" onClick={goBack}>
                                <FaAngleLeft />
                                Go back
                            </Button>
                        </Nav>
                    }
                    {!goBackButton && <Nav></Nav>}
                    {!props.userData && loggingButtons && <>
                        <div className="d-flex justify-content-end">
                            <Button onClick={toLoginPage} variant="dark" className="me-3">
                                Log in
                            </Button>
                            <Button onClick={toSignupPage} variant="dark">
                                Sign up
                            </Button>
                        </div>
                    </>}
                    {props.userData && loggingButtons && <>
                        <div className="d-flex">
                            <NavDropdown title={<span>{props.userData.first_name} <UserIcon /></span>} id="navbarScrollingDropdown" className="user-icon">
                                <NavDropdown.Item onClick={toUserPage}>Profile page</NavDropdown.Item>
                                {/* <NavDropdown.Item onClick={toUserFiles}>My Files</NavDropdown.Item> */}
                                <NavDropdown.Item onClick={toChangePasswordPage}>Change password</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>
                                    Log out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div></>
                    }
                </Container>
            </Navbar>
        </>
    );
};

export default SFNavbar;
