import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import DeleteIconWithWarning from '../../components/DeleteIconWithWarning';
import PaginationComponent from '../../components/ApiPagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { ReactComponent as TaskIcon } from '../../assets/icon06_tasks.svg';
import { ReactComponent as EditIcon } from '../../assets/icon08_edit.svg';
import { ReactComponent as DeleteIconOff } from '../../assets/icon09_delete_off.svg';
import { ReactComponent as EditIconOff } from '../../assets/icon08_edit_off.svg';


// props.client -> DjangoAPIClient
// props.setProject -> function to set project
// props.refreshProjects -> useEffect() with refresh projects
// props.setShowManageModal -> useEffect() with show/hide modal

const ProjectsTable = (props) => {
	const navigate = useNavigate();

	const [initialRender, setInitialRender] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [dataTable, setDataTable] = useState([]);
	const [dataSize, setDataSize] = useState(0);
	const [requestData, setRequestData] = useState(true);

	const [activePage, setActivePage] = useState(1);
	const [sortColumn, setSortColumn] = useState("creation_date");
	const [sortDirection, setSortDirection] = useState("-");
	const [filters, setFilters] = useState({
		name: '',
		group: '',
		creation_date: ''
	});

	useEffect(() => {
		if (requestData){
			fetchdataTable(setIsLoading, setDataTable, setDataSize);
		}
	}, [activePage, sortColumn, sortDirection, filters, requestData, props.refreshData]);

	function fetchdataTable(setIsLoading, setDataTable, setDataSize) {
		setIsLoading(true);
		props.client.getMyProjects(activePage, sortColumn, sortDirection, filters)
			.then(
				response => {
					setDataSize(response.data.count);
					setDataTable(response.data.results);
				}
			)
	}

	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
		} else {
			setIsLoading(false);
		}
	}, [dataTable]);

	function handleSort(column) {
		setActivePage(1);
		if (sortColumn === column) {
			// If the same column is clicked, reverse the sort direction
			setSortDirection((prevSortDirection) =>
				prevSortDirection === "+" ? "-" : "+"
			);
		} else {
			// If a different column is clicked, set it as the new sort column
			setSortColumn(column);
			setSortDirection("+");
		}
	}

	const handleKeyDown = (event, setRequestData) => {
		if (event.key === 'Enter') {
			setRequestData(true);
		}
	}

	function deleteProject(projectId) {
		setIsLoading(true)
		props.client.deleteProject(projectId)
			.then(response => fetchdataTable(setIsLoading, setDataTable, setDataSize))
			.catch(error => console.log(error));
	}

	return (
		<><Table striped bordered hover responsive className='align-middle'>
			<thead>
				<tr>
					<th onClick={() => handleSort("name")} className='sort-arrow'>
						Project Name
						<span>
							{sortColumn === "name" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "name" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "name" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("group")} className='sort-arrow'>
						Group
						<span>
							{sortColumn === "group" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "group" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "group" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("creation_date")} className='sort-arrow'>
						Creation date
						<span className="sort-arrow">
							{sortColumn === "creation_date" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "creation_date" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "creation_date" && <FaSort />}
						</span>
					</th>
					{/* <th colSpan="2" onClick={() => handleSort("spiders_count")} className='sort-arrow'> */}
					<th colSpan="2">
						Spiders
						{/* <span className="sort-arrow">
							{sortColumn === "spiders_count" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "spiders_count" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "spiders_count" && <FaSort />}
						</span> */}
					</th>
					<th>Edit</th>
					<th>Delete</th>
				</tr>
				<tr>
					<th>
						<Form.Control
							type="text"
							id="filter-name"
							placeholder="Filter by name"
							value={filters.name}
							onBlur={(e) => {setActivePage(1); setRequestData(true)}}
							onChange={(e) => {setRequestData(false); setFilters({...filters, name: e.target.value})}}
							onKeyDown={(e) => handleKeyDown(e, setRequestData)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-group"
							placeholder="Filter by group"
							value={filters.group}
							onBlur={(e) => {setActivePage(1); setRequestData(true)}}
							onChange={(e) => {setRequestData(false); setFilters({...filters, group: e.target.value})}}
							onKeyDown={(e) => handleKeyDown(e, setRequestData)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-group"
							placeholder="Filter by date and time"
							value={filters.creation_date}
							onBlur={(e) => {setActivePage(1); setRequestData(true)}}
							onChange={(e) => {setRequestData(false); setFilters({...filters, creation_date: e.target.value})}}
							onKeyDown={(e) => handleKeyDown(e, setRequestData)}
						/>
					</th>
					<th colSpan="2"></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{isLoading && <tr><td colSpan="7" className="text-center">Loading...</td></tr>}
				{!isLoading && dataSize === 0 && <tr><td colSpan="7" className="text-center">No projects found</td></tr>}
				{!isLoading && dataTable.map((project, index) => {
					return (
						<tr key={index}>
							<td>
								{project.name}
							</td>
							<td>{project.group_name}</td>
							<td>
								<OverlayTrigger
									placement="top"
									delay={{ hide: 250 }}
									overlay={
										<Tooltip>
											Created by {project.user_email.split('@')[0]} 
										</Tooltip>
									}
								>
									<a>{project.creation_date}</a>
								</OverlayTrigger>
							</td>
							<td>{project.spiders_count} spiders</td>
							<td>
								<Button
									variant='none'
									size="md"
									type="button"
									onClick={(e) => {
										props.setProject(project);
										navigate('/my-spiders');
									}}
								>
									{<TaskIcon className='table-icon' />}
								</Button>
							</td>
							<td>
								{props.userId === project.user &&
									<Button
										size="md"
										variant='none'
										type="button"
										className='rounded-pill'
										onClick={(e) => {
											props.setProject(project);
											setTimeout(() => {
												props.setShowManageModal(true)
											}, 0);
											;
										}}
									>
										{<EditIcon className='table-icon' />}
									</Button>
								}
								{props.userId !== project.user &&
									<OverlayTrigger
										placement="left"
										delay={{ hide: 250 }}
										overlay={
											<Tooltip id="button-tooltip-2">
												You don't own this project
											</Tooltip>
										}
									>
										<EditIconOff className='table-icon' />
									</OverlayTrigger>
								}
							</td>
							<td>
								{props.userId === project.user &&
									<DeleteIconWithWarning
										handleDelete={(e) => deleteProject(project.id)}
										warningBodyText={
											<a>You are about to delete the <b>{project.name}</b> project and all of it's spiders. Do you want to continue?</a>
										}
									/>
								}
								{props.userId !== project.user &&
									<OverlayTrigger
										placement="left"
										delay={{ hide: 250 }}
										overlay={
											<Tooltip>
												You don't own this project
											</Tooltip>
										}
									>
										<DeleteIconOff className='table-icon' />
									</OverlayTrigger>
								}
							</td>
						</tr>
					)
				}
				)}
			</tbody>
		</Table>
			<Col>
				<PaginationComponent
					pageLimit={props.maxPagination}
					countAllResults={dataSize}
					activePage={activePage}
					setActivePage={setActivePage}
				/>
			</Col></>
	)
}

export default ProjectsTable;