import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Navbar from '../../components/Navbar';


const ActivateAccountMessage = () =>{
    const navigate = useNavigate();
    const [title] = useState("Scrapfinderlab - activate your email message");

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);

    return(
        <Container fluid className="App bg-light-pf23 rounded-bottom min-vh-100">
            <Navbar></Navbar>
            <Row className="App-header justify-content-center align-items-center mt-5">
                <Col sm={12} md={8}>
                    <h3>You should receive an email soon with activation link. Once you click on the links your account will be activated automatically.</h3>
                    <Button  variant='pf23 my-3 w-25 mx-auto' onClick={(e)=> navigate("/")} className='mt-3'>Go to homepage</Button>
                    {/* <h4>Once you confirm your email, our team will process your request and account activation.</h4> */}
                </Col>
            </Row>
        </Container>
    )
}

export default ActivateAccountMessage;