import React from "react";
import { useEffect , useState, useContext} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';


// props.currentText
// props.setCurrentText

const InputTextEdit = (props) => {
    const [showEdit, setShowEdit] = useState(false);
    const [newText, setNewText] = useState(props.currentText);

    useEffect(()=>{
        setNewText(props.currentText)
    }, [props.currentText])

    return (
        <Container>
            {!showEdit && 
                <Row className="d-flex align-items-center">
                    <Col md={7}>
                        <Form>
                            <Form.Group>
                                    <Form.Control 
                                        type="text"
                                        className="rounded-pill"
                                        placeholder={props.currentText}
                                        disabled
                                        />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col md={5}>
                        <Button
                            variant="dark"
                            size="md"
                            className="w-50 rounded-pill"
                            onClick={(e) => {setShowEdit(true); console.log(newText)}}
                        >
                            Edit
                        </Button>
                    </Col>
                </Row>
            }
            {showEdit && 
                <Row className="d-flex align-items-center">
                    <Col sm={7}>
                        <Form>
                            <Form.Group>
                                    <Form.Control
                                        type="text"
                                        className="rounded-pill"
                                        placeholder={newText}
                                        value={newText}
                                        onChange={(e) => setNewText(e.target.value)}
                                        />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col sm={5}>
                        <Button 
                            variant="modal"
                            size="md"
                            className="w-50 rounded-pill"
                            onClick={(e) => {
                                props.setCurrentText(newText)
                                setShowEdit(false)
                            }}>
                            Save
                        </Button>
                        <Button 
                            variant="dark" 
                            className="ms-2 rounded-pill"
                            size="md"
                            onClick={(e) => {
                                setNewText(props.currentText)
                                setShowEdit(false)
                            }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default InputTextEdit;
