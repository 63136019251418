import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import DeleteIconWithWarning from '../../components/DeleteIconWithWarning';
import PaginationComponent from '../../components/ApiPagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ReactComponent as TaskIcon } from '../../assets/icon06_tasks.svg';
import { ReactComponent as EditIcon } from '../../assets/icon08_edit.svg';
import { ReactComponent as DeleteIconOff } from '../../assets/icon09_delete_off.svg';
import { ReactComponent as EditIconOff } from '../../assets/icon08_edit_off.svg';


// props.client,
// props.filesType
// props.groupNameFilters
// props.maxPagination

const FilesTable = (props) => {
    const [initialRender, setInitialRender] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [dataTable, setDataTable] = useState([]);
	const [dataSize, setDataSize] = useState(0);
	const [requestData, setRequestData] = useState(true);

	const [activePage, setActivePage] = useState(1);
	const [sortColumn, setSortColumn] = useState("creation_date");
	const [sortDirection, setSortDirection] = useState("-");
	const [filters, setFilters] = useState({
		name: '',
		project: '',
		creation_date: '',
        update_date: '',
        is_team: props.groupNameFilters.includes('My team files') ? true : false,
        is_private: props.groupNameFilters.includes('My files') ? true : false,
        is_public: props.groupNameFilters.includes('Public files') ? true : false,
	});

    useEffect(() => {
		setIsLoading(true);
		setActivePage(1);
		setRequestData(true);
		setFilters({...filters, 
            is_team: props.groupNameFilters.includes('My team files') ? true : false,
            is_private: props.groupNameFilters.includes('My files') ? true : false,
            is_public: props.groupNameFilters.includes('Public files') ? true : false,
        });
	}, [props.groupNameFilters]);

	useEffect(() => {
		if (requestData & props.selectedKey === props.filesType){
			fetchdataTable(setIsLoading, setDataTable, setDataSize);
		}
	}, [activePage, sortColumn, sortDirection, filters, requestData, props.refreshData, props.selectedKey]);

	function fetchdataTable(setIsLoading, setDataTable, setDataSize) {
		setIsLoading(true);
		props.client.getUserFiles(props.filesType, activePage, sortColumn, sortDirection, filters)
			.then(
				response => {
					setDataSize(response.data.count);
					setDataTable(response.data.results);
				}
			)
	}

	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
		} else {
			setIsLoading(false);
		}
	}, [dataTable]);

	function handleSort(column) {
		setActivePage(1);
		if (sortColumn === column) {
			// If the same column is clicked, reverse the sort direction
			setSortDirection((prevSortDirection) =>
				prevSortDirection === "+" ? "-" : "+"
			);
		} else {
			// If a different column is clicked, set it as the new sort column
			setSortColumn(column);
			setSortDirection("+");
		}
	}

	const handleKeyDown = (event, setRequestData) => {
		if (event.key === 'Enter') {
			setRequestData(true);
		}
	}

    function handleDeleteFile(fileType, fileId, setIsLoading, setDataTable, setDataSize) {
        setIsLoading(true);
        props.client.deleteUserFile(fileType, fileId)
            .then((response) => {
                fetchdataTable(setIsLoading, setDataTable, setDataSize);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

	return (
		<>
            <Table striped bordered hover responsive className='align-middle files-table'>
            <thead>
                <tr>
                <th onClick={() => handleSort('project')} className='sort-arrow'>
                    Project
                    <span>
                        {sortColumn === 'project' && sortDirection === '+' && <FaSortUp />}
                        {sortColumn === 'project' && sortDirection === '-' && <FaSortDown />}
                        {sortColumn !== 'project' && <FaSort />}
                    </span>
                </th>
                <th onClick={() => handleSort('file')} className='sort-arrow'>
                    Filename
                    <span>
                        {sortColumn === 'file' && sortDirection === '+' && <FaSortUp />}
                        {sortColumn === 'file' && sortDirection === '-' && <FaSortDown />}
                        {sortColumn !== 'file' && <FaSort />}
                    </span>
                </th>
                <th onClick={() => handleSort('creation_date')} className='sort-arrow'>
                    Creation date
                    <span>
                        {sortColumn === 'creation_date' && sortDirection === '+' && <FaSortUp />}
                        {sortColumn === 'creation_date' && sortDirection === '-' && <FaSortDown />}
                        {sortColumn !== 'creation_date' && <FaSort />}
                    </span>
                </th>
                <th onClick={() => handleSort('update_date')} className='sort-arrow'>
                    Update date
                    <span>
                        {sortColumn === 'update_date' && sortDirection === '+' && <FaSortUp />}
                        {sortColumn === 'update_date' && sortDirection === '-' && <FaSortDown />}
                        {sortColumn !== 'update_date' && <FaSort />}
                    </span>
                </th>
                <th className='button-column'>Download</th>
                <th className='button-column'>Delete</th>
                </tr>
                <tr>
                <th>
                    <Form.Control
                        size='sm'
                        type="text"
                        placeholder="Filter by project"
                        value={filters.project}
                        onBlur={(e) => {setActivePage(1); setRequestData(true)}}
                        onChange={(e) => {setRequestData(false); setFilters({...filters, project: e.target.value})}}
                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                    />
                </th>
                <th>
                    <Form.Control
                        size='sm'
                        type="text"
                        placeholder="Filter by filename"
                        value={filters.file}
                        onBlur={(e) => {setActivePage(1); setRequestData(true)}}
                        onChange={(e) => {setRequestData(false); setFilters({...filters, file: e.target.value})}}
                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                    />
                </th>
                <th>
                    <Form.Control
                        size='sm'
                        type="text"
                        placeholder="Filter by date"
                        value={filters.creation_date}
                        onBlur={(e) => {setActivePage(1); setRequestData(true)}}
                        onChange={(e) => {setRequestData(false); setFilters({...filters, creation_date: e.target.value})}}
                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                    />
                </th>
                <th>
                    <Form.Control
                        size='sm'
                        type="text"
                        placeholder="Filter by date"
                        value={filters.update_date}
                        onBlur={(e) => {setActivePage(1); setRequestData(true)}}
                        onChange={(e) => {setRequestData(false); setFilters({...filters, update_date: e.target.value})}}
                        onKeyDown={(e) => handleKeyDown(e, setRequestData)}
                    />
                </th>
                <th></th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {isLoading && <tr><td colSpan="6" className="text-center">Loading...</td></tr>}
				{!isLoading && dataSize === 0 && <tr><td colSpan="6" className="text-center">No projects found</td></tr>}
                {!isLoading && dataTable.map((file, index) => (
                    <tr key={index}>
                        <td>{file.project}</td>
                        <td>{file.file.split('/').pop()}</td>
                        <td>
                            <OverlayTrigger
								placement="top"
                                delay={{ hide: 250 }}
                                overlay={
                                    <Tooltip>
                                        Created by {file.user_email ? file.user_email.split('@')[0]: ""} 
                                    </Tooltip>
                                }
                            >
                                <a>{file.creation_date}</a>
                            </OverlayTrigger>
                        </td>
                        <td>
                            <OverlayTrigger
								placement="top"
                                delay={{ hide: 250 }}
                                overlay={
                                    <Tooltip>
                                        Last updated by {file.updated_by ? file.updated_by.split('@')[0]: ""} 
                                    </Tooltip>
                                }
                            >
                                <a>{file.update_date}</a>
                            </OverlayTrigger>
                        </td>
                        <td>
                            <a href={file.file} className='text-dark download-icon'>
                                <FaCloudDownloadAlt />
                            </a>
                        </td>
                        <td>
                            {props.userId === file.user &&
                                <DeleteIconWithWarning
                                    handleDelete={(e) => handleDeleteFile(props.filesType, file.id, setIsLoading, setDataTable, setDataSize)}
                                    warningBodyText={
                                        <a>You are about to delete the file <b>{file.file.split('/').pop()}</b> from project <b>{file.project}</b>. Do you want to continue?</a>
                                    }
                                />}
                            {props.userId !== file.user && 
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ hide: 250 }}
                                    overlay={
                                        <Tooltip>
                                            You don't own this file
                                        </Tooltip>
                                    }
                                >
                                    <DeleteIconOff className='table-icon' />
                                </OverlayTrigger>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
            </Table>
            <Col>			
                <PaginationComponent
					pageLimit={props.maxPagination}
					countAllResults={dataSize}
					activePage={activePage}
					setActivePage={setActivePage}
				/>
            </Col>
        </>
    )
}

export default FilesTable;

