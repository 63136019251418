import React from "react";
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';


const OutputTable = (props) => {
    const [outputData, setOutputData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        try {
            setOutputData(props.outputData.map(({ _id, ...item }) => item));
        } catch (error) {
            setError(true);
        }
    }, [props.outputData]);

    useEffect(() => {
        try {
            setTableColumns(getColumns(outputData));
        } catch (error) {
            setError(true);
        }
    }, [outputData]);

    function getColumns(data) {
        const keys = Object.keys(Object.assign({}, ...data));
        const columns = keys.map((key, id) => {
            return {
                Header: key,
                accessor: key
            }
        });
        return columns
    }

    return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {tableColumns.map(column => (
                            <th key={column.Header}>{column.Header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {outputData.slice(0, 5).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                      {tableColumns.map(column => (
                        <td key={column.accessor} className={typeof row[column.accessor] === 'string' && row[column.accessor].length > 600 ? 'truncated-cell' : ''}>
                          {typeof row[column.accessor] === 'object' ? JSON.stringify(row[column.accessor]) : row[column.accessor]}
                        </td>
                      ))}
                    </tr>
                    ))}
                </tbody>
            </Table>
    )
}

export default OutputTable;
