import { React, useState, useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ItemsDropdown from "../ItemsDropdown";

// props.client -> DjangoAPIClient
// props.setProject -> function to set project
// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.setShowSuccessModal -> function to set show/hide success modal


const CreateProjectModal = (props) => {
    const [error, setError] = useState({ name: "", group: "" })

    const [newProjectName, setNewProjectName] = useState('');
    const [isProjectShared, setIsProjectShared] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({ id: 1, name: "Please select a group" })

    useEffect(() => {
        props.client.getUserGroups()
            .then(response => {
                const groups = response.data.filter(group => group.name != "Private" && group.name.startsWith("EXTERNAL_") === false);
                setGroups(groups);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);
    
    useEffect(() => {
        if (!props.showModal) {
            closeModal(props.setShowModal, setNewProjectName, setSelectedGroup, setIsProjectShared);
        }
    }, [props.showModal]);

    function handleSwitchChange(e, setIsProjectShared, setSelectedGroup) {
        setIsProjectShared(e.target.checked);
        setSelectedGroup({id: 1, name: "Please select a group"});
    }

    function closeModal(setShowModal, setNewProjectName, setSelectedGroup, setIsProjectShared) {
        setNewProjectName("");
        setSelectedGroup({id: 1, name: "Please select a group"});
        setIsProjectShared(false);
        setShowModal(false);
    }

	function handleProjectCreate(client, newProjectName, is_shared, group, setProjectContext, setShowModal, setShowSuccessModal, setError) {
		client.createNewProject(newProjectName, is_shared, group.id)
			.then(response => {
				setProjectContext({ id: response.data.id, name: response.data.name });
                setShowModal(false);
                setShowSuccessModal(true);
			})
			.catch(error => {
				console.log(error);
				setError(error.response.data);
			});
	}

    return (
        <Modal show={props.showModal} backdrop="static" onHide={(e) => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="formNewProjectName">
                        <Form.Control 
                            className="w-100"
                            type="text"
                            placeholder="Project name"
                            value={newProjectName}
                            isInvalid={!!error.name}
                            onChange={(e) => setNewProjectName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error.name}
                            {error.group}
                        </Form.Control.Feedback>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            checked={isProjectShared}
                            className="my-2"
                            label="Share this project with others"
                            onChange={(e) => handleSwitchChange(e, setIsProjectShared, setSelectedGroup)}
                        />
                        {isProjectShared && (
                            <ItemsDropdown
                                items={groups}
                                selected={selectedGroup}
                                setSelected={setSelectedGroup}
                                attr={"name"}
                            />
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="modal"
                    onClick={(e) => 
                        handleProjectCreate(props.client, newProjectName, isProjectShared, selectedGroup, props.setProject, props.setShowModal, props.setShowSuccessModal, setError)
                    }
                >
                    Create
                </Button>
                <Button
                    variant="dark"
                    onClick={(e) => props.setShowModal(false)}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default CreateProjectModal;