import React, { useState, useEffect, useContext } from 'react';
import ProjectContext from '../components/contexts/ProjectContext';
// import SpiderContext from '../components/contexts/SpiderContext';

import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import config from '../config';
import DjangoAPIClient from '../client';
import ItemsDropdown from '../components/ItemsDropdown';
import HomepageFooter from '../components/HomepageFooter'

import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const client = new DjangoAPIClient(config);

const Dashboard = ({ title, userData }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    const [projectContext, setProjectContext] = useContext(ProjectContext);

    const [myProjects, setMyProjects] = useState([]);
    const [dropdownLoading, setDropdownLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [copied, setCopied] = useState(false);
    const [last_7_days, setLast7Days] = useState(true);
    const [last_14_days, setLast14Days] = useState(false);
    const [last_30_days, setLast30Days] = useState(false);
    const [error, setError] = useState(null);

    function fetchDashboardData(last_7_days, last_14_days, last_30_days, project_id) {
        setIsLoading(true);
        setDropdownLoading(true);
        client.getDashboardData(last_7_days, last_14_days, last_30_days, project_id)
            .then((response) => {
                setData(response.data);
                setError(null);
            })
            .catch((error) => {
                console.error('Error fetching dashboard data:', error);
                setData([]);
                setIsLoading(false);
                setDropdownLoading(false);
                if (error.response && (error.response.status === 500 || error.response.status === 404)) {
                    setError(error.response.data.detail || `Error ${error.response.status}: ${error.response.statusText}`);
                } else {
                    setError('An unexpected error occurred.');
                }
            });
    }

    useEffect(() => {
        fetchDashboardData(last_7_days, last_14_days, last_30_days, projectContext.id);
    }, [projectContext.id]);

    useEffect(() => {
        if (data.task_results === undefined) {
            return;
        }
        setIsLoading(false);
        setDropdownLoading(false);
    }, [data]);

    function fetchMyProjects() {
        setDropdownLoading(true);
        client.getDropdownProjects()
            .then(
                (response) => {
                    const projects = response.data.filter(function (el) { return el.name != "Generic spiders"; });
                    projects.unshift({ id: 0, name: "All projects" })
                    setMyProjects(projects);
                    if (projectContext.id === 0) {
                        setProjectContext({ id: 0, name: "All projects" });
                    }
                    setDropdownLoading(false);
                }
            )
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        fetchMyProjects()
    }, [])

    const getColumnCount = () => {
        if (data.task_results === undefined) {
            return 0;
        }

        const dateCount = data.task_results.reduce((allDates, result) => {
            result.dates.forEach((date) => {
                if (!allDates.includes(date.done_at)) {
                    allDates.push(date.done_at);
                }
            });
            return allDates;
        }, []).length;

        return dateCount + 1;
    }

    const prepareChartData = () => {
        if (!data.task_results) return [];
    
        const chartData = [];
        const uniqueDates = new Set();
    
        data.task_results.forEach((result) => {
            result.dates.forEach((date) => {
                const formattedDate = new Date(date.done_at.split("-").reverse().join("-")).toISOString().split('T')[0];
    
                if (!uniqueDates.has(formattedDate)) {
                    uniqueDates.add(formattedDate);
    
                    const successDataPoint = {
                        type: 'stackedColumn',
                        showInLegend: false,
                        color: '#93C572',
                        name: 'Success',
                        dataPoints: [{
                            x: new Date(formattedDate),
                            y: date.success_count,
                            toolTipContent: `<b>Success</b>: ${date.success_count} tasks`,
                        }],
                    };
    
                    const failedDataPoint = {
                        type: 'stackedColumn',
                        showInLegend: false,
                        color: '#EE4B2B',
                        name: 'Failed',
                        dataPoints: [{
                            x: new Date(formattedDate),
                            y: date.failed_count,
                            toolTipContent: `<b>Failed</b>: ${date.failed_count} tasks`,
                        }],
                    };
    
                    chartData.push(successDataPoint);
                    chartData.push(failedDataPoint);
                }
            });
        });
        return chartData;
    };
    
    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <Row className='pt-3 pb-2'>
                    <Col>
                        <Card className='text-center'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>Welcome to Scrapfinderlab!</h1>
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='pb-2 user-data'>
                    <Col sm={6} md={3} className='py-1'>
                        <Card>
                            <Link role='button' to="/my-projects" className='text-decoration-none text-dark'>
                                <Card.Body>
                                    <Card.Title>{data.number_of_projects}</Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    Total number of projects created
                                </Card.Footer>
                            </Link>
                        </Card>
                    </Col>
                    <Col sm={6} md={3} className='py-1'>
                        <Card>
                            <Link role='button' to="/my-spiders" className='text-decoration-none text-dark'>
                                <Card.Body>
                                    <Card.Title>{data.number_of_spiders}</Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    Total number of spiders created
                                </Card.Footer>
                            </Link>
                        </Card>
                    </Col>
                    <Col sm={6} md={3} className='py-1'>
                        <Card>
                            <Link role='button' to="/my-tasks" className='text-decoration-none text-dark'>
                                <Card.Body>
                                    <Card.Title>{data.number_of_user_tasks}</Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    Total number of ran tasks
                                </Card.Footer>
                            </Link>
                        </Card>
                    </Col>
                    <Col sm={6} md={3} className='py-1'>
                        <Card>
                            <Link role='button' to="/my-tasks" className='text-decoration-none text-dark'>
                                <Card.Body>
                                    <Card.Title>{data.number_of_active_tasks}</Card.Title>
                                </Card.Body>
                                <Card.Footer>
                                    Number of running tasks
                                </Card.Footer>
                            </Link>
                        </Card>
                    </Col>
                </Row>
                <Card className='sticky-top'>
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={3}>
                                <ItemsDropdown
                                    items={myProjects}
                                    selected={projectContext}
                                    setSelected={setProjectContext}
                                    attr={"name"}
                                    isLoading={isLoading}
                                />
                            </Col>
                            <Col sm={12} md={9} className='d-flex justify-content-end'>
                                <Button
                                    variant='dark'
                                    className='p-2'
                                    // disable if already selected or if loading
                                    disabled={isLoading || dropdownLoading}
                                    active={last_7_days}
                                    onClick={() => {
                                        setLast7Days(true);
                                        setLast14Days(false);
                                        setLast30Days(false);
                                        fetchDashboardData(true, false, false, projectContext.id);
                                    }
                                    }>
                                    Last 7 days
                                </Button>
                                <Button variant='dark'
                                    className='p-2 mx-3'
                                    disabled={isLoading || dropdownLoading}
                                    active={last_14_days}
                                    onClick={() => {
                                        setLast7Days(false);
                                        setLast14Days(true);
                                        setLast30Days(false);
                                        fetchDashboardData(false, true, false, projectContext.id);
                                    }
                                    }
                                >Last 14 days</Button>
                                <Button variant='dark'
                                    className='p-2'
                                    disabled={isLoading || dropdownLoading}
                                    active={last_30_days}
                                    onClick={() => {
                                        setLast7Days(false);
                                        setLast14Days(false);
                                        setLast30Days(true);
                                        fetchDashboardData(false, false, true, projectContext.id);
                                    }
                                    }
                                >Last 30 days</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row className='pt-3 pb-2'>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    <h3>Tasks per Day</h3>
                                    {isLoading && !error && <span className="my-2">Loading...</span>}
                                </Card.Title>
                                {error && <div className="text-danger fs-5">{error}</div>}
                                {data.task_results && data.task_results.length !== 0 && !isLoading &&
                                    <CanvasJSChart options={{
                                        axisX: {
                                            valueFormatString: 'DD-MM-YYYY',
                                            title: 'Date',
                                            intervalType: 'day',
                                            interval: 1,
                                            labelAngle: -45,
                                            reversed: true,
                                        },
                                        axisY: { title: 'Number of Tasks' },
                                        toolTip: { shared: true },
                                        exportEnabled: false,
                                        animationEnabled: true,
                                        dataPointWidth: 90,
                                        data: prepareChartData(),
                                    }} />
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='pt-2 pb-3'>
                    <Col>
                        <Card>
                            <Card.Title>
                                <h3 className='pt-3'>Spider Last Status per Day</h3>
                            </Card.Title>
                            {error && <div className="text-danger fs-5">{error}</div>}
                            <Card.Body>
                                <Card.Text>
                                {!error && (
                                    <Table striped bordered responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ minWidth: '8em' }}>Spider Name</th>
                                                {data.task_results &&
                                                    data.task_results.reduce((allDates, result) => {
                                                        result.dates.forEach((date) => {
                                                            if (!allDates.includes(date.done_at)) {
                                                                allDates.push(date.done_at);
                                                            }
                                                        });
                                                        return allDates;
                                                    }, []).map((uniqueDate, index) => (
                                                        <th style={{ minWidth: '8em' }} key={index}>{uniqueDate}</th>
                                                    ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && !error && <tr><td colSpan={getColumnCount()} className="text-center">Loading...</td></tr>}
                                            {data.task_results && !isLoading &&
                                                data.task_results.map((result, index) => (
                                                    <tr key={index}>
                                                        <td>{result.spider_name}</td>
                                                        {data.task_results.reduce((allDates, result) => {
                                                            result.dates.forEach((date) => {
                                                                if (!allDates.includes(date.done_at)) {
                                                                    allDates.push(date.done_at);
                                                                }
                                                            });
                                                            return allDates;
                                                        }, []).map((uniqueDate, dateIndex) => (
                                                            <td key={dateIndex}>
                                                                {result.dates
                                                                    .filter((date) => date.done_at === uniqueDate)
                                                                    .map((date) => {
                                                                        const tasksForDate = date.tasks.length > 0 ? [...date.tasks] : [];
                                                                        tasksForDate.sort((a, b) => new Date(b.done_at) - new Date(a.done_at));
                                                                        const latestTask = tasksForDate.length > 0 ? tasksForDate[0] : null;

                                                                        return (
                                                                            <td className='d-flex align-items-center justify-content-center' key={dateIndex}>
                                                                                <OverlayTrigger
                                                                                    overlay={<Tooltip>{copied ? 'Copied!' : 'Click to copy task_id'}</Tooltip>}
                                                                                    placement="right"
                                                                                    delayShow={0}
                                                                                    delayHide={150}
                                                                                >
                                                                                    <td
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            navigator.clipboard.writeText(latestTask.task_id);
                                                                                            setCopied(true);
                                                                                            setTimeout(() => setCopied(false), 500); // Reset copied state after 0.5 seconds
                                                                                        }}
                                                                                    >
                                                                                        {latestTask.status}
                                                                                    </td>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                        );
                                                                    })}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                )}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <HomepageFooter />
            </Container>
        </>
    )
}

export default Dashboard;