import React from "react";

import config from '../config';
import DjangoAPIClient from '../client';

import { useEffect , useState, useContext} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import ItemsDropdown from "./ItemsDropdown";
import { ReactComponent as AddIcon } from '../assets/icon10_add.svg';

const client = new DjangoAPIClient(config);

const SelectOrAddCollection = (props) => {
    const [addCollection, setAddCollection] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState("");


    return (
        <Container>
            {!addCollection && 
                <Row className="d-flex align-items-center">
                    <Col sm={12} md={7}>
                        <ItemsDropdown
                            selected={props.selectedCollection}
                            setSelected={props.setSelectedCollection}
                            items={props.myCollections}
                            attr={"collection_name"}
                            isLoading={props.isLoading}
                        />
                    </Col>
                    <Col  sm={12} md={5} className="p-0">
                        <Button 
                            variant="none" 
                            size="md" 
                            onClick={(e) => setAddCollection(true)}
                        >
                            {<AddIcon className='table-icon'/>}
                        </Button>
                    </Col>
                </Row>
            }
            {addCollection && 
                <Row className="d-flex align-items-center">
                    <Col sm={12} md={7}>
                        <Form>
                        <Form.Group controlId="formNewCollectionName">
                        <Form.Control 
                            className="w-100 rounded-pill"
                            type="text"
                            placeholder="Collection name"
                            value={newCollectionName}
                            isInvalid={!!props.error}
                            onChange={(e) => setNewCollectionName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.error}
                        </Form.Control.Feedback>
                    </Form.Group>
                        </Form>
                    </Col>
                    <Col sm={12} md={5}>
                        <Button 
                            variant="modal"
                            size="md"
                            className="w-50 rounded-pill"
                            onClick={(e) => {
                                props.setSelectedCollection(true)
                                props.handleCollectionCreate(newCollectionName);
                                if (newCollectionName) {
                                    setAddCollection(false);
                                }
                            }}>
                            Add
                        </Button>
                        <Button 
                            variant="dark"
                            className="ms-2 rounded-pill"
                            size="md"
                            onClick={(e) => {
                                if(props.selectedCollection === false){props.setSelectedCollection({id: 0, name: "Select"})};
                                setAddCollection(false)
                            }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default SelectOrAddCollection;