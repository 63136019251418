import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';

import DjangoAPIClient from '../../client';
import config from '../../config';

const client = new DjangoAPIClient(config);


const changePassword = ({ title, userData }) => {
    const [error, setError] = useState({ password: "", password2: "" })
    const [changePasswordForm, setchangePasswordForm] = useState({ password: "", password2: "" })

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const navigate = useNavigate()

    useEffect(() => {
        document.title = title;
    }, [title]);

    const toDeaHomepage = () => {
        navigate("/dea")
    }

    const onchangePasswordForm = (e) => {
        e.preventDefault()
        setError(false)

        // Check if password and password2 match
        if (changePasswordForm.password !== changePasswordForm.password2) {
            setError({ password2: "Passwords do not match!" });
            return;
        }

        // Check if password is not empty
        if (changePasswordForm.password == "" || changePasswordForm.password2 == "") {
            setError({ password2: "Password cannot be empty!" });
            return;
        }

        client.changePassword(changePasswordForm.password, changePasswordForm.password2)
            .then(() => {
                toDeaHomepage()
            })
            .catch((err) => {
                setError({ password: err.response.data.errors.password2[0] });
            });
    }

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <Row className='justify-content-center align-items-center App-header'>
                    <Col lg={4} md={6} xs={10}>
                        <h1>Change password</h1>
                        <Form onSubmit={(e) => onchangePasswordForm(e)}>
                            <FloatingLabel
                                controlId="floatingPassword"
                                label="Password"
                                className='my-3'>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    error={error.password}
                                    isInvalid={error.password}
                                    value={changePasswordForm.password}
                                    onChange={(e) => setchangePasswordForm({ ...changePasswordForm, password: e.target.value })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.password}
                                </Form.Control.Feedback>
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="floatingPassword2"
                                label="Confirm password"
                                className='mb-3'>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm password"
                                    error={error.password2}
                                    isInvalid={error.password2}
                                    value={changePasswordForm.password2}
                                    onChange={(e) => setchangePasswordForm({ ...changePasswordForm, password2: e.target.value })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.password2}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <input type="hidden" name="remember" defaultValue="true" />
                            {/* {error.password && <p className="text-danger">{error.password}</p>}
                        {error.password2 && <p className="text-danger">{error.password2}</p>} */}
                            <Row className='g-0'>
                                <Button
                                    variant="pf23"
                                    type="submit"
                                    className='mx-auto mb-2'
                                    error={error.password} title={"ResetPassword"}>
                                    Change password
                                </Button>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default changePassword;
