import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import PaginationComponent from '../../components/Pagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

import { CSVLink } from 'react-csv';

const EretailersTable = (props) => {
	const [eretailersFiltered, setEretailersFiltered] = useState([]);
	const [eretailersDisplayed, setEretailersDisplayed] = useState([]);

	const [activePage, setActivePage] = useState(1);
	const [maxDisplay, setMaxDisplay] = useState(props.maxPagination);

	const [sortColumn, setSortColumn] = useState("last_run");
	const [sortDirection, setSortDirection] = useState("desc");

	const [filterName, setFilterName] = useState('');
	const [filterPageType, setFilterPageType] = useState('');
	const [filterFields, setFilterFields] = useState('');
	const [filterWarning, setFilterWarning] = useState('');
	const [filterDDCD, setFilterDDCD] = useState('');
	const [filterLastRun, setFilterLastRun] = useState('');
	  
	function handleSort(column) {
		if (sortColumn === column) {
			// If the same column is clicked, reverse the sort direction
			setSortDirection((prevSortDirection) =>
				prevSortDirection === "asc" ? "desc" : "asc"
			);
			setActivePage(1);
		} else {
			// If a different column is clicked, set it as the new sort column
			setSortColumn(column);
			setSortDirection("asc");
			setActivePage(1);
		}
	}

	// Sort and filter projects
	function sortAndFilterEretailers() {
		const filtered = props.eretailers.filter(item => {
			// Check if item is defined
			if (!item) {
				return false;
			}

			const nameMatch = item.name ? item.name.toLowerCase().includes(filterName.toLowerCase()) : false;
			const pageTypeMatch = item.page_type ? item.page_type.toLowerCase().includes(filterPageType.toLowerCase()) : false;
			const fieldsMatch = filterFields ? filterFields.split(",").every(elem => item.fields.join(", ").includes(elem.replace(/\s/g, ''))) : true;
			const warningMatch = item.warnings ? item.warnings.join(', ').toLowerCase().includes(filterWarning.toLowerCase()) : true;
			const ddcdMatch = filterDDCD ? (typeof item.data_driven_projects === 'boolean' ? item.data_driven_projects.toString().toLowerCase().includes(filterDDCD.toLowerCase()) : false) : true;
			const lastRunMatch = item.last_run ? item.last_run.toLowerCase().includes(filterLastRun.toLowerCase()) : true;
	
			return nameMatch && pageTypeMatch && fieldsMatch && warningMatch && ddcdMatch && lastRunMatch;
		});
	
		const sorted = filtered.sort((a, b) => {
			if (sortColumn === "name") {
				return sortDirection === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
			} else if (sortColumn === "page_type") {
				return sortDirection === "asc" ? a.page_type.localeCompare(b.page_type) : b.page_type.localeCompare(a.page_type);
			} else if (sortColumn === "fields") {
				return sortDirection === "asc" ? a.fields_count - b.fields_count : b.fields_count - a.fields_count;
			} else if (sortColumn === "warning") {
				return sortDirection === "asc" ? a.warnings.join(', ').localeCompare(b.warnings.join(', ')) : b.warnings.join(', ').localeCompare(a.warnings.join(', '));
			} else if (sortColumn === "ddcd") {
				return sortDirection === "asc" ? a.data_driven_projects - b.data_driven_projects : b.data_driven_projects - a.data_driven_projects;
			} else if (sortColumn === "last_run") {
				const parseDate = (dateStr) => {
					const [day, month, year] = dateStr.split('-');
					return new Date(`${year}-${month}-${day}`);
				};
				const dateA = a.last_run ? parseDate(a.last_run) : new Date(0);
				const dateB = b.last_run ? parseDate(b.last_run) : new Date(0);
				return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
			}
			return 0;
		});
	
		setEretailersFiltered(sorted);
		setActivePage(1);
	}
	
	const generateCSVFileName = () => {
		const currentDate = new Date();
		const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
		return `scrapfinderlab_eretailers_${formattedDate}.csv`;
	  };
	  
	  const generateCSVData = () => {
		const data = [['File Name', 'Page Type', 'Fields', 'Warning', 'DDCD', 'Last Run']];
		eretailersFiltered.forEach((item) => {
		  const ddcdValue = typeof item.data_driven_projects === 'boolean' ? item.data_driven_projects.toString() : 'N/A';
		  data.push([item.name, item.page_type, item.fields.join(', '), item.warnings.join(', '), ddcdValue, item.last_run]);
		});
		return data;
	  };

	useEffect(() => {
		sortAndFilterEretailers();
	}, [props.eretailers, filterName, filterPageType, filterFields, filterWarning, filterDDCD, filterLastRun, sortColumn, sortDirection]);

	  
	return (
        <>
		<Row>
			<Col sm={12} md={12} className='text-end' style={{marginTop: '-3.75rem'}}>
			<Button
				className='rounded-pill'
				variant='pf23'
			>
				<CSVLink
				type='button'
				data={generateCSVData()}
				filename={generateCSVFileName()}
				className='text-decoration-none text-white'>
					Export to CSV
				</CSVLink>
			</Button>
			</Col>
		</Row>

		<Table responsive striped bordered hover className='eretailer-table my-3'>
            {props.isLoading && !props.eretailers.length && <tr><td colSpan="8" className="text-center py-4">Fetching data from database...</td></tr>}
            {!props.isLoading && !props.eretailers.length && <tr><td colSpan="8" className="text-center py-4">Error loading data. Please try again later.</td></tr>}
            <thead>
				<tr>
					<th onClick={() => handleSort("name")} className='sort-arrow'>
						File Name
						<span>
							{sortColumn === "name" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "name" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "name" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("page_type")} className='sort-arrow'>
						Page Type
						<span>
							{sortColumn === "page_type" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "page_type" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "page_type" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("fields")} className='sort-arrow'>
						Fields
						<span className="sort-arrow">
							{sortColumn === "fields" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "fields" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "fields" && <FaSort />}
						</span>
					</th>
                    <th onClick={() => handleSort("warning")} className='sort-arrow'>
						Warning
						<span className="sort-arrow">
							{sortColumn === "warning" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "warning" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "warning" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("ddcd")} className='sort-arrow'>
						DDCD
						<span className="sort-arrow">
							{sortColumn === "ddcd" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "ddcd" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "ddcd" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("last_run")} className='sort-arrow'>
						Last run
						<span className="sort-arrow">
							{sortColumn === "last_run" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "last_run" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "last_run" && <FaSort />}
						</span>
					</th>
				</tr>
				<tr>
					<th>
						<Form.Control
							type="text"
							id="filter-name"
							placeholder="Filter by name"
							value={filterName}
							onChange={(e) => setFilterName(e.target.value)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-page-type"
							placeholder="Filter by page type"
							value={filterPageType}
							onChange={(e) => setFilterPageType(e.target.value)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-fields"
							placeholder="Filter by field"
							value={filterFields}
							onChange={(e) => setFilterFields(e.target.value)}
						/>
					</th>
                    <th>
						<Form.Control
							type="text"
							id="filter-warning"
							placeholder="Filter by warning"
							value={filterWarning}
							onChange={(e) => setFilterWarning(e.target.value)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-ddcd"
							placeholder="Filter by DDCD"
							value={filterDDCD}
							onChange={(e) => setFilterDDCD(e.target.value)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-last-run"
							placeholder="Filter by Last Run"
							value={filterLastRun}
							onChange={(e) => setFilterLastRun(e.target.value)}
						/>
					</th>
				</tr>
			</thead>
            <tbody>
                {eretailersDisplayed.map((item, index) => {
					return (
						<tr key={index}>
							<td>{item.name}</td>
							<td>{item.page_type}</td>
							<td>
								{Array.isArray(item.fields) ? (
									item.fields.map((field, fieldIndex) => (
										<span key={fieldIndex} className="badge bg-secondary m-1" style={{ fontSize: '15px', fontWeight: '400' }}>
											{field}
										</span>
									))
								) : (
									<span style={{ color: 'red' }}>Error when loading yaml file</span>
								)}
							</td>
							<td>
								{item.warnings && Array.isArray(item.warnings)
									? item.warnings.join(', ')
									: null}
							</td>
							<td>{typeof item.data_driven_projects === 'boolean' ? item.data_driven_projects.toString() : 'N/A'}</td>
							<td>{item.last_run ? item.last_run : null}</td>
						</tr>
					);
                })}
            </tbody>
        </Table>
        <Col className='pb-3'>
			<PaginationComponent
				allItems={eretailersFiltered}
				setItemsToDisplay={setEretailersDisplayed}
				maxDisplay={maxDisplay}
				activePage={activePage}
				setActivePage={setActivePage}
			/>
		</Col></>
    )
}

export default EretailersTable;