import React, { useState, useEffect } from "react";
import config from '../config';
import DjangoAPIClient from '../client';

import { marked } from "marked";

import Container from 'react-bootstrap/Container';
import Navbar from '../components/Navbar';
import Row from 'react-bootstrap/Row';
import HomepageFooter from '../components/HomepageFooter'
import Sidebar from "../components/Sidebar";

const client = new DjangoAPIClient(config);


const Quickstart = ({ title, userData }) => {
	const [content, setContent] = useState("");

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		client.getQuickstart()
			.then(response => {
				setContent(marked(response.data.markdown_content));
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	return (
		<>
			<Sidebar userData={userData} />
			<Container fluid className='App with-sidebar bg-light rounded-bottom d-flex flex-column justify-content-between min-vh-100'>
				<Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
				<Row className="App-header bg-white">
					<h1>How to use our platform?</h1>
				</Row>
				<Row className="text-start flex-grow-1 quick-start">
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</Row>
				<HomepageFooter />
			</Container>
		</>
	);
};

export default Quickstart;
