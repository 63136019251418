import { React, useState, useEffect } from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ItemsDropdown from "../ItemsDropdown";
import Alert from 'react-bootstrap/Alert';
import { CSSTransition } from 'react-transition-group';

// props.client -> DjangoAPIClient
// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.handleUpload -> function to upload file


const UploadFileModal = (props) => {
    const [newFile, setNewFile] = useState('');

    const [projects, setProjects] = useState([]);
    const [selectedFileType, setSelectedFileType] = useState({ id: 1, name: "Select" })
    const [selectedProject, setSelectedProject] = useState({ id: 1, name: "Select" })

    const [error, setError] = useState("");
    const [alertMessage, setAlertMessage] = useState(false);

    const [showConfirmOverwrite, setShowConfirmOverwrite] = useState(false);

    useEffect(() => {
        props.client.getDropdownProjects()
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    function closeModal() {
        setNewFile("");
        setSelectedProject({ id: 1, name: "Select" });
        setSelectedFileType({ id: 1, name: "Select" });
        setError("");
        setAlertMessage(false);
        props.setShowModal(false);
    }

    function validateForm() {
        if (!newFile) {
            setError("Please select file")
            return false
        }
        const fileExtension = newFile[0].name.split(".").pop()
        if (!["py", "yaml", "xlsx"].includes(fileExtension)) {
            setError("File extension needs to be one of .py/.yaml/.xlsx");
            return false
        }
        if (selectedProject.name === "Select" || selectedProject.name === "Select" || newFile === "") {
            setAlertMessage("Please select project");
            setTimeout(() => {
                setAlertMessage(false);
            }, 1000);
            return false;
        }
    }

    function preUploadFile() {
        if (validateForm() === false) {
            return 0;
        }
        props.client.isFileInDB(selectedFileType.name, selectedProject.name, newFile[0].name).then(
            (response) => {
                if (response.data.data === true) {
                    setShowConfirmOverwrite(true);
                } else if (response.data.msg != "Not correct file_type argument") {
                    uploadFile();
                } else {
                    console.log(response.data)
                }
            }
        )
    }

    function uploadFile() {
        setShowConfirmOverwrite(false);
        props.handleUpload(selectedFileType.name, selectedProject.name, newFile);
        setSelectedProject({ id: 1, name: "Select" });
        setSelectedFileType({ id: 1, name: "Select" });
        setNewFile("");
        setError("");
        setAlertMessage(false);
        props.setShowModal(false);
    }

    const mainModalBody = () => {
        return (
            <Modal.Body>
                <Row className="mb-4">
                    <Col md={4} className="d-flex align-items-center">
                        Project name:
                    </Col>
                    <Col md={8} className="ps-4">
                        <ItemsDropdown
                            items={projects}
                            selected={selectedProject}
                            setSelected={setSelectedProject}
                            attr={"name"}
                        />
                    </Col>
                </Row>
                {/* <Row className="my-4">
                    <Col md={4} className="d-flex align-items-center">
                        File type:
                    </Col>
                    <Col md={8} className="ps-4">
                        <ItemsDropdown
                            items={[{id: 1, name: "spider-config"}, {id: 2, name: "yaml-config"}, {id: 3, name: "template-file"}]}
                            selected={selectedFileType}
                            setSelected={setSelectedFileType}
                            attr={"name"}
                        />
                    </Col>
                </Row> */}
                <Row className="mt-4">
                    <Col md={4} className="d-flex align-items-center">
                        File:
                    </Col>
                    <Col md={8} className="ps-4">
                        <Form>
                            <Form.Group>
                                <Form.Control
                                    type="file"
                                    className="rounded-pill"
                                    onChange={(e) => {
                                        setNewFile(e.target.files)
                                        const fileExtension = e.target.files[0].name.split(".").pop()
                                        if (fileExtension === "py") {
                                            setSelectedFileType({ id: 1, name: "spider-config" })
                                        } else if (fileExtension === "yaml") {
                                            setSelectedFileType({ id: 2, name: "yaml-config" })
                                        } else if (fileExtension === "xlsx" || fileExtension === "csv") {
                                            setSelectedFileType({ id: 3, name: "template-file" })
                                        }
                                    }}
                                    isInvalid={error}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {error}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        )
    }

    const confirmOverwriteModalBody = () => {
        return (
            <Modal.Body>
                You are going to <b>overwrite</b> existing file: <br />
                - File type: {selectedFileType.name} <br />
                - Project name: {selectedProject.name} <br />
                - File name: {newFile[0].name} <br />
                <br />
                Do you want to continue?
            </Modal.Body>
        )
    }

    return (
        <Modal show={props.showModal} backdrop="static" onHide={(e) => closeModal()}>
            <CSSTransition
                in={alertMessage}
                timeout={300}
                classNames="alert"
                unmountOnExit
            >
                <Alert variant="danger" onClose={() => setAlertMessage(false)} dismissible>
                    {alertMessage}
                </Alert>
            </CSSTransition>
            <Modal.Header closeButton>
                <Modal.Title>Upload file</Modal.Title>
            </Modal.Header>
            {!showConfirmOverwrite && mainModalBody()}
            {showConfirmOverwrite && confirmOverwriteModalBody()}
            <Modal.Footer>
                {!showConfirmOverwrite &&
                    <>
                        <Button
                            variant="modal"
                            onClick={(e) => preUploadFile()}
                        >
                            Upload
                        </Button>
                        <Button
                            variant="dark"
                            onClick={(e) => closeModal()}
                        >
                            Cancel
                        </Button>
                    </>
                }
                {showConfirmOverwrite &&
                    <>
                        <Button
                            variant="modal"
                            onClick={(e) => uploadFile()}
                        >
                            Confirm
                        </Button>
                        <Button
                            variant="dark"
                            onClick={(e) => setShowConfirmOverwrite(false)}
                        >
                            Back
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    )
}


export default UploadFileModal;