import React from "react";

import config from '../config';
import DjangoAPIClient from '../client';

import { useEffect , useState, useContext} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import ItemsDropdown from "./ItemsDropdown";
import { ReactComponent as AddIcon } from '../assets/icon10_add.svg';

const client = new DjangoAPIClient(config);

const SelectOrAddProject = (props) => {
    const [addProject, setAddProject] = useState(false);
    const [newProjectName, setNewProjectName] = useState("");
    
    const [isProjectShared, setIsProjectShared] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState({id: 1, name: "Please select a group"})

    const handleSwitchChange = (e) => {
        // console.log(groups)
        setIsProjectShared(e.target.checked);
        setSelectedGroup({id: 1, name: "Please select a group"});
    }

    useEffect(() => {
        client.getUserGroups()
            .then(response => {
                const groups = response.data.filter(group => group.name != "Private" && group.name.startsWith("EXTERNAL_") === false);
                setGroups(groups);
            })
            .catch(error => {console.log(error);
            });
    }, []);

    useEffect(()=>{
        if(props.selectedProject === false){
            setAddProject(true)
        }
    })

    return (
        <Container>
            {!addProject && 
                <Row className="d-flex align-items-center">
                    <Col sm={12} md={7}>
                        <ItemsDropdown
                            selected={props.selectedProject}
                            setSelected={props.setSelectedProject}
                            items={props.myProjects}
                            attr={"name"}
                        />
                    </Col>
                    <Col  sm={12} md={5} className="p-0">
                        <Button 
                            variant="none" 
                            size="md" 
                            onClick={(e) => setAddProject(true)}
                        >
                            {<AddIcon className='table-icon'/>}
                        </Button>
                    </Col>
                </Row>
            }
            {addProject && 
                <Row className="d-flex align-items-center">
                    <Col sm={12} md={7}>
                        <Form>
                        <Form.Group className="mb-3" controlId="formNewProjectName">
                        <Form.Control 
                            className="w-100"
                            type="text"
                            placeholder="Project name"
                            value={newProjectName}
                            isInvalid={!!props.error}
                            onChange={(e) => setNewProjectName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {props.error}
                            {/* {props.error.group} */}
                        </Form.Control.Feedback>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            className="my-2"
                            label="Share this project with others"
                            onChange={handleSwitchChange}
                        />
                        {isProjectShared && (
                            <ItemsDropdown
                            items={groups}
                            selected={selectedGroup}
                            setSelected={setSelectedGroup}
                            attr={"name"}
                            />
                        )}
                    </Form.Group>
                        </Form>
                    </Col>
                    <Col sm={12} md={5}>
                        <Button 
                            variant="modal"
                            size="md"
                            className="w-50 rounded-pill"
                            onClick={(e) => {
                                props.setSelectedProject(true)
                                console.log(groups)
                                props.handleProjectCreate(newProjectName, isProjectShared, selectedGroup.id);
                                if (newProjectName) {
                                    setAddProject(false);
                                }
                            }}>
                            Create
                        </Button>
                        <Button 
                            variant="dark"
                            className="ms-2 rounded-pill"
                            size="md"
                            onClick={(e) => {
                                if(props.selectedProject === false){props.setSelectedProject({id: 0, name: "Select"})};
                                setAddProject(false);
                                setIsProjectShared(false);
                            }}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default SelectOrAddProject;