import React, {useState, useEffect} from 'react';
import {Route, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from '../../components/Navbar';
import { Button } from 'react-bootstrap';

const ResetPasswordMessage = () =>{
    const navigate = useNavigate()
    const [title] = useState("Scrapfinderlab - activate your email message");

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);


    return(
        <Container fluid className="App bg-light-pf23 rounded-bottom vh-100">
            <Navbar></Navbar>
            <Row className="App-header justify-content-center align-items-center mt-5">
                <Col sm={12} md={8}>
                <h3>You should receive an email soon with a link that will redirect you to reset password form.</h3>
                </Col>
            </Row>
            <Row className='justify-content-center align-items-center'>
                    <Button
                        variant='pf23 my-3 w-25 mx-auto'
                        className='auth-button'
                        onClick={(e) => navigate('/')}
                    >
                        Go to homepage
                    </Button>
            </Row>
        </Container>
    )
}

export default ResetPasswordMessage;