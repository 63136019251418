import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { CSSTransition } from 'react-transition-group';
import Dropdown from 'react-bootstrap/Dropdown';

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import SelectOrAddProject from "../components/SelectOrAddProject";
import SelectOrAddCollection from "../components/SelectOrAddCollection";
import SelectOrUploadFile from "../components/SelectOrUploadFile";
import InputTextEdit from "../components/InputTextEdit";
import ProjectContext from "../components/contexts/ProjectContext";
// import SpiderContext from "../components/contexts/SpiderContext";
import SpiderKwargsModal from "../components/modals/SpiderKwargsModal";

import config from '../config';
import DjangoAPIClient from '../client';

const client = new DjangoAPIClient(config);


const SpiderDetailView = ({ title, userData }) => {
    const { spiderId } = useParams();

    const navigate = useNavigate();

    const [projectContext, setProjectContext] = useContext(ProjectContext);
    // const [spiderContext, setSpiderContext] = useContext(SpiderContext)
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingKwargs, setIsLoadingKwargs] = useState(true);
    const [showKwargsModal, setShowKwargsModal] = useState(false);

    const [error, setError] = useState("")
    const [alertMessage, setAlertMessage] = useState({ msg: "", project: "", mongodb_collection_name: "", spider_config_file: "", template_file: "", yaml_config_file: "" });
    // loaded projects/files:
    const [myProjects, setMyProjects] = useState([]);
    const [myCollections, setMyCollections] = useState([]);
    const [spiderConfigs, setSpiderConfigs] = useState([]);
    const [yamlConfigs, setYamlConfigs] = useState([]);
    const [templateFiles, setTemplateFiles] = useState([]);
    const [spiderKwargs, setSpiderKwargs] = useState("{}");

    const [spiderPossibleKwargs, setSpiderPossibleKwargs] = useState({});

    // spider info:
    const [spiderName, setSpiderName] = useState();
    const [region, setRegion] = useState();
    const [regionChoices, setRegionChoices] = useState([]);
    const [user, setUser] = useState();
    const [userEmail, setUserEmail] = useState();
    const [selectedCollection, setSelectedCollection] = useState({});
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedSpiderConfig, setSelectedSpiderConfig] = useState({});
    const [selectedYamlConfig, setSelectedYamlConfig] = useState({});
    const [selectedTemplateFile, setSelectedTemplateFile] = useState({});
    const [creationDate, setCreationDate] = useState();
    const [updatedBy, setUpdatedBy] = useState();
    const [tasks_notification, setTasksNotification] = useState();
    const [lastUpdateDate, setLastUpdateDate] = useState();

    const [genericSpiderConfigs, setGenericSpiderConfigs] = useState(false);
    const [projectSpiderConfigs, setProjectSpiderConfigs] = useState(true);
    const [genericYamlConfigs, setGenericYamlConfigs] = useState(false);
    const [projectYamlConfigs, setProjectYamlConfigs] = useState(true);

    ////////// Page title
    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);
    //////////

    useEffect(() => {
        fetchMyProjects();
        fetchMyCollections();
        fetchRegionChoices();
        // fetchSpiderPossibleKwargs();
    }, [])

    useEffect(() => {
        fetchSpiderData();
    }, [])

    useEffect(() => {
        fetchSpiderConfigs();
        fetchYamlConfigs();
        fetchTemplateFiles();
    }, [selectedProject, genericSpiderConfigs, genericYamlConfigs])

    function getFileName(fullPath) {
        try {
            const parts = fullPath.split('/');
            const filenameWithExtension = parts.pop();
            const filename = filenameWithExtension.split('.')[0];
            return filename;
        } catch (error) {
            console.error('Error while getting filename:', error);
            return 'Filename does not exist';
        }
    }

    function fetchSpiderData() {
        client.getSpider(spiderId).then((response) => {
            setIsLoading(true);
            const spiderData = response.data;
            // Set the spider data in the component state
            setUser(spiderData.user);
            setUserEmail(spiderData.user_email);
            setSelectedProject({ id: spiderData.project, name: spiderData.project_name });
            setSelectedCollection({ id: spiderData.mongodb_collection, collection_name: spiderData.mongodb_collection_name });
            setSelectedSpiderConfig({ id: spiderData.spider_config_file, file: getFileName(spiderData.spider_config_name), filepath: spiderData.spider_config_name });
            setSelectedYamlConfig({ id: spiderData.yaml_config_file, file: getFileName(spiderData.yaml_config_name), filepath: spiderData.yaml_config_name });
            setSelectedTemplateFile({ id: spiderData.template_file, file: getFileName(spiderData.template_name), filepath: spiderData.template_name });
            setSpiderName(spiderData.name);
            setRegion(spiderData.region);
            setCreationDate(spiderData.creation_date);
            setUpdatedBy(spiderData.updated_by);
            setLastUpdateDate(spiderData.update_date);
            setTasksNotification(spiderData.tasks_notification);
            setSpiderKwargs(spiderData.kwargs);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setError(error.response.data);
        });
    }

    function fetchSpiderConfigPossibleKwargs() {
        if (selectedSpiderConfig.hasOwnProperty('id') === true) {
            setIsLoadingKwargs(true);
            client.getSpiderConfigPossibleKwargs(selectedSpiderConfig.id).then((response) => {
                setSpiderPossibleKwargs(response.data);
                setIsLoadingKwargs(false);
            }).catch((error) => {
                console.log(error);
                setError(error.response.data);
                setIsLoadingKwargs(false);
            });
        }
        // setSpiderPossibleKwargs([
        //     {
        //         "name": "broswer_name",
        //         "type": "str",
        //         "options": [],
        //         "description": "overwrite default browser name (chromium, firefox)"
        //     },
        //     {
        //         "name": "pages_limit",
        //         "type": "int",
        //         "options": [],
        //         "description": "limit of pages to scrape"
        //     },
        //     {
        //         "name": "projects",
        //         "type": "multiple_choice_list",
        //         "options": ["DE_1", "DE_2"],
        //         "description": "price check specific, projects to run scraping for"
        //     },
        //     {
        //         "name": "analysis",
        //         "type": "single_choice_list",
        //         "options": ["rr", "la", "qa"],
        //         "description": "type of analysis"
        //     },
        //     {
        //         "name": "skip_listing",
        //         "type": "bool",
        //         "options": [],
        //         "description": "If true skips listing scraping"
        //     }
        // ])
    }

    function fetchRegionChoices() {
        client.fetchRegionChoices()
            .then((response) => {
                setRegionChoices(response.data);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
            });
    }

    useEffect(() => {
        fetchSpiderConfigPossibleKwargs();
    }, [selectedSpiderConfig])

    function fetchMyProjects() {
        client.getDropdownProjects().then(
            (response) => {
                setMyProjects(response.data);
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function fetchMyCollections() {
        client.getMyCollections().then(
            (response) => {
                setMyCollections(response.data);
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function handleProjectCreate(newProjectName, is_shared, group) {
        client.createNewProject(newProjectName, is_shared, group).then(
            (response) => {
                // setProjectContext({id: response.data.id, name: response.data.name});
                setSelectedProject({ id: response.data.id, name: response.data.name });
                fetchMyProjects();
            }
        )
            .catch((error) => {
                console.log(error.response.data)
                setError(error.response.data);
            });
    }

    function handleCollectionCreate(newCollectionName) {
        client.createNewCollection(newCollectionName).then(
            (response) => {
                setSelectedCollection({ id: response.data.id, collection_name: response.data.collection_name });
                fetchMyCollections();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function updateSpider() {
        const spiderData = {
            name: spiderName,
            region: region,
            project: selectedProject.id,
            mongodb_collection: selectedCollection.id,
            user: user,
            spider_config_file: selectedSpiderConfig.id,
            yaml_config_file: selectedYamlConfig.id,
            template_file: selectedTemplateFile.id,
            creation_date: creationDate,
            updated_by: updatedBy,
            tasks_notification: tasks_notification,
            kwargs: spiderKwargs
        };
        // Call the API to update the spider with the new data
        client.updateSpider(spiderId, spiderData)
            .then((response) => {
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);

                // navigate(`/spiders/${spiderId}`);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
            });
    }

    function fetchSpiderConfigs() {
        const projectName = genericSpiderConfigs ? "Generic spiders" : selectedProject.name;
        client.getDropdownSpiderConfigs(projectName)
            .then((response) => {
                setSpiderConfigs(response.data);
            })
            .catch((error) => {
                console.log(error);
                setError(error.response.data);
            });
    }

    function fetchYamlConfigs() {
        const projectName = genericYamlConfigs ? "Generic spiders" : selectedProject.name;
        client.getDropdownYamlConfigs(projectName).then(
            (response) => {
                setYamlConfigs(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function fetchTemplateFiles() {
        const projectName = selectedProject.name;
        client.getDropdownTemplateFiles(projectName).then(
            (response) => {
                setTemplateFiles(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function uploadSpiderConfig(newFile) {
        client.uploadSpiderConfig(newFile, selectedProject.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedSpiderConfig({ id: response.data.data.id, file: short_name });
                fetchSpiderConfigs();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function uploadYamlConfig(newFile) {
        client.uploadYamlConfig(newFile, selectedProject.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedYamlConfig({ id: response.data.data.id, file: short_name });
                fetchYamlConfigs();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function uploadTemplateFile(newFile) {
        client.uploadTemplateFile(newFile, selectedProject.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedTemplateFile({ id: response.data.data.id, file: short_name });
                fetchTemplateFiles();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    return (
        <>
            <Sidebar userData={userData} />
            <SpiderKwargsModal 
                showModal={showKwargsModal}
                setShowModal={setShowKwargsModal}
                possibleSpiderKwargs={spiderPossibleKwargs}
                spiderKwargs={spiderKwargs}
                setSpiderKwargs={setSpiderKwargs}
            />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <CSSTransition
                    in={showAlert}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <Alert variant="success" onClose={() => setAlertMessage(false)} dismissible>
                        Spider updated successfully <br></br>
                    </Alert>
                </CSSTransition>
                {isLoading &&
                    <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                        <header className="bg-white pt-2">
                            <h1 className='text-start'>Loading...</h1>
                        </header>
                    </Row>
                }
                {!isLoading &&
                    <section>
                        <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                            <header className="bg-white pt-2">
                                <h1 className='text-start'>{spiderName} - spider details</h1>
                            </header>
                        </Row>

                        <Form noValidate>

                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Author:
                                </Col>
                                <Col sm={12} md={9}>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        placeholder="Author"
                                        value={userEmail}
                                        onChange={(e) => setUser(e.target.value)}
                                        isInvalid={!!error.user}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {error.user}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Last updated by:
                                </Col>
                                <Col sm={12} md={9}>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        placeholder="Last updated by"
                                        value={updatedBy}
                                        onChange={(e) => setUpdatedBy(e.target.value)}
                                        isInvalid={!!error.updated_by}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {error.updated_by}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Creation date:
                                </Col>
                                <Col sm={12} md={9}>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        placeholder="Creation date"
                                        value={creationDate}
                                        onChange={(e) => setCreationDate(e.target.value)}
                                        isInvalid={!!error.creation_date}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {error.creation_date}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Last update date:
                                </Col>
                                <Col sm={12} md={9}>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        placeholder="Last update date"
                                        value={lastUpdateDate}
                                        onChange={(e) => setLastUpdateDate(e.target.value)}
                                        isInvalid={!!error.last_update_date}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {error.last_update_date}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex">
                                    Task notification:
                                </Col>
                                <Col sm={12} md={9} className='text-start'>
                                    <Form.Check
                                        type="checkbox"
                                        checked={tasks_notification}
                                        onChange={(e) => setTasksNotification(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row className="mx-3 my-3">
                                <Col sm={3} md={3} className="d-flex align-items-center">Project:</Col>
                                <Col sm={8} md={9}>
                                    <SelectOrAddProject
                                        selectedProject={selectedProject}
                                        setSelectedProject={setSelectedProject}
                                        myProjects={myProjects}
                                        handleProjectCreate={handleProjectCreate}
                                        isInvalid={!!error.project}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Field cannot be empty
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Region:
                                </Col>
                                {/* <Col sm={12} md={9}>
                                    <InputTextEdit
                                        currentText={region}
                                        setCurrentText={setRegion}
                                        isInvalid={!!error}
                                    />
                                </Col> */}
                                <Col sm={12} md={9}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                    variant="dark"
                                    id="region-dropdown"
                                    className='region-dropdown rounded-pill'
                                    >
                                        {regionChoices.find(option => option.value === region)?.display_name || 'Select Region'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='region-dropdown-menu'>
                                        {regionChoices.map(choice => (
                                            <Dropdown.Item
                                                key={choice.value}
                                                onClick={() => setRegion(choice.value)}
                                            >
                                                {choice.display_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                </Col>
                            </Row>                            
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Spider name:
                                </Col>
                                <Col sm={12} md={9}>
                                    <InputTextEdit
                                        currentText={spiderName}
                                        setCurrentText={setSpiderName}
                                        isInvalid={!!error}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    MongoDB collection name:
                                </Col>
                                <Col sm={12} md={9}>
                                    <SelectOrAddCollection
                                        selectedCollection={selectedCollection}
                                        setSelectedCollection={setSelectedCollection}
                                        myCollections={myCollections}
                                        isInvalid={!!error}
                                        handleCollectionCreate={handleCollectionCreate}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Spider config file (generic files&nbsp;
                                    <Form.Check
                                        type="checkbox"
                                        checked={genericSpiderConfigs}
                                        onChange={(e) => {
                                            setGenericSpiderConfigs(e.target.checked);
                                            setProjectSpiderConfigs(!e.target.checked);
                                        }}
                                    />):
                                </Col>
                                <Col sm={12} md={9}>
                                    <SelectOrUploadFile
                                        selected={selectedSpiderConfig}
                                        isInvalid={!!error}
                                        setSelected={setSelectedSpiderConfig}
                                        items={spiderConfigs}
                                        handleUpload={uploadSpiderConfig}
                                        extension={[".py"]}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Yaml config file (generic files&nbsp;
                                    <Form.Check
                                        type="checkbox"
                                        checked={genericYamlConfigs}
                                        onChange={(e) => {
                                            setGenericYamlConfigs(e.target.checked);
                                            setProjectYamlConfigs(!e.target.checked);
                                        }}
                                    />
                                    ):
                                </Col>
                                <Col sm={12} md={9}>
                                    <SelectOrUploadFile
                                        selected={selectedYamlConfig}
                                        isInvalid={!!error}
                                        setSelected={setSelectedYamlConfig}
                                        items={yamlConfigs}
                                        handleUpload={uploadYamlConfig}
                                        extension={[".yaml"]}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3 pb-2">
                                <Col className="d-flex align-items-center">
                                    Template file:
                                </Col>
                                <Col sm={12} md={9}>
                                    <SelectOrUploadFile
                                        selected={selectedTemplateFile}
                                        isInvalid={!!error}
                                        setSelected={setSelectedTemplateFile}
                                        items={templateFiles}
                                        handleUpload={uploadTemplateFile}
                                        extension={[".xlsx", ".csv"]}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-3 my-3">
                                <Col className="d-flex align-items-center">
                                    Spider kwargs:
                                </Col>
                                <Col sm={12} md={9}>
                                <Row className="d-flex align-items-center">
                                        <Col md={7} style={{"paddingLeft": "20px"}}>
                                            <Form>
                                                <Form.Group>
                                                        <Form.Control 
                                                            type="text"
                                                            className="rounded-pill"
                                                            placeholder={spiderKwargs}
                                                            disabled
                                                            />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col md={5}>
                                            <Button
                                                variant="dark"
                                                size="md"
                                                className="w-50 rounded-pill"
                                                onClick={(e) => {setShowKwargsModal(true)}}
                                                disabled={isLoadingKwargs}
                                            >
                                                Edit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr></hr>
                            <div className='pb-3'>
                                <Button
                                    className="mx-2"
                                    variant="pf23"
                                    onClick={(e) => {
                                        updateSpider();
                                        navigate("/my-spiders");
                                    }}
                                >
                                    Submit
                                </Button>
                                <Button
                                    className="mx-2"
                                    variant="dark"
                                    onClick={(e) => navigate("/my-spiders")}
                                    style={{ padding: ".75rem" }}
                                >
                                    Back
                                </Button>
                            </div>
                        </Form>
                    </section>
                }
            </Container>
        </>
    )
}


export default SpiderDetailView;
