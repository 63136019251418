import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { CSSTransition } from 'react-transition-group';
import { FaSyncAlt } from "react-icons/fa";

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import TaskResultsTable from '../components/tables/TaskResultsTable';
import SpiderContext from '../components/contexts/SpiderContext';
import ItemsDropdown from '../components/ItemsDropdown';
import DetailedTaskResultModal from '../components/modals/DetailedTaskResultModal';

import ProjectContext from '../components/contexts/ProjectContext';
import config from '../config';
import DjangoAPIClient from '../client';

const client = new DjangoAPIClient(config);


const ScheduledTasks = ({ title, userData }) => {
    const [projectContext, setProjectContext] = useContext(ProjectContext);
    const [spiderContext, setSpiderContext] = useContext(SpiderContext);

    const [myProjects, setMyProjects] = useState([]);

    const [mySpiders, setMySpiders] = useState([]);
    const [noSpiders, setNoSpiders] = useState(false);
    const [dropdownLoading, setDropdownLoading] = useState(false);

    const [selectedTaskResult, setSelectedTaskResult] = useState();
    const [showDetailedViewModal, setShowDetailedViewModal] = useState(false);

    const [refreshData, setRefreshData] = useState(0);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ msg: "" });


    ////////// Page title & mobile detection
    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);
    //////////

    function fetchMyProjects() {
        setDropdownLoading(true);
        client.getDropdownProjects()
            .then(
                (response) => {
                    const projects = response.data.filter(function (el) { return el.name != "Generic spiders"; });
                    setMyProjects(projects);
                    if (projectContext.id === 0) {
                        setProjectContext(response.data[0]);
                    }
                    setDropdownLoading(false);
                }
            )
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        fetchMyProjects()
    }, [])

    useEffect(() => {
        fetchMySpiders();
    }, [projectContext])

    function fetchMySpiders() {
        setDropdownLoading(true);
        client.getDropdownSpiders(projectContext.id)
            .then(
                (response) => {
                    if (response.data.length === 0) {
                        setMySpiders([]);
                        setNoSpiders(true);
                        setSpiderContext({ id: 0, name: "No spiders found" });
                    } else {
                        setMySpiders(response.data);
                        if (!response.data.map(obj => obj.id).includes(spiderContext.id)) {
                            setSpiderContext(response.data[0]);
                        }
                        setNoSpiders(false);
                    }
                    setDropdownLoading(false);
                }
            )
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <CSSTransition
                    in={showAlert}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                        {alertMessage.msg}
                    </Alert>
                </CSSTransition>
                <DetailedTaskResultModal
                    showModal={showDetailedViewModal}
                    setShowModal={setShowDetailedViewModal}
                    client={client}
                    task={selectedTaskResult}
                // taskResult={selectedTaskResult}
                // setTaskResult={setSelectedTaskResult}
                />
                <section>
                    <Row className='px-3 py-1 bg-white'>
                        <Col sm={12} md={6} >
                            <header className="bg-white pt-2">
                                <h1 className='text-start'>My tasks</h1>
                            </header>
                        </Col>
                    </Row>
                    <Row className="px-3 py-2">
                        <Col sm={5} md={3} className='text-start'>
                            <ItemsDropdown
                                items={myProjects}
                                selected={projectContext}
                                setSelected={setProjectContext}
                                attr={"name"}
                                isLoading={dropdownLoading}
                            />
                        </Col>
                        <Col sm={4} md={3} className='text-start'>
                            <ItemsDropdown
                                items={mySpiders}
                                selected={spiderContext}
                                setSelected={setSpiderContext}
                                attr={"name"}
                                isLoading={dropdownLoading}
                            />
                        </Col>
                        <Col sm={3} md={6} className='refresh'>
                            <Button
                                type="button"
                                variant='dark'
                                className="rounded-circle"
                                onClick={(e) => {
                                    setRefreshData(refreshData + 1);
                                }}
                            >
                                <FaSyncAlt />
                            </Button>
                        </Col>
                    </Row>
                    <Row className='p-3 '>
                        <TaskResultsTable
                            client={client}
                            spider={spiderContext}
                            noSpiders={noSpiders}
                            setTask={setSelectedTaskResult}
                            setShowDetailedViewModal={setShowDetailedViewModal}
                            setShowAlert={setShowAlert}
                            setAlertMessage={setAlertMessage}
                            refreshData={refreshData}
                            maxPagination={10}
                        />
                    </Row>
                </section>
            </Container>
        </>
    );

}
export default ScheduledTasks;

