import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { React, useState, useEffect, useCallback, useRef } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import PrivateRoute from './components/PrivateRoute';

import PageNotFound from './pages/PageNotFound'
import Login from './pages/user/LoginPage';
import SignUpUser from './pages/user/RegisterPage';
import ChangePassword from './pages/user/ChangePassword';
import SendResetPasswordEmail from './pages/user/ResetPasswordSendEmail';
import ResetPassword from './pages/user/ResetPasswordPage';
import ActivatedAccount from './pages/user/ActivatedAccount';
import Homepage from './pages/HomePage';
import ScheduledTasks from './pages/ScheduledTasks';
import SpidersPage from './pages/Spiders';
import ProjectsPage from './pages/Projects';
import Sidebar from './components/Sidebar';
import Documentation from './pages/Documentation'
import Quickstart from './pages/Quickstart'
import FAQ from './pages/FAQ'
import ProfilePage from './pages/user/ProfilePage';
import ProjectContext from './components/contexts/ProjectContext';
import SpiderContext from './components/contexts/SpiderContext';
import ActivateAccountMessage from './pages/user/ActivateAccountMessage';
import ResetPasswordMessage from './pages/user/ResetPasswordMessage';
import UserFiles from './pages/UserFiles';
import CreateSpiderPage from './pages/CreateSpider';
import SpiderDetailView from './pages/SpiderDetailView';
import Eretailers from './pages/Eretailers';
import Reports from './pages/pbi-related/Reports';
import Dashboard from './pages/Dashboard';
import { use } from 'marked';
import HomepageExternal from './pages/HomePage_external';
import EretailersExternal from './pages/Eretailers_external';
import ScrapedData from './pages/ScrapedData';

import config from './config';
import DjangoAPIClient from './client';

const client = new DjangoAPIClient(config);


function App() {
	const [projectContext, setProjectContext] = useState({id: 0, name: ""})
	const [spiderContext, setSpiderContext] = useState({id: 0, name: ""})

	const [fetchingUser, setFetchingUser] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(undefined);
	const [userData, setUserData] = useState(undefined);

	const fetchAuthToken = useCallback(async () => {
		const tokenString = localStorage.getItem("token");
		if (tokenString) {
			const token = JSON.parse(tokenString)["token"];
			const decodedAccessToken = jwtDecode(token.access);
			if (moment.unix(decodedAccessToken.exp).toDate() > new Date()) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		} else {
			setIsLoggedIn(false);
		}
	})

	useEffect(() => {
		setFetchingUser(true);
		if (isLoggedIn) {
			client.fetchUserData().then(
				(response) => {
					setUserData(response.data);
				}
			);
		} else if (isLoggedIn !== undefined) {
			setUserData({});
		}
	}, [isLoggedIn])

	useEffect(() => {
		if (userData !== undefined) {
			setFetchingUser(false);
		}
	}, [userData])

	useEffect(() => {
		const interval = setInterval(() => {
			fetchAuthToken()
		}, 10);
		return () => clearInterval(interval);
	}, [fetchAuthToken])

	return (
		<>
			<BrowserRouter>
				<ProjectContext.Provider value={[projectContext, setProjectContext]}>
					<SpiderContext.Provider value={[spiderContext, setSpiderContext]}>
						{/* <Sidebar reportPage={reportPage}/> */}
						{/* <Sidebar isLoggedIn={isLoggedIn}/> */}
						<Routes>
							<Route path='*' element={<PageNotFound title="Scrapfinderlab - 404"/>} />
							<Route path="/" element={<HomepageExternal title="Scrapfinderlab - homepage" isLoggedIn={isLoggedIn}/>} />
							<Route path="/eretailers-ext" element={<EretailersExternal title='Scrapfinderlab - e-retailers'/>} />
							<Route path="/login" element={<Login userData={userData} />} />
							<Route path="/register/" element={<SignUpUser userData={userData}/>} />
							<Route path="/send-reset-password-email/" element={<SendResetPasswordEmail userData={userData}/>} />
							<Route path="api/users/reset/:uid/:token" element={<ResetPassword />} />
							<Route path="api/users/activate/:uidb64/:token" element={<ActivatedAccount />} />
							<Route path='/activate-your-account' element={<ActivateAccountMessage />} />
							<Route path='/reset-email-message' element={<ResetPasswordMessage />} />
							{/* Private routes for DEA */}
							<Route
								path="/dea"
								element={
									<PrivateRoute
										component={Dashboard}
										title="Scrapfinderlab - dashboard"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							{/* <Route 
								path="/dea"
								element={
									<PrivateRoute 
										component={Homepage}
										title="Scrapfinderlab DEA"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>
								}
								/> */}
							<Route 
								path="/eretailers" 
								element={
									<PrivateRoute
										component={Eretailers}
										title='Scrapfinderlab - e-retailers'
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/pbi/reports"
								element={
									<PrivateRoute
										component={Reports}
										title="PowerBI - reports"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/my-projects"
								element={
									<PrivateRoute
										component={ProjectsPage}
										title="Scrapfinderlab - projects"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/my-spiders"
								element={
									<PrivateRoute
										component={SpidersPage}
										title="Scrapfinderlab - spiders"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/my-tasks"
								element={
									<PrivateRoute
										component={ScheduledTasks}
										title="Scrapfinderlab - tasks"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route
								path="/spiders/:spiderId"
								element={
									<PrivateRoute
										component={SpiderDetailView}
										title="Scrapfinderlab - spider details"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/create-spider"
								element={
									<PrivateRoute
										component={CreateSpiderPage}
										title="Scrapfinderlab - create spider"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/project-files"
								element={
									<PrivateRoute
										component={UserFiles}
										title="Scrapfinderlab - project files"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/change-password"
								element={
									<PrivateRoute
										component={ChangePassword}
										title="Scrapfinderlab - change password"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/user-profile"
								element={
									<PrivateRoute
										component={ProfilePage}
										title="Scrapfinderlab - my profile"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/documentation" 
								element={
									<PrivateRoute
										component={Documentation}
										title="Scrapfinderlab - documentation"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/quick-start" 
								element={
									<PrivateRoute
										component={Quickstart}
										title="Scrapfinderlab - Quickstart"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/frequently-asked-questions" 
								element={
									<PrivateRoute
										component={FAQ}
										title="Scrapfinderlab - FAQ"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
							<Route 
								path="/scraped-data" 
								element={
									<PrivateRoute
										component={ScrapedData}
										title="Scrapfinderlab - scraped data"
										fetchingUser={fetchingUser}
										userData={userData}
										requiresDEA={true}
									/>}
							/>
						</Routes>
					</SpiderContext.Provider>
				</ProjectContext.Provider>
			</BrowserRouter>
		</>
	);
}

export default App;