import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import SelectOrAddProject from "../components/SelectOrAddProject";
import SelectOrAddCollection from "../components/SelectOrAddCollection";
import SelectOrUploadFile from "../components/SelectOrUploadFile";
import Navbar from '../components/Navbar';
import { CSSTransition } from 'react-transition-group';
import Alert from 'react-bootstrap/Alert';

import config from '../config';
import DjangoAPIClient from '../client';
import ProjectContext from "../components/contexts/ProjectContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const client = new DjangoAPIClient(config);

const CreateSpiderPage = ({ title, userData }) => {
    const [projectContext, setProjectContext] = useContext(ProjectContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCollections, setIsLoadingCollections] = useState(true);

    const navigate = useNavigate();

    // loaded projects/files:
    const [myProjects, setMyProjects] = useState([]);
    const [myCollections, setMyCollections] = useState([]);
    const [spiderConfigs, setSpiderConfigs] = useState([]);
    const [yamlConfigs, setYamlConfigs] = useState([]);
    const [templateFiles, setTemplateFiles] = useState([]);



    // data to create spider:
    const [newSpiderName, setNewSpiderName] = useState();
    const [selectedCollection, setSelectedCollection] = useState({ id: 0, collection_name: "Select" });
    const [selectedSpiderConfig, setSelectedSpiderConfig] = useState({ id: 0, file: "Select" });
    const [selectedYamlConfig, setSelectedYamlConfig] = useState({ id: 0, file: "Select" });
    const [selectedTemplateFile, setSelectedTemplateFile] = useState({ id: 0, file: "Select" });
    const [spiderKwargs, setSpiderKwargs] = useState("{}");

    const [validated, setValidated] = useState(false);
    const [error, setError] = useState({ statusText: "", project: "", mongodb_collection_name: "", spider_config_file: "", template_file: "", yaml_config_file: "" });    
	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState({ statusText: "", project: "", mongodb_collection_name: "", spider_config_file: "", template_file: "", yaml_config_file: "" });

    const [genericSpiderConfigs, setGenericSpiderConfigs] = useState(false);
    const [projectSpiderConfigs, setProjectSpiderConfigs] = useState(true);
    const [genericYamlConfigs, setGenericYamlConfigs] = useState(false);
    const [projectYamlConfigs, setProjectYamlConfigs] = useState(true);

    ////////// Page title
	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);
    //////////

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
    
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

    };


    useEffect(() => {
        fetchMyProjects();
        fetchMyCollections();
        // fetchSpiderConfigs();
        // fetchYamlConfigs();
        // fetchTemplateFiles();
    }, [title])

    useEffect(() => {
        fetchSpiderConfigs();
        fetchYamlConfigs();
        fetchTemplateFiles();
    }, [projectContext, genericSpiderConfigs, genericYamlConfigs])

	function fetchMyProjects() {
		client.getDropdownProjects()
		.then(
			(response) => {
				setMyProjects(response.data);
				if (projectContext.id === 0) {
					setProjectContext(response.data[0]);
				}
                setIsLoading(false);
			}
		)
		.catch((error) => {
			console.log(error)
		});
	}

    function fetchMyCollections() {
        setIsLoadingCollections(true);
        client.getMyCollections().then(
            (response) => {
                setMyCollections(response.data);
                setIsLoadingCollections(false);
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function handleProjectCreate(newProjectName, is_shared, group) {
        client.createNewProject(newProjectName, is_shared, group).then(
            (response) => {
                setProjectContext({ id: response.data.id, name: response.data.name });
                fetchMyProjects();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function handleCollectionCreate(newCollectionName) {
        client.createNewCollection(newCollectionName).then(
            (response) => {
                setSelectedCollection({ id: response.data.id, collection_name: response.data.collection_name });
                fetchMyCollections();
            }
        )
            .catch((error) => {
                console.log(error)
                setError(error.response.data);
            });
    }

    function fetchSpiderConfigs() {
        const projectName = genericSpiderConfigs ? "Generic spiders" : projectContext.name;
        client.getDropdownSpiderConfigs(projectName).then(
            (response) => {
                setSpiderConfigs(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function fetchYamlConfigs() {
        const projectName = genericYamlConfigs ? "Generic spiders" : projectContext.name;
        client.getDropdownYamlConfigs(projectName).then(
            (response) => {
                setYamlConfigs(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function fetchTemplateFiles() {
        const projectName = projectContext.name;
        client.getDropdownTemplateFiles(projectName).then(
            (response) => {
                setTemplateFiles(response.data)
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function toSpidersPage() {
        navigate("/my-spiders");
    }

    function handleSpiderCreate(
        projectId, spiderName, mongoCollectionId,
        spiderConfigId, yamlConfigId, templateId, 
        kwargs
    ) {
        // Check if the spider name is provided
        if (!spiderName) {
    
            // Display the error message in the alert component
            setShowAlert(true);
            setAlertMessage({
                name: "Spider name must be provided.",
            });
            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            return;
        }
    
        client.createNewSpider(projectId, spiderName, mongoCollectionId, spiderConfigId, yamlConfigId, templateId, kwargs)
            .then(
                (response) => {
                    console.log(response.data);
                    toSpidersPage();
                }
            )
            .catch((error) => {
                console.log(error.response.data);
                setError(error.response.data);
                setShowAlert(true);
                setAlertMessage({
                    // Extract custom error messages from the response
                    project: error.response.data.project || "",
                    mongodb_collection_name: error.response.data.mongodb_collection || "",
                    spider_config_file: error.response.data.spider_config_file || "",
                    template_file: error.response.data.template_file || "",
                    yaml_config_file: error.response.data.yaml_config_file || ""
                });
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            });
    }
    
    

    function uploadSpiderConfig(newFile) {
        client.uploadSpiderConfig(newFile, projectContext.name).then(
            (response) => {
                console.log(response.data);
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedSpiderConfig({ id: response.data.data.id, file: short_name});
                fetchSpiderConfigs();
            }
        )
        .catch((error) => {
            console.log(error);
        });
    }
    

    function uploadYamlConfig(newFile) {
        client.uploadYamlConfig(newFile, projectContext.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedYamlConfig({ id: response.data.data.id, file: short_name });
                fetchYamlConfigs();
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    function uploadTemplateFile(newFile) {
        client.uploadTemplateFile(newFile, projectContext.name).then(
            (response) => {
                const filename = response.data.data.file.substr(response.data.data.file.lastIndexOf('/') + 1);
                const short_name = filename.split('.').slice(0, -1).join('.');
                setSelectedTemplateFile({ id: response.data.data.id, file: short_name });
                fetchTemplateFiles();
            }
        )
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <>
            <Sidebar userData={userData}/>
            <Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <CSSTransition
                    in={showAlert}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                >
                    <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                        <strong>Submitted data incorrect!!!<br /></strong>
                        {alertMessage.name}{alertMessage.name && <br />}
                        {alertMessage.project}{alertMessage.project && <br />}
                        {alertMessage.mongodb_collection_name}{alertMessage.mongodb_collection_name && <br />}
                        {alertMessage.spider_config_file}{alertMessage.spider_config_file && <br />}
                        {alertMessage.template_file}{alertMessage.template_file && <br />}
                        {alertMessage.yaml_config_file}{alertMessage.yaml_config_file && <br />}
                        {alertMessage.statusText}{alertMessage.statusText && <br />}
                    </Alert>
                </CSSTransition>

                    <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                            <header className="bg-white pt-2">
                                <h1 className='text-start'>Create new spider</h1>
                            </header>
                    </Row>
                    {isLoading && <div className='h3 p-4 text-start'>Loading...</div>}
                    {!isLoading &&
                    <section>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Project:
                            </Col>
                            <Col md={8}>
                                <SelectOrAddProject
                                    selectedProject={projectContext}
                                    setSelectedProject={setProjectContext}
                                    myProjects={myProjects}
                                    isInvalid={!!error}
                                    handleProjectCreate={handleProjectCreate}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.project}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Spider name:
                            </Col>
                            <Col className="ps-4 spider-name-md-5">
                                <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    className="rounded-pill"
                                    placeholder="Spider name"
                                    isInvalid={newSpiderName === ""}
                                    value={newSpiderName}
                                    onChange={(e) => setNewSpiderName(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.name}
                                </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                MongoDB collection name:
                            </Col>
                            <Col md={8} className="ps-3">
                                <SelectOrAddCollection
                                    selectedCollection={selectedCollection}
                                    setSelectedCollection={setSelectedCollection}
                                    myCollections={myCollections}
                                    isInvalid={!!error.mongodb_collection_name}
                                    handleCollectionCreate={handleCollectionCreate}
                                    isLoading={isLoadingCollections}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {/* Field cannot be empty */}
                                    error.mongodb_collection_name
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Spider config file (generic files&nbsp;
                                    <Form.Check
                                        type="checkbox"
                                        checked={genericSpiderConfigs}
                                        onChange={(e) => {
                                            setGenericSpiderConfigs(e.target.checked);
                                            setProjectSpiderConfigs(!e.target.checked);
                                        }}
                                    />):
                            </Col>
                            <Col md={8}>
                                <SelectOrUploadFile
                                    selected={selectedSpiderConfig}
                                    setSelected={setSelectedSpiderConfig}
                                    items={spiderConfigs}
                                    error={error}
                                    isInvalid={!!error}
                                    handleUpload={uploadSpiderConfig}
                                    extension={[".py"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.spider_config_file}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Yaml config file (generic files&nbsp;
                                    <Form.Check
                                        type="checkbox"
                                        checked={genericYamlConfigs}
                                        onChange={(e) => {
                                            setGenericYamlConfigs(e.target.checked);
                                            setProjectYamlConfigs(!e.target.checked);
                                        }}
                                    />):
                            </Col>
                            <Col md={8}>
                                <SelectOrUploadFile
                                    selected={selectedYamlConfig}
                                    setSelected={setSelectedYamlConfig}
                                    items={yamlConfigs}
                                    error={error}
                                    isInvalid={!!error}
                                    handleUpload={uploadYamlConfig}
                                    extension={[".yaml"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Field cannot be empty
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Template file:
                            </Col>
                            <Col md={8}>
                                <SelectOrUploadFile
                                    selected={selectedTemplateFile}
                                    setSelected={setSelectedTemplateFile}
                                    items={templateFiles}
                                    error={error}
                                    isInvalid={!!error}
                                    handleUpload={uploadTemplateFile}
                                    extension={[".xlsx", ".csv"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Field cannot be empty
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                        <Row className="mx-3 my-3">
                            <Col md={4} className="d-flex align-items-center">
                                Spider kwargs:
                            </Col>
                            <Col className="ps-4 spider-name-md-5">
                                <Form.Group>
                                <Form.Control
                                    required
                                    type="text"
                                    className="rounded-pill"
                                    placeholder="{}"
                                    isInvalid={!spiderKwargs.startsWith("{") || !spiderKwargs.endsWith("}")}
                                    value={spiderKwargs}
                                    onChange={(e) => setSpiderKwargs(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Kwargs needs to be a valid JSON format
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    <hr></hr>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <Button
                                className="mx-2"
                                variant="pf23"
                                onClick={(e) => {
                                    handleSpiderCreate(
                                        projectContext.id, newSpiderName, selectedCollection.id,
                                        selectedSpiderConfig.id, selectedYamlConfig.id, selectedTemplateFile.id, spiderKwargs
                                    );
                                }}
                            >
                                Create
                            </Button>
                            <Button
                                className="mx-2"
                                variant="dark"
                                onClick={(e) => navigate(-1)}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </section>
            }
            </Container>
        </>
    )
}


export default CreateSpiderPage;