import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DjangoAPIClient from '../../client';
import config from '../../config';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Navbar from '../../components/Navbar';

const client = new DjangoAPIClient(config);


const SendResetPasswordEmail = ({ userData }) => {
    const [error, setError] = useState({email: ""})
    const [SendResetPasswordEmailForm, setSendResetPasswordEmailForm] = useState({email: ""})

    const navigate = useNavigate()

    const [title] = useState("Scrapfinderlab - reset email");

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);

    const toResetEmailMessage = () => {
        navigate("/reset-email-message")
    }

    const handleLogout = () => {
        client.logout();
        navigate('/')
    }

    const onSendResetPasswordEmailForm = (e) => {
        e.preventDefault()
        setError(false)

        client.sendResetPasswordEmail(SendResetPasswordEmailForm.email)
            .then(() => {
                toResetEmailMessage()
            })
            .catch((err) => {
                setError({email: err.response.data.errors.non_field_errors[0]})
            });
    }

    return (
        <Container fluid className='App bg-light-pf23 rounded-bottom min-vh-100'>
            <Navbar goBackButton={true}></Navbar>
            {(userData !== undefined && Object.keys(userData).length > 0) && 
                <Row className="App-header">
                    <h1>You are logged in.</h1>
                    <Button variant='pf23 my-3 w-25 mx-auto' onClick={handleLogout}>Logout</Button>
                </Row>
            }
            {(userData === undefined || Object.keys(userData).length === 0) && 
                <Row className="App-header justify-content-center align-items-center">
                    <h1>Reset password</h1>
                    <Col lg={4} md={6} xs={10}>
                        <Form className="" onSubmit={(e) => onSendResetPasswordEmailForm(e)}>
                            <input type="hidden" name="remember" defaultValue="true" />
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email address"
                                className="my-3">
                                <Form.Control 
                                type="email" 
                                placeholder="n.surname@pathfinder23.com" 
                                error={error.email}
                                isInvalid={error.email}
                                value={SendResetPasswordEmailForm.email}
                                onChange={(e) => setSendResetPasswordEmailForm({ ...SendResetPasswordEmailForm, email: e.target.value })}
                                />
                            <Form.Control.Feedback type="invalid">
                                {error.email}
                            </Form.Control.Feedback>
                            </FloatingLabel>

                            <Row className='g-0'>
                            <Button
                                variant='pf23'
                                type="submit"
                                className='mx-auto mb-2'
                                error={error.password} 
                                title={"SendResetPasswordEmail"}
                            >
                                Send email with link
                            </Button>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default SendResetPasswordEmail;
