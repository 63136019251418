import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import config from '../../config';
import DjangoAPIClient from '../../client';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Navbar from '../../components/Navbar';

const client = new DjangoAPIClient(config);


const SignUpUser = ({userData}) => {
    const [error, setError] = useState({ email: "", password: "", password2: "" })
    const [SignUpUserForm, setSignUpUserForm] = useState({ email: "", password: "", password2: "" })

    const navigate = useNavigate()

    const toActivateYourAccountPage = () => {
        navigate("/activate-your-account")
    }

    const [title] = useState("Scrapfinderlab - register");

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);

    const handleLogout = () => {
        client.logout();
        navigate('/')
    }

    const onSignUpUser = (e) => {
        e.preventDefault();
        setError(false);

        // check if @#brandnewgalaxy.com
        // if (!SignUpUserForm.email.endsWith("@brandnewgalaxy.com" || SignUpUserForm.email === "")) {
        //     setError({ email: "Enter correct email (only BNG emails)" });
        //     return;
        // }

        // Check if password and password2 match
        if (SignUpUserForm.password !== SignUpUserForm.password2) {
            setError({ password: "Passwords do not match!", password2: "Passwords do not match!" });
            return;
        }

        // Check if password is not empty
        if (SignUpUserForm.password == "" || SignUpUserForm.password2 == "") {
            setError({ password: "Passwords do not match!", password2: "Password cannot be empty!" });
            return;
        }

        client.register(SignUpUserForm.email, SignUpUserForm.password, SignUpUserForm.password2)
            .then(() => {
                toActivateYourAccountPage();
            })
            .catch((err) => {
                setError({ email: err.response.data.errors.email })
                console.log(err);
            });
    };

    return (
        <>
            <Container fluid className='App bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar goBackButton={true}></Navbar>
                {userData !== undefined && Object.keys(userData).length > 0 &&
                    <Row className="App-header">
                        <h1>You are logged in</h1>
                        <Button variant='pf23 my-3 w-25 mx-auto' onClick={handleLogout}>Logout</Button>
                    </Row>
                }
                {(userData === undefined || Object.keys(userData).length === 0) &&
                    <Row className="App-header">
                        <h1>Register new account</h1>
                        <Col lg={4} md={6} xs={10} className="mx-auto">
                            <Form onSubmit={(e) => onSignUpUser(e)}>
                                <input type="hidden" name="remember" defaultValue="true" />
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="my-3">
                                    <Form.Control
                                        type="email"
                                        placeholder="n.surname@pathfinder23.com"
                                        error={error.email}
                                        isInvalid={error.email}
                                        value={SignUpUserForm.email}
                                        onChange={(e) => setSignUpUserForm({ ...SignUpUserForm, email: e.target.value })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error.email}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="floatingPassword"
                                    label="Password"
                                    className='mb-3'>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        error={error.password}
                                        isInvalid={error.password}
                                        value={SignUpUserForm.password}
                                        onChange={(e) => setSignUpUserForm({ ...SignUpUserForm, password: e.target.value })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error.password}
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="floatingPassword"
                                    label="Confirm password"
                                    className='mb-3'>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm password"
                                        error={error.password2}
                                        isInvalid={error.password2}
                                        value={SignUpUserForm.password2}
                                        onChange={(e) => setSignUpUserForm({ ...SignUpUserForm, password2: e.target.value })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {error.password2}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                                {/* <div className='text-danger'>
                        {error.email && <p>{error.email}</p>}
                        {error.password && <p>{error.password}</p>}
                        {error.password2 && <p>{error.password2}</p>}
                        </div> */}
                                <Row className='g-0'>
                                    <Button
                                        type="submit"
                                        variant='pf23'
                                        className='mx-auto mb-2'
                                        error={error.password} title={"SignUpUser"}>
                                        Create account
                                    </Button>
                                    <div className='fs-5 mt-4 mb-2 text-start'>Already have an account? Log in <a role="button" onClick={(e) => navigate("/login")} className="fw-bold hyperlink px-0">here</a>.</div>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    )
}

export default SignUpUser;
