import React from "react";
import { useState, useEffect } from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ItemsDropdown from "../ItemsDropdown";

import Delayed from "../DelayedComponent";

// props.client -> DjangoAPIClient
// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.project -> project object

const ManageProjectModal = (props) => {
	const [error, setError] = useState("")
	const [projectDetails, setprojectDetails] = useState({})
	
	const [newProjectName, setNewProjectName] = useState("");
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({ id: 1, name: "Please select a group" })

	useEffect(() => {
		props.client.getUserGroups()
			.then(response => {
				setGroups(response.data.filter(group => group.name.startsWith("EXTERNAL_") === false));
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (props.project.id !== 0){
			props.client.getProjectDetails(props.project.id)
				.then(response => {
					setprojectDetails(response.data);
					setNewProjectName(response.data.name);
					setSelectedGroup({ id: response.data.group, name: response.data.group_name })
				})
				.catch(error => {
					console.log(error);
				});
			}
	}, [props.project]);

	function closeModal(setShowModal, setNewProjectName, setSelectedGroup) {
		setNewProjectName(projectDetails.name);
		setSelectedGroup({ id: projectDetails.group, name: projectDetails.group_name })
		setShowModal(false);
	}

	function handleProjectUpdate(projectId, newProjectName, groupName, setShowModal, refreshData, setRefreshData) {
		props.client.updateProject(projectId, newProjectName, groupName)
			.then(response => {
					setShowModal(false)
					setRefreshData(refreshData+1);
				}
			)
			.catch((error) => {
				setError(error.response.data);
			});
	}

	return (
		<Modal size="lg" show={props.showModal} onHide={(e) => closeModal(props.setShowModal, setNewProjectName, setSelectedGroup)}>
			<Modal.Header closeButton>
				<Modal.Title>Edit your project</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Delayed waitBeforeShow="800">
					<Form>
						<Form.Group controlId="projectName">
							<Row className="pb-2">
								<Col sm={12} md={3}>
									<Form.Label>Project Name:</Form.Label>
								</Col>
								<Col sm={12} md={6}>
									<Form>
										<Form.Group>
											<Form.Control
												type="text"
												className="rounded-pill"
												placeholder={newProjectName}
												value={newProjectName}
												onChange={(e) => setNewProjectName(e.target.value)}
											/>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Form.Group>
						<Form.Group controlId="groupName">
							<Row>
								<Col sm={12} md={3}>
									<Form.Label>Group Name:</Form.Label>
								</Col>
								<Col sm={12} md={6}>
									<Form.Check>
										<ItemsDropdown
											items={groups}
											selected={selectedGroup}
											setSelected={setSelectedGroup}
											attr={"name"}
										/>
									</Form.Check>
								</Col>
							</Row>
						</Form.Group>
					</Form>
				</Delayed>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="modal"
					className="rounded-pill"
					onClick={(e) => handleProjectUpdate(projectDetails.id, newProjectName, selectedGroup, props.setShowModal, props.refreshData, props.setRefreshData)}
				>
					Save
				</Button>
				<Button
					variant="dark"
					className="rounded-pill"
					onClick={(e) => closeModal(props.setShowModal, setNewProjectName, setSelectedGroup)}
				>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ManageProjectModal;