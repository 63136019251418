import React, {useState, useEffect} from 'react';
import {Route, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import DjangoAPIClient from '../client';
import config from '../config';
import LoginForm from './LoginForm';

const client = new DjangoAPIClient(config);

const AnonymousUser = ({errorMessage, isLoggedIn}) =>{
    const navigate = useNavigate()
    const [title] = useState("Scrapfinderlab - access denied");

    useEffect(() => {
      // This will run when the page first loads and whenever the title changes
      document.title = title;
    }, [title]);

    function toHome(){
      navigate("/")
      client.logout()
    }

    return(
        <Row className="App-header">
            {errorMessage}
            <div>
              {!isLoggedIn &&
                <LoginForm client={client} stayOnCurrentPage={true}/>
              }
              {isLoggedIn &&
                <Button  variant='pf23 my-3 w-25 mx-auto' onClick={toHome} className='mt-3'>Go to homepage</Button>
              }
            </div>
        </Row>
    )
}
  
export default AnonymousUser;