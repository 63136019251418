import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Navbar from '../../components/Navbar';

import DjangoAPIClient from '../../client';
import config from '../../config';

const client = new DjangoAPIClient(config);


const ResetPassword = () => {
    const [title] = useState("Scrapfinderlab - Reset Password");
    const [error, setError] = useState({password: "" , password2: ""})
    const [ResetPasswordForm, setResetPasswordForm] = useState({password: "" , password2: ""})
    const { uid, token } = useParams()
    const navigate = useNavigate()

    const toLoginPage = () => {
        navigate("/")
    }
    
    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);

    const onResetPassword = (e) => {
        e.preventDefault()
        setError(true)

        // Check if password and password2 match
        if (ResetPasswordForm.password !== ResetPasswordForm.password2) {
            setError({ password2: "Passwords do not match!" });
            return;
        }
        
        // Check if password is not empty
        if (ResetPasswordForm.password == "" || ResetPasswordForm.password2 == "") {
        setError({ password2: "Password cannot be empty!" });
        return;
        }

        client.resetPassword(ResetPasswordForm.password, ResetPasswordForm.password2, uid, token)
            .then( () => {
                toLoginPage()
            })
            .catch( (err) => {
                setError(true);
                console.log(err)
            });
    }

    return (
        <>
            <Container fluid className='App bg-light-pf23 rounded-bottom min-vh-100'>
                <Navbar/>
                <Row className="App-header">
                    <h1>Create new password</h1>
                </Row>
                <Row className='justify-content-center align-items-center'>
                    <Col lg={4} md={6} xs={10}>
                        <Form onSubmit={(e) => onResetPassword(e)}>
                            <FloatingLabel 
                                controlId="floatingPassword" 
                                label="Password"
                                className='mb-3'>
                                    <Form.Control 
                                    type="password" 
                                    placeholder="Password"
                                    error={error.password}
                                    isInvalid={error.password}
                                    value={ResetPasswordForm.password}
                                    onChange={(e) => setResetPasswordForm({ ...ResetPasswordForm, password: e.target.value })}
                                    />
                                <Form.Control.Feedback type="invalid">
                                    {error.password}
                                </Form.Control.Feedback>
                                </FloatingLabel>

                                <FloatingLabel 
                                controlId="floatingPassword" 
                                label="Confirm password"
                                className='mb-3'>
                                    <Form.Control 
                                    type="password" 
                                    placeholder="Confirm password"
                                    error={error.password2}
                                    isInvalid={error.password2}
                                    value={ResetPasswordForm.password2}
                                    onChange={(e) => setResetPasswordForm({ ...ResetPasswordForm, password2: e.target.value })}
                                    />
                                <Form.Control.Feedback type="invalid">
                                    {error.password2}
                                </Form.Control.Feedback>
                                </FloatingLabel>
                                <input type="hidden" name="remember" defaultValue="true" />
                                {/* {error.password && <p className="text-danger">{error.password}</p>}
                                {error.password2 && <p className="text-danger">{error.password2}</p>} */}
                            <Row className='g-0'>
                                <Button 
                                    variant="pf23"
                                    type="submit"
                                    className='mx-auto'
                                    error={error.password} 
                                    title={"ResetPassword"}
                                >
                                    Reset password
                                </Button>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ResetPassword;
