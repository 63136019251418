import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Navbar from '../../components/Navbar';
import DjangoAPIClient from '../../client';
import config from '../../config';
import LoadingPage from '../LoadingPage';

const client = new DjangoAPIClient(config);


const ActivateAccount = () => {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();

    const [title] = useState("Scrapfinderlab - email confirmed");
    const [text, setText] = useState("loading");

    useEffect(() => {
        document.title = title;

        client.activateAccount(uidb64, token)
            .then(() => {
                console.log("confirmed");
                setText("confirmed");
            })
            .catch((err) => {
                console.log(err);
                setText("error");
            });
    }, [title, uidb64, token]);

    const toHome = () => {
        navigate("/");
    };

    return (
        <>
            {text === "loading" && <LoadingPage />}
            {text !== "loading" && (
                <Container fluid className="App bg-light-pf23 rounded-bottom min-vh-100">
                    <Navbar></Navbar>
                    <Row className="App-header">
                        {text === "confirmed" && (
                            <>
                                <h1>Your email is confirmed!</h1>
                                <Button
                                    variant="pf23 my-3 w-25 mx-auto"
                                    onClick={toHome}
                                    className="mt-3"
                                >
                                    Go to homepage
                                </Button>
                            </>
                        )}
                        {text === "error" && (
                            <>
                                <h1>Something went wrong!</h1>
                                <h3>Please contact our team.</h3>
                                <Button
                                    variant="pf23 my-3 w-25 mx-auto"
                                    onClick={toHome}
                                    className="mt-3"
                                >
                                    Go to homepage
                                </Button>
                            </>
                        )}
                    </Row>
                </Container>
            )}
        </>
    );
};

export default ActivateAccount;
