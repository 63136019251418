import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import DjangoAPIClient from '../client';
import Image from 'react-bootstrap/Image'
import Scrapfinderlab_logo from '../assets/Scrapfinder logo02.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Navbar from 'react-bootstrap/Navbar';
import Navbar from '../components/Navbar';
import HomepageFooter from '../components/HomepageFooter'
import Alert from 'react-bootstrap/Alert';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';


const LoadingPage = () => {
  const navigate = useNavigate();

  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
    document.activeElement.blur()
  };

  return (
    <>
      <Container fluid className='App bg-light rounded-bottom d-flex flex-column justify-content-between min-vh-100'>
        <Navbar></Navbar>
        {/* <Row className="rounded-top px-3 py-0" style={{backgroundColor: "white", minHeight: "45px", margin: "0 -12px" }}/> */}
        <Row className='justify-content-center align-items-center flex-column'>
          <Spinner/>
          {/* <Image src={Scrapfinderlab_logo} className="logo-loading"></Image> */}
          <Col sm={9} md={5} className='fw-semibold fs-5 py-4'>
            Loading ...
          </Col>
        </Row>
        <Row className="rounded-bottom px-3 py-0" style={{backgroundColor: "white", minHeight: "53px", margin: "0 -12px" }}/>
        {/* <HomepageFooter/> */}
      </Container>
    </>
)
}

export default LoadingPage;