import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import config from '../../config';
import DjangoAPIClient from '../../client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Alert from 'react-bootstrap/Alert';
import Navbar from '../../components/Navbar';
import { CSSTransition } from 'react-transition-group';
import LoginForm from '../../components/LoginForm';

const client = new DjangoAPIClient(config);


function LoginPage({ userData }) {
	const navigate = useNavigate();
	const [error, setError] = useState({ email: "", password: "" })
	const [loginForm, setLoginForm] = useState({ email: '', password: '' })

	const [showAlert, setShowAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const [title] = useState("Scrapfinderlab - login");

	const toAddUserPage = () => {
		navigate("/register/")
	}

	const toResetPasswordPage = () => {
		navigate("/send-reset-password-email/")
	}

	useEffect(() => {
		document.title = title;
	}, [title]);

	const onLogin = (e) => {
		e.preventDefault()
		setError(false)

		if (loginForm.email === "") {
			setError({ email: "Email cannot be blank!" })
			return;
		}
		if (loginForm.password === "") {
			setError({ password: "Password cannot be blank!" })
			return;
		}
		
		client.login(loginForm.email, loginForm.password)
			.then(() => {
				navigate("/dea")
			})
			.catch((err) => {
				console.log(err)
				setError({ email: err.response.data.message, password: err.response.data.message })
				return;
			});
	}

	const handleLogout = () => {
		client.logout();
		navigate('/')
	}

	return (
		<Container fluid className="App bg-light-pf23 rounded-bottom min-vh-100">
			<Navbar goBackButton={true}></Navbar>
			<CSSTransition
				in={showAlert}
				timeout={300}
				classNames="alert"
				unmountOnExit
			>
			<Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
				{alertMessage}
			</Alert>
			</CSSTransition>
			{(userData === undefined || Object.keys(userData).length === 0) &&
				<Row className="App-header">
					<h1>Log in to your account</h1>
				</Row>
			}
			{userData !== undefined && Object.keys(userData).length > 0 &&
				<Row className="App-header">
					<h1>You are logged in</h1>
					<div>
						<Button variant='pf23 my-3 w-25 mx-auto' onClick={handleLogout}>Logout</Button>
					</div>
				</Row>
			}
			{(userData === undefined || Object.keys(userData).length === 0) &&
				<LoginForm client={client} />
			}
		</Container>
	);
}

export default LoginPage;