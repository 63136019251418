import React, { useState, useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { ReactComponent as DeleteIcon } from '../../assets/icon09_delete.svg';
import { ReactComponent as ToggleIconOFF } from '../../assets/icon12_toogle_off.svg';
import { ReactComponent as ToggleIconON } from '../../assets/icon12_toogle_on.svg';
import PaginationComponent from '../Pagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

// props.periodicTasks,
// props.handleEnable, props.handleDelete

const SchedulesTable = (props) => {
	const [periodicTasksSorted, setPeriodicTasksSorted] = useState([]);
	const [periodicTasksDisplayed, setPeriodicTasksDisplayed] = useState([]);

	const [activePage, setActivePage] = useState(1);
	const [maxDisplay, setMaxDisplay] = useState(4);

	const [sortColumn, setSortColumn] = useState("crontab_string");
	const [sortDirection, setSortDirection] = useState("desc");

	function handleSort(column) {
		if (sortColumn === column) {
			// If the same column is clicked, reverse the sort direction
			setSortDirection((prevSortDirection) =>
				prevSortDirection === "asc" ? "desc" : "asc"
			);
		} else {
			// If a different column is clicked, set it as the new sort column
			setSortColumn(column);
			setSortDirection("asc");
		}
	}

	// Sort periodic tasks
	function sortPeriodicTasks() {
		const sorted = props.periodicTasks.sort((a, b) => {
			if (sortColumn === "crontab_string") {
				return sortDirection === "asc" ? a.crontab_string.localeCompare(b.crontab_string) : b.crontab_string.localeCompare(a.crontab_string);
			} else if (sortColumn === "date_changed") {
				return sortDirection === "asc" ? a.date_changed.localeCompare(b.date_changed) : b.date_changed.localeCompare(a.date_changed);;
			} else if (sortColumn === "total_run_count") {
				return sortDirection === "asc" ? a.total_run_count - b.total_run_count : b.total_run_count - a.total_run_count;
			} else if (sortColumn === "last_run_at") {
				return sortDirection === "asc" ? a.last_run_at.localeCompare(b.last_run_at) : b.last_run_at.localeCompare(a.last_run_at);
			} else if (sortColumn === "enabled") {
				return sortDirection === "asc" ? a.enabled.toString().localeCompare(b.enabled.toString()) : b.enabled.toString().localeCompare(a.enabled.toString());
			}
			return 0;
		});
		setPeriodicTasksSorted(sorted);
	};

	useEffect(() => {
		sortPeriodicTasks();
	}, [props.periodicTasks, sortColumn, sortDirection]);


	return (
		<><Table striped bordered hover responsive className='align-middle text-center'>
			<thead>
				<tr>
					<th onClick={() => handleSort("crontab_string")} className='sort-arrow'>
						Crontab
						<span>
							{sortColumn === "crontab_string" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "crontab_string" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "crontab_string" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("total_run_count")} className='sort-arrow'>
						Total runs
						<span className="sort-arrow">
							{sortColumn === "total_run_count" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "total_run_count" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "total_run_count" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("last_run_at")} className='sort-arrow'>
						Last ran at
						<span className="sort-arrow">
							{sortColumn === "last_run_at" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "last_run_at" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "last_run_at" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("date_changed")} className='sort-arrow'>
						Last changed
						<span className="sort-arrow">
							{sortColumn === "date_changed" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "date_changed" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "date_changed" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("enabled")} className='sort-arrow'>
						Active
						<span className="sort-arrow">
							{sortColumn === "enabled" && sortDirection === "asc" && <FaSortUp />}
							{sortColumn === "enabled" && sortDirection === "desc" && <FaSortDown />}
							{sortColumn !== "enabled" && <FaSort />}
						</span>
					</th>
					{/* <th>On/Off</th> */}
					<th>Delete</th>
				</tr>
			</thead>
			<tbody>
				{/* {props.isLoading && <tr><td colSpan="7" className="text-center">Loading...</td></tr>} */}
				{periodicTasksDisplayed.length === 0 && <tr><td colSpan="7" className="text-center">No schedules found</td></tr>}
				{periodicTasksDisplayed.length !== 0 && periodicTasksDisplayed.map((task, index) => {
					return (
						<tr key={index}>
							<td>{task.crontab_string}</td>
							<td>{task.total_run_count}</td>
							<td>{task.last_run_at}</td>
							<td>{task.date_changed}</td>
							<td style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<Col>
									{task.enabled && "Active"}
									{!task.enabled && "Off"}
								</Col>
								<Col>
									<Button 
										type="button" 
										variant="none"
											onClick={(e) => props.handleEnable(task)}
										>
											{!task.enabled && <ToggleIconOFF className='table-icon'></ToggleIconOFF>}
											{task.enabled && <ToggleIconON className='table-icon'></ToggleIconON>}
									</Button>
								</Col>

							</td>
							<td>
								<Button 
								type="button" 
								variant="none"
								onClick={(e) => props.handleDelete(task)}
								>
									{<DeleteIcon className='table-icon'/>}
								</Button>
							</td>
						</tr>
					)}
				)}
			</tbody>
		</Table>
		<Col>
			<PaginationComponent
				allItems={periodicTasksSorted}
				setItemsToDisplay={setPeriodicTasksDisplayed}
				maxDisplay={maxDisplay}
				activePage={activePage}
				setActivePage={setActivePage}
			/>
		</Col></>
	)
}

export default SchedulesTable;
