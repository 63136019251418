import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ReactComponent as ScheduleIcon } from '../../assets/icon05_details.svg';
import { ReactComponent as TaskIcon } from '../../assets/icon06_tasks.svg';
import { ReactComponent as RunIconOff } from '../../assets/icon07_run_off.svg';
import { ReactComponent as EditIcon } from '../../assets/icon08_edit.svg';
import { ReactComponent as DeleteIconOff } from '../../assets/icon09_delete_off.svg';
import RunIconWithWarning from '../RunIconWithWarning';
import DeleteIconWithWarning from '../DeleteIconWithWarning';
import PaginationComponent from '../ApiPagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

//NEW:
// props.client -> DjangoAPIClient
// props.project -> project object
// props.setSpiderContext -> useState for spider context
// props.setShowSchedulesModal -> useState for showing schedules modal
// props.maxPagination -> max number of items per page

const SpidersTable = (props) => {
	const navigate = useNavigate();

	const [initialRender, setInitialRender] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [dataTable, setDataTable] = useState([]);
	const [dataSize, setDataSize] = useState(0);
	const [requestData, setRequestData] = useState(true);

	const [activePage, setActivePage] = useState(1);
	const [sortColumn, setSortColumn] = useState("creation_date");
	const [sortDirection, setSortDirection] = useState("-");
	const [filters, setFilters] = useState({
		name: '',
		creation_date: '',
		last_run: '',
		last_status: [],
		project: props.project.id
	});

	const statusOptions = [
		{ value: 'SENT', label: 'SENT' },
		{ value: 'SUBMITTED', label: 'SUBMITTED' },
		{ value: 'SUCCESS', label: 'SUCCESS' },
		{ value: 'FAILURE', label: 'FAILURE' },
		{ value: 'ABORTED', label: 'ABORTED' },
		{ value: 'STARTED', label: 'STARTED' },
		{ value: '-', label: '-' },
	];

	useEffect(() => {
		if(props.project.id !== 0){
			setIsLoading(true);
			setActivePage(1);
			setRequestData(true);
			setFilters({...filters, project: props.project.id});
		}
	}, [props.project]);

	useEffect(() => {
		if(props.project.id !== 0 && requestData){
			fetchDataTable(setIsLoading, setDataTable, setDataSize);
		}
	}, [activePage, sortColumn, sortDirection, filters, requestData, props.refreshData]);

	function fetchDataTable(setIsLoading, setDataTable, setDataSize) {
		setIsLoading(true);
		props.client.getMySpiders(activePage, sortColumn, sortDirection, filters).then(
			(response) => {
				setDataSize(response.data.count);
				setDataTable(response.data.results);
			}
		)
	}

	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
		} else {
			setIsLoading(false);
		}
	}, [dataTable]);

	function handleSort(column) {
		setActivePage(1);
		if (sortColumn === column) {
			// If the same column is clicked, reverse the sort direction
			setSortDirection((prevSortDirection) =>
				prevSortDirection === "+" ? "-" : "+"
			);
		} else {
			// If a different column is clicked, set it as the new sort column
			setSortColumn(column);
			setSortDirection("+");
		}
	}

	function handleStatusCheckboxChange(status, checked) {
		if (checked) {
			// Add the status to the filters.last_status array
			setFilters({...filters, last_status: filters.last_status + status});
		} else {
			// Remove the status from the filters.last_status array
			setFilters({...filters, last_status: filters.last_status.filter((s) => s !== status)});
		}
	}

	const handleItemClick = (value) => {
		console.log("click")
		const isChecked = filters.last_status.includes(value);
		if (isChecked) {
			setFilters({...filters, last_status: filters.last_status.filter((status) => status !== value)});
		} else {
			setFilters({...filters, last_status: [...filters.last_status, value]});
		}
	};

	const getSelectedStatusText = () => {
		if (filters.last_status.length === 0) {
			return 'Filter by status';
		} else if (filters.last_status.length === 1) {
			const selectedOption = statusOptions.find(
				(option) => option.value === filters.last_status[0]
			);
			return selectedOption.label;
		} else {
			return `${filters.last_status.length} selected`;
		}
	};

	const handleKeyDown = (event, setRequestData) => {
		if (event.key === 'Enter') {
			setRequestData(true);
		}
	}

	function handleSpiderRun(spiderId, setIsLoading, setDataTable, setDataSize) {
		props.client.runOneOffPeriodicTask(spiderId)
			.then(
				(response) => {
					fetchDataTable(setIsLoading, setDataTable, setDataSize);
				}
			)
			.catch((error) => {
				console.log(error.response.statusText)
			}
			);
	}
	function handleTestSpiderRun(spiderId, setIsLoading, setDataTable, setDataSize) {
		props.client.runTestOneOffPeriodicTask(spiderId)
			.then(
				(response) => {
					fetchDataTable(setIsLoading, setDataTable, setDataSize);
				}
			)
			.catch((error) => {
				console.log(error.response.statusText)
			}
			);
	}
	function handleSchedules(spider, setSpider, setShowSchedulesModal) {
		setSpider(spider);
		setShowSchedulesModal(true);
	}

	function handleTasks(spider, setSpider) {
		setSpider(spider);
		navigate("/my-tasks");
	}

	function handleEdit(spider) {
		navigate(`/spiders/${spider.id}`)
	}

	function handleDelete(spider, setIsLoading, setDataTable, setDataSize) {
		props.client.deleteSpider(spider.id)
			.then(
				(response) => {
					fetchDataTable(setIsLoading, setDataTable, setDataSize);
				}
			)
			.catch((error) => console.log(error));
	}

	return (
		<>
			<Table striped responsive bordered hover className='align-middle custom-table'>
				<thead>
					<tr>
						<th onClick={() => handleSort("name")} className='sort-arrow'>
							Spider Name
							<span>
								{sortColumn === "name" && sortDirection === "+" && <FaSortUp />}
								{sortColumn === "name" && sortDirection === "-" && <FaSortDown />}
								{sortColumn !== "name" && <FaSort />}
							</span>
						</th>
						<th onClick={() => handleSort("creation_date")} className='sort-arrow'>
							Creation date
							<span className="sort-arrow">
								{sortColumn === "creation_date" && sortDirection === "+" && <FaSortUp />}
								{sortColumn === "creation_date" && sortDirection === "-" && <FaSortDown />}
								{sortColumn !== "creation_date" && <FaSort />}
							</span>
						</th>
						<th onClick={() => handleSort("last_run")} className='sort-arrow'>
							Last ran at
							<span className="sort-arrow">
								{sortColumn === "last_run" && sortDirection === "+" && <FaSortUp />}
								{sortColumn === "last_run" && sortDirection === "-" && <FaSortDown />}
								{sortColumn !== "last_run" && <FaSort />}
							</span>
						</th>
						<th onClick={() => handleSort("last_status")} className='sort-arrow'>
							Last status
							<span className="sort-arrow">
								{sortColumn === "last_status" && sortDirection === "+" && <FaSortUp />}
								{sortColumn === "last_status" && sortDirection === "-" && <FaSortDown />}
								{sortColumn !== "last_status" && <FaSort />}
							</span>
						</th>
						<th>Summary</th>
						<th>Schedules</th>
						<th>Task results</th>
						<th>Run</th>
						<th>Edit</th>
						<th>Delete</th>
						<th>Test</th>
					</tr>
					<tr>
						<th>
							<Form.Control
								type="text"
								id="filter-name"
								placeholder={"Filter by name"}
								value={filters.name}
								onBlur={(e) => {setActivePage(1); setRequestData(true)}}
								onChange={(e) => {setRequestData(false); setFilters({...filters, name: e.target.value})}}
								onKeyDown={(e) => handleKeyDown(e, setRequestData)}
							/>
						</th>
						<th>
							<Form.Control
								type="text"
								id="filter-group"
								placeholder="Filter by date and time"
								value={filters.creation_date}
								onBlur={(e) => {setActivePage(1); setRequestData(true)}}
								onChange={(e) => {setRequestData(false); setFilters({...filters, creation_date: e.target.value})}}
								onKeyDown={(e) => handleKeyDown(e, setRequestData)}
							/>
						</th>
						<th>
							<Form.Control
								type="text"
								id="filter-group"
								placeholder="Filter by date and time"
								value={filters.last_run}
								onBlur={(e) => {setActivePage(1); setRequestData(true)}}
								onChange={(e) => {setRequestData(false); setFilters({...filters, last_run: e.target.value})}}
								onKeyDown={(e) => handleKeyDown(e, setRequestData)}
							/>
						</th>
						<th>
							<Dropdown>
								<Dropdown.Toggle variant="filter" id="filter-dropdown">
									{getSelectedStatusText()}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{statusOptions.map((option) => (
										<Dropdown.Item
											key={option.value}
											onClick={() => {handleItemClick(option.value)}}
											className='py-0'
										>
											<Form.Check style={{ cursor: "pointer" }}>
												<Form.Check.Input
													type="checkbox"
													style={{ cursor: "pointer" }}
													id={`status-${option.value}`}
													checked={filters.last_status.includes(option.value)}
													onChange={(e) =>
														handleStatusCheckboxChange(option.value, e.target.checked)
													}
												/>
												<Form.Check.Label htmlFor={`status-${option.value}`} style={{ cursor: "pointer" }}>
													{option.label}
												</Form.Check.Label>
											</Form.Check>
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{isLoading && <tr><td colSpan="11" className="text-center">Loading...</td></tr>}
					{!isLoading && dataSize === 0 && <tr><td colSpan="11" className="text-center">No spiders found</td></tr>}
					{!isLoading && dataTable.map((spider, index) => {
						return (
							<tr key={index}>
								<td>{spider.name}</td>
								<td>
									<OverlayTrigger
										placement="top"
										delay={{ hide: 250 }}
										overlay={
											<Tooltip>
												Created by {spider.user_email.split('@')[0]}
											</Tooltip>
										}
									>
										<a>{spider.creation_date}</a>
									</OverlayTrigger>
								</td>
								<td>{spider.last_run}</td>
								<td>{spider.last_status}</td>
								<td className='text-white'>
									<span className="bg-success count" data-bs-toggle="tooltip" data-bs-placement="top" title="Number of successfully completed tasks">
										{spider.success_count}
									</span>
									<span className="bg-warning count" data-bs-toggle="tooltip" data-bs-placement="top" title="Number of task with status cancelled">
										{spider.cancel_count}
									</span>
									<span className="bg-danger count" data-bs-toggle="tooltip" data-bs-placement="top" title="Number of task with status failure">
										{spider.failure_count}
									</span>
								</td>
								<td>
									<Button
										size="md"
										variant="none"
										type="button"
										onClick={(e) => {
											handleSchedules(spider, props.setSpider, props.setShowSchedulesModal)
										}}
									>
										{<ScheduleIcon className='table-icon' />}
									</Button>
								</td>
								<td>
									<Button
										size="md"
										variant="none"
										onClick={(e) => {
											handleTasks(spider, props.setSpider)
										}}
									>
										{<TaskIcon className='table-icon' />}
									</Button>
								</td>
								<td>
									{(spider.last_status != "SENT" && spider.last_status != "STARTED" && spider.last_status != "SUBMITTED") &&
										<RunIconWithWarning
											handleRun={(e) => handleSpiderRun(spider.id, setIsLoading, setDataTable, setDataSize)}
											warningBodyText={
												<a>You are about to start the <b>{spider.name}</b> now. Do you want to continue?</a>
											}
										/>
									}
									{(spider.last_status === "SENT" || spider.last_status === "STARTED" || spider.last_status === "SUBMITTED") && <RunIconOff className='table-icon' />}
								</td>
								<td>
									<Button
										size="md"
										variant='none'
										type="button"
										className='rounded-pill'
										onClick={(e) => handleEdit(spider)}
									>
										{<EditIcon className='table-icon' />}
									</Button>
								</td>
								<td>
									{props.userId === spider.user &&
										<DeleteIconWithWarning
											handleDelete={(e) => handleDelete(spider, setIsLoading, setDataTable, setDataSize)}
											warningBodyText={
												<a>You are about to delete the <b>{spider.name}</b> spider and all of its schedules. Do you want to continue?</a>
											}
										/>}
									{props.userId !== spider.user &&
										<OverlayTrigger
											placement="left"
											delay={{ hide: 250 }}
											overlay={
												<Tooltip>
													You don't own this spider
												</Tooltip>
											}
										>
											<DeleteIconOff className='table-icon' />
										</OverlayTrigger>
									}
								</td>
								<td>
									{(spider.last_status != "SENT" && spider.last_status != "STARTED" && spider.last_status != "SUBMITTED") &&
										<RunIconWithWarning
											handleRun={(e) => handleTestSpiderRun(spider.id, setIsLoading, setDataTable, setDataSize)}
											warningBodyText={
												<a>You are about to start test spider named <b>{spider.name}</b> now. Do you want to continue?</a>
											}
										/>
									}
									{(spider.last_status === "SENT" || spider.last_status === "STARTED" || spider.last_status === "SUBMITTED") && <RunIconOff className='table-icon' />}
								</td>
							</tr>
						)
					}
					)}
				</tbody>
			</Table>
			<Col>
				<PaginationComponent
					pageLimit={props.maxPagination}
					countAllResults={dataSize}
					activePage={activePage}
					setActivePage={setActivePage}
				/>
			</Col></>
	)
}

export default SpidersTable;
