import React, { useState, useEffect } from 'react';
import config from '../config';
import DjangoAPIClient from '../client';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Table } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from '../components/Navbar';
import EretailersTable from '../components/tables/EretailersTable';

const client = new DjangoAPIClient(config);


const EretailersExternal = ({ title }) => { 
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);

    // Fetching yaml from the API
    function fetchYamlData() {
      setIsLoading(true);
      client.getYamlData()
        .then((response) => {
          setData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching YAML data:', error);
          setIsLoading(false);
          setData([]); // Reset data to an empty array in case of error
        });
    }
    

	useEffect(() => {
		fetchYamlData();
	}, []);

    return (
		<Container fluid className='App bg-light-pf23 rounded-bottom min-vh-100'>
			<Navbar goBackButton={true}></Navbar>
        {/* <h1 className='py-3'>E-retailers</h1> */}
				<Row className='px-3 py-1 bg-white d-flex align-items-center'>
					<Col sm={12} md={6} className='header'>
						<header className="bg-white pt-2">
							<h1 className='text-start'>Eretailers</h1>
						</header>
					</Col>
				</Row>
        <EretailersTable 
          eretailers={data}
          isLoading={isLoading}
          maxPagination={10}
        />
      </Container>
    )

}

export default EretailersExternal;
