import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';


const ItemsDropdown = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredItems, setFilteredItems] = useState(props.items);
    const searchInputRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
  
    useEffect(() => {
      setFilteredItems(props.items.filter(item => item[props.attr].toLowerCase().includes(searchQuery.toLowerCase())));
      setHighlightedIndex(0);
    }, [props.items, props.attr, searchQuery]);
  
    const handleDropdownToggle = (isOpen, event, metadata) => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown') {
        setHighlightedIndex((prev) => (prev + 1) % filteredItems.length);
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex((prev) => (prev === 0 ? filteredItems.length - 1 : prev - 1));
      } else if ((e.key === 'Enter') && highlightedIndex >= 0) {
        const item = filteredItems[highlightedIndex];
        if (props.filepath) {
          props.setSelected({ id: parseInt(item.id), name: item[props.attr], filepath: item.filepath });
        } else {
          props.setSelected({ id: parseInt(item.id), name: item[props.attr] });
        }
        setHighlightedIndex(-1);
      } else if (e.key === ' ' && e.target.tagName === 'INPUT') {
        e.stopPropagation();
      }
    };
  
    return (
      <Dropdown id="project-dropdown" onToggle={handleDropdownToggle}>
        <Dropdown.Toggle 
          variant='dark'
          className="d-flex align-items-center justify-content-between rounded-pill"
        >
          {props.isLoading & (!props.selected[props.attr] || props.selected[props.attr] == "Select" ) ? "Loading..." : props.selected[props.attr]}

        </Dropdown.Toggle>
  
        <Dropdown.Menu
          variant="dark"
          className='w-100 overflow-auto'
          style={{maxHeight: '300px'}}
        >
          <Dropdown.Item>
            <div className="input-group">
              <input
                type="text"
                placeholder="Search by name"
                className="form-control"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                ref={searchInputRef}
                onKeyDown={handleKeyDown}
              />
            </div>
          </Dropdown.Item>
          {filteredItems.map((item, index) => (
            <Dropdown.Item
              key={index}
              value={item.id}
              onClick={(e) => {
                if (props.filepath) {
                  props.setSelected({id: parseInt(e.target.attributes.value.value), [props.attr]: item[props.attr], filepath: item.filepath});
                } else {
                  props.setSelected({id: parseInt(e.target.attributes.value.value), [props.attr]: item[props.attr]});
                }
                // props.setSelected({id: parseInt(e.target.attributes.value.value), [props.attr]: item[props.attr]})
              }}
              className={index === highlightedIndex ? 'highlighted' : ''}
            >
              {item[props.attr]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  
  export default ItemsDropdown;