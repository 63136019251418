import React from "react";
import { useState, useEffect } from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ItemsDropdown from "../../components/ItemsDropdown";

import Delayed from "../../components/DelayedComponent";

// props.client -> DjangoAPIClient
// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.report -> project object

const ManageReportModal = (props) => {
	const [error, setError] = useState("")
	// const [reportDetails, setReportDetails] = useState({})
	
	const [newReportName, setNewReportName] = useState("");
	const [groups, setGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState({ id: 1, name: "Please select a group" })

	useEffect(() => {
		props.client.getUserGroups()
			.then(response => {
				setGroups(response.data.filter(group => group.name !== "Public"));
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (props.report.reportId !== 0){
			// setReportDetails(props.report);
			setNewReportName(props.report.report_name);
			setSelectedGroup({ id: props.report.group, name: props.report.group_name })
			// props.client.getProjectDetails(props.report.reportId)
			// 	.then(response => {
			// 		setprojectDetails(response.data);
			// 		setNewReportName(response.data.name);
			// 		setSelectedGroup({ id: response.data.group, name: response.data.group_name })
			// 	})
			// 	.catch(error => {
			// 		console.log(error);
			// 	});
			}
	}, [props.report]);

	function closeModal(setShowModal, setNewReportName, setSelectedGroup) {
		setNewReportName(props.report.report_name);
		setSelectedGroup({ id: props.report.group, name: props.report.group_name })
		setShowModal(false);
	}

	function handleReportUpdate(reportId, newReportName, groupId, setShowModal, refreshData, setRefreshData) {
		props.client.editPowerBIReport(reportId, newReportName, groupId)
			.then(response => {
					setShowModal(false)
					setRefreshData(refreshData+1);
				}
			)
			.catch((error) => {
				setError(error.response.data);
			});
	}

	return (
		<Modal size="lg" show={props.showModal} onHide={(e) => closeModal(props.setShowModal, setNewReportName, setSelectedGroup)}>
			<Modal.Header closeButton>
				<Modal.Title>Edit your project</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Delayed waitBeforeShow="800">
					<Form>
						<Form.Group controlId="projectName">
							<Row className="pb-2">
								<Col sm={12} md={3}>
									<Form.Label>Project Name:</Form.Label>
								</Col>
								<Col sm={12} md={6}>
									<Form>
										<Form.Group>
											<Form.Control
												type="text"
												className="rounded-pill"
												placeholder={newReportName}
												value={newReportName}
												onChange={(e) => setNewReportName(e.target.value)}
											/>
										</Form.Group>
									</Form>
								</Col>
							</Row>
						</Form.Group>
						<Form.Group controlId="groupName">
							<Row>
								<Col sm={12} md={3}>
									<Form.Label>Group Name:</Form.Label>
								</Col>
								<Col sm={12} md={6}>
									<Form.Check>
										<ItemsDropdown
											items={groups}
											selected={selectedGroup}
											setSelected={setSelectedGroup}
											attr={"name"}
										/>
									</Form.Check>
								</Col>
							</Row>
						</Form.Group>
					</Form>
				</Delayed>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="modal"
					className="rounded-pill"
					onClick={(e) => handleReportUpdate(props.report.report_id, newReportName, selectedGroup.id, props.setShowModal, props.refreshData, props.setRefreshData)}
				>
					Save
				</Button>
				<Button
					variant="dark"
					className="rounded-pill"
					onClick={(e) => closeModal(props.setShowModal, setNewReportName, setSelectedGroup)}
				>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ManageReportModal;