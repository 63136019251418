import React, { useState, useEffect } from "react";

import { marked } from "marked";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Sidebar from "../components/Sidebar";
import Navbar from '../components/Navbar';
import HomepageFooter from '../components/HomepageFooter'

import DjangoAPIClient from '../client';
import config from '../config';

const client = new DjangoAPIClient(config);


const FAQ = ({ title, userData }) => {
	const [content, setContent] = useState("");

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		client.getQuestionsandAnswers()
			.then(response => {
				setContent(marked(response.data.markdown_content));
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	return (
		<>
			<Sidebar userData={userData} />
			<Container fluid className='App with-sidebar bg-light rounded-bottom d-flex flex-column justify-content-between min-vh-100'>
				<Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
				<Row className="App-header bg-white">
					<h1>ScrapfinderLAB - FAQ</h1>
				</Row>
				<Row className="text-start flex-grow-1 faq">
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</Row>
				<HomepageFooter />
			</Container>
		</>
	);
};

export default FAQ;
