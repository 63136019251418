import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ReactComponent as ScheduleIcon } from '../../assets/icon05_details.svg';
import { ReactComponent as TaskIcon } from '../../assets/icon06_tasks.svg';
import { ReactComponent as RunIconOff } from '../../assets/icon07_run_off.svg';
import { ReactComponent as EditIcon } from '../../assets/icon08_edit.svg';
import { ReactComponent as CancelIconOff } from '../../assets/icon14_cancel.svg';
import { ReactComponent as DeleteIconOff } from '../../assets/icon09_delete_off.svg';
import RunIconWithWarning from '../RunIconWithWarning';
import DeleteIconWithWarning from '../DeleteIconWithWarning';
import CancelIconWithWarning from '../CancelIconWithWarning';
import PaginationComponent from '../ApiPagination';
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";


// props.taskResults, 
// props.handleDetails, props.handleTaskStop,
// props.maxPagination, props.isLoading

//NEW:
// props.client -> DjangoAPIClient
// props.spider -> spider object
// props.setTask -> setTask function
// props.setShowAlert -> setShowAlert function
// props.setAlertMessage -> setAlertMessage function
// props.setShowDetailedViewModal -> setShowDetailedViewModal function
// props.maxPagination -> max number of items per page


const TaskResultsTable = (props) => {
	const [initialRender, setInitialRender] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [dataTable, setDataTable] = useState([]);
	const [dataSize, setDataSize] = useState(0);
	const [requestData, setRequestData] = useState(true);

	const [activePage, setActivePage] = useState(1);
	const [sortColumn, setSortColumn] = useState("creation_date");
	const [sortDirection, setSortDirection] = useState("-");
	const [filters, setFilters] = useState({
		task_id: '',
		spider_id: props.spider.id,
		created_at: '',
		done_at: '',
		status: [],
	})
	const statusOptions = [
		{ value: 'SENT', label: 'SENT' },
		{ value: 'SUBMITTED', label: 'SUBMITTED'},
		{ value: 'SUCCESS', label: 'SUCCESS' },
		{ value: 'FAILURE', label: 'FAILURE' },
		{ value: 'ABORTED', label: 'ABORTED' },
		{ value: 'STARTED', label: 'STARTED' },
		{ value: '-', label: '-' },
	];

	const [copied, setCopied] = useState(false);

	useEffect(() => {
		if(props.spider.id !== 0){
			setIsLoading(true);
			setActivePage(1);
			setRequestData(true);
			setFilters({...filters, spider_id: props.spider.id});
		}
	}, [props.spider]);

	useEffect(() => {
		if(props.spider.id !== 0 && requestData){
			fetchDataTable(setIsLoading, setDataTable, setDataSize);
		}
	}, [activePage, sortColumn, sortDirection, filters, requestData, props.refreshData]);

	function fetchDataTable(setIsLoading, setDataTable, setDataSize) {
		setIsLoading(true);
		props.client.getTaskResults(activePage, sortColumn, sortDirection, filters)
			.then(
				(response) => {
					setDataTable(response.data.results);
					setDataSize(response.data.count);
				}
			)
	}

	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
		} else {
			setIsLoading(false);
		}
	}, [dataTable]);

	function handleSort(column) {
		setActivePage(1);
		if (sortColumn === column) {
			// If the same column is clicked, reverse the sort direction
			setSortDirection((prevSortDirection) =>
				prevSortDirection === "+" ? "-" : "+"
			);
		} else {
			// If a different column is clicked, set it as the new sort column
			setSortColumn(column);
			setSortDirection("+");
		}
	}

	function handleStatusCheckboxChange(status, checked) {
		if (checked) {
			// Add the status to the filters.status array
			setFilters({...filters, status: filters.status + status});
		} else {
			// Remove the status from the filters.status array
			setFilters({...filters, status: filters.status.filter((s) => s !== status)});
		}
	}

	const handleItemClick = (value) => {
		console.log("click")
		const isChecked = filters.status.includes(value);
		if (isChecked) {
			setFilters({...filters, status: filters.status.filter((status) => status !== value)});
		} else {
			setFilters({...filters, status: [...filters.status, value]});
		}
	};

	const getSelectedStatusText = () => {
		if (filters.status.length === 0) {
			return 'Filter by status';
		} else if (filters.status.length === 1) {
			const selectedOption = statusOptions.find(
				(option) => option.value === filters.status[0]
			);
			return selectedOption.label;
		} else {
			return `${filters.status.length} selected`;
		}
	};

	const handleKeyDown = (event, setRequestData) => {
		if (event.key === 'Enter') {
			setRequestData(true);
		}
	}

    function stopTaskResult(taskResult, setShowAlert, setAlertMessage, setIsLoading, setDataTable, setDataSize) {
        setIsLoading(true);
		props.client.stopTaskResult(taskResult.task_id)
            .then(
                (response) => {
                    setShowAlert(true);
                    setAlertMessage(response.data);
                    setTimeout(() => {
                        setShowAlert(false);
                    }, 4000);
                    fetchDataTable(setIsLoading, setDataTable, setDataSize)
                }
            )
    }

	function deleteTaskResult(taskResult, setIsLoading, setDataTable, setDataSize) {
		setIsLoading(true);
        props.client.deleteTaskResult(taskResult.task_id)
            .then(
                (response) => {
                    fetchDataTable(setIsLoading, setDataTable, setDataSize)
                }
            )
    }

    function handleDetails(taskResult, setTask, setShowDetailedViewModal) {
        setTask(taskResult);
        setShowDetailedViewModal(true);
    }

	return (
		<><Table striped responsive bordered hover className='align-middle custom-table'>
			<thead>
				<tr>
					<th onClick={() => handleSort("task_id")} className='sort-arrow'>
						Task ID
						<span>
							{sortColumn === "task_id" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "task_id" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "task_id" && <FaSort />}
						</span>
					</th>
					{/* <th onClick={() => handleSort("project_name")} className='sort-arrow'>
						Project
						<span className="sort-arrow">
							{sortColumn === "project_name" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "project_name" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "project_name" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("spider_name")} className='sort-arrow'>
						Spider
						<span>
							{sortColumn === "spider_name" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "spider_name" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "spider_name" && <FaSort />}
						</span>
					</th> */}
					<th onClick={() => handleSort("created_at")} className='sort-arrow'>
						Date started
						<span className="sort-arrow">
							{sortColumn === "created_at" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "created_at" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "created_at" && <FaSort />}
						</span>
					</th>
					<th onClick={() => handleSort("done_at")} className='sort-arrow'>
						Date finished
						<span className="sort-arrow">
							{sortColumn === "done_at" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "done_at" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "done_at" && <FaSort />}
						</span>
					</th>					
					<th onClick={() => handleSort("status")} className='sort-arrow'>
						Status
						<span className="sort-arrow">
							{sortColumn === "status" && sortDirection === "+" && <FaSortUp />}
							{sortColumn === "status" && sortDirection === "-" && <FaSortDown />}
							{sortColumn !== "status" && <FaSort />}
						</span>
					</th>
					<th>Cancel</th>
					<th>Delete</th>
					<th>Details</th>
				</tr>
				<tr>
					<th>
						<Form.Control
							type="text"
							id="filter-id"
							placeholder="Filter by task ID"
							value={filters.task_id}
							onBlur={(e) => {setActivePage(1); setRequestData(true)}}
							onChange={(e) => {setRequestData(false); setFilters({...filters, task_id: e.target.value})}}
							onKeyDown={(e) => handleKeyDown(e, setRequestData)}
						/>
					</th>
					{/* <th>
						<Form.Control
							type="text"
							id="filter-project"
							placeholder="Filter by project"
							value={filterProject}
							onChange={(e) => setFilterProject(e.target.value)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-spider"
							placeholder="Filter by spider"
							value={filterSpider}
							onChange={(e) => setFilterSpider(e.target.value)}
						/>
					</th> */}
					<th>
						<Form.Control
							type="text"
							id="filter-created"
							placeholder="Filter by date and time"
							value={filters.created_at}
							onBlur={(e) => {setActivePage(1); setRequestData(true)}}
							onChange={(e) => {setRequestData(false); setFilters({...filters, created_at: e.target.value})}}
							onKeyDown={(e) => handleKeyDown(e, setRequestData)}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							id="filter-done"
							placeholder="Filter by date and time"
							value={filters.done_at}
							onBlur={(e) => {setActivePage(1); setRequestData(true)}}
							onChange={(e) => {setRequestData(false); setFilters({...filters, done_at: e.target.value})}}
							onKeyDown={(e) => handleKeyDown(e, setRequestData)}
						/>
					</th>
					<th>
						<Dropdown>
							<Dropdown.Toggle variant="filter" id="filter-dropdown">
								{getSelectedStatusText()}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{statusOptions.map((option) => (
									<Dropdown.Item
										key={option.value}
										onClick={() => {handleItemClick(option.value)}}
										className='py-0'
									>
										<Form.Check style={{ cursor: "pointer" }}>
											<Form.Check.Input
												type="checkbox"
												style={{ cursor: "pointer" }}
												id={`status-${option.value}`}
												checked={filters.status.includes(option.value)}
												onChange={(e) =>
													handleStatusCheckboxChange(option.value, e.target.checked)
												}
											/>
											<Form.Check.Label htmlFor={`status-${option.value}`} style={{ cursor: "pointer" }}>
												{option.label}
											</Form.Check.Label>
										</Form.Check>
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</th>
					<th></th>
					<th></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{props.noSpiders && <tr><td colSpan="11" className="text-center">No tasks found for given project.</td></tr>}
				{isLoading && !props.noSpiders && <tr><td colSpan="11" className="text-center">Loading...</td></tr>}
				{!isLoading && !props.noSpiders && dataSize === 0 && <tr><td colSpan="11" className="text-center">No tasks found or tasks have been archived.</td></tr>}
				{!isLoading && !props.noSpiders && dataTable.map((task, index) => {
					return (
						<tr key={index}>
							<OverlayTrigger
									overlay={<Tooltip>{copied ? 'Copied!' : 'Click to copy'}</Tooltip>}
									placement="right"
									delayShow={0}
									delayHide={150}
							>
								<td 
									style={{ cursor: 'default' }}
									onClick={() => {
										navigator.clipboard.writeText(task.task_id);
										setCopied(true);
										setTimeout(() => setCopied(false), 500); // Reset copied state after 0.5 seconds
									}}
								>
									{task.task_id}
								</td>
							</OverlayTrigger>
							{/* <td>{task.project_name}</td>
							<td>{task.spider_name}</td> */}
							<td>{task.created_at}</td>
							<td>{task.done_at}</td>
							<td>
								{/* {task.status === "STARTED" &&
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								}
								{task.status != "STARTED" && task.status} */}
								{task.status}
							</td>
							<td>
								{(task.status === "STARTED" || task.status === 'SUBMITTED' ) &&
									<CancelIconWithWarning
										handleDelete={(e) => {stopTaskResult(task, props.setShowAlert, props.setAlertMessage, setIsLoading, setDataTable, setDataSize)}}
										warningBodyText={
											<a>You are about to cancel task execution. Do you want to continue?</a>
										}
									/>
								}
								{(task.status != "STARTED" && task.status != 'SUBMITTED') && 
									<OverlayTrigger
										placement="left"
										delay={{ hide: 250 }}
										overlay={
											<Tooltip>
												Task is not running
											</Tooltip>
										}
									>
										<CancelIconOff className='table-icon cancel-task'/>
									</OverlayTrigger>
								}
							</td>
							<td>
								{/* {task.status === "SENT" &&
									<OverlayTrigger
										placement="left"
										delay={{ hide: 250 }}
										overlay={
											<Tooltip>
												Wait until task is started
											</Tooltip>
										}
									>
										<DeleteIconOff className='table-icon' />
									</OverlayTrigger>
								} */}
								{/* task.status !== "SENT" && */}
								{
									<DeleteIconWithWarning
										handleDelete={(e) => {deleteTaskResult(task, setIsLoading, setDataTable, setDataSize)}}
										warningBodyText={
											<a>You are about to delete task, all related data from MongoDB & logs. Do you want to continue?</a>
										}
									/>
								}
							</td>
							<td>
								<Button
									variant="none"
									size="md"
									type="button"
									onClick={(e) => {handleDetails(task, props.setTask, props.setShowDetailedViewModal)}}
								>
									{<ScheduleIcon className='table-icon' />}
								</Button>
							</td>
						</tr>
					)}
				)}
			</tbody>
		</Table>
		<Col>
			<PaginationComponent
				pageLimit={props.maxPagination}
				countAllResults={dataSize}
				activePage={activePage}
				setActivePage={setActivePage}
			/>
		</Col></>
	)
}

export default TaskResultsTable;
