import { React, useState, useEffect } from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ItemsDropdown from "../../components/ItemsDropdown";

// props.client -> DjangoAPIClient
// props.showModal, props.setShowModal -> useEffect() with show/hide modal


const CreateReportModal = (props) => {
    const [error, setError] = useState({ report_id: "" })
    const [isLoading, setIsLoading] = useState(false);
    const [newReportId, setNewReportId] = useState('');

    useEffect(() => {
        if (!props.showModal) {
            closeModal(props.setShowModal, setNewReportId);
        }
    }, [props.showModal]);

    function closeModal(setShowModal, setNewReportId) {
        setNewReportId("");
        setShowModal(false);
    }

	function handleReportCreate(client, newReportId, setShowModal, setError) {
        setIsLoading(true);
		client.addPowerBIReport(newReportId)
			.then(response => {
                setShowModal(false);
                setIsLoading(false);
			})
			.catch(error => {
				console.log(error);
				setError(error.response.data);
			});
	}

    return (
        <Modal show={props.showModal} backdrop="static" onHide={(e) => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Report</Modal.Title>
            </Modal.Header>
            {!isLoading && <>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formnewReportId">
                            <Form.Control 
                                className="w-100"
                                type="text"
                                placeholder="Report ID"
                                value={newReportId}
                                isInvalid={!!error.report_id}
                                onChange={(e) => setNewReportId(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {error.report_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="modal"
                        onClick={(e) => 
                            handleReportCreate(props.client, newReportId, props.setShowModal, setError)
                        }
                    >
                        Create
                    </Button>
                    <Button
                        variant="dark"
                        onClick={(e) => props.setShowModal(false)}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </>}
            {isLoading && 
                <Modal.Body>
                    <p className="text-center">Loading...</p>
                </Modal.Body>
            }
        </Modal>
    )
}


export default CreateReportModal;