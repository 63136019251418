import React, { useState, useEffect, useContext } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import moment, { max } from 'moment';

import config from '../config';
import DjangoAPIClient from '../client';
import ProjectContext from '../components/contexts/ProjectContext';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import CreateProjectModal from '../components/modals/CreateProjectModal';
import ProjectCreatedModal from '../components/modals/ProjectCreatedModal';
import Navbar from '../components/Navbar';
import ManageProjectModal from '../components/modals/ManageProjectModal';
import ProjectsTable from '../components/tables/ProjectsTable';
import Sidebar from '../components/Sidebar';

const client = new DjangoAPIClient(config);


const ProjectsPage = ({ userData, title }) => {
	const [projectContext, setProjectContext] = useContext(ProjectContext);
	
	const [refreshData, setRefreshData] = useState(0);

	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showProjectCreatedModal, setShowProjectCreatedModal] = useState(false);
	const [showManageProjectModal, setShowManageProjectModal] = useState(false);

	////////// Page title & mobile detection
	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);

	useEffect(() => {
		if (projectContext.id === 0) {
			client.getDropdownProjects()
				.then(response => {
					setProjectContext({id: response.data[0].id, name: response.data[0].name})
				})
		}
	}, []);

	useEffect(() => {
		if (showProjectCreatedModal === false) {
			setRefreshData(refreshData+1);
		}
	}, [showProjectCreatedModal])

	return (
		<>
			<Sidebar userData={userData}/>
			<Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
				<Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
				<CreateProjectModal
					client={client}
					showModal={showCreateModal}
					setShowModal={setShowCreateModal}
					setShowSuccessModal={setShowProjectCreatedModal}
					setProject={setProjectContext}
				/>
				<ProjectCreatedModal
					showModal={showProjectCreatedModal}
					setShowModal={setShowProjectCreatedModal}
				/>
				<ManageProjectModal
					client={client}
					showModal={showManageProjectModal}
					setShowModal={setShowManageProjectModal}
					refreshData={refreshData}
					setRefreshData={setRefreshData}
					project={projectContext}
				/>

				<section>
					<Row className='px-3 py-1 bg-white d-flex align-items-center'>
						<Col sm={12} md={6}>
							<header className="bg-white pt-2">
								<h1 className='text-start'>My projects</h1>
							</header>
						</Col>
						<Col sm={12} md={6} className='d-flex justify-content-md-end justify-content-sm-start'>
							<Button
								className='rounded-pill'
								variant='pf23'
								onClick={(e) => setShowCreateModal(true)}
							>
								Create Project
							</Button>
						</Col>
					</Row>
					<Row className='p-3 '>
						<ProjectsTable
							client={client}
							userId={userData.id}
							setProject={setProjectContext}
							setShowManageModal={setShowManageProjectModal}
							refreshData={refreshData}
							maxPagination={10}
						/>
					</Row>
				</section>
			</Container>
		</>
	)
}

export default ProjectsPage;
