import React from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.handleConfirm -> confirm function
// props.bodyText -> body text with warning

const WarningModal = (props) => {
    return (
        <Modal backdrop="static" show={props.showModal} onHide={(e) => props.setShowModal(false)}>
            <Modal.Header closeButton>
            <Modal.Title className="text-danger fw-bold">WARNING!!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.bodyText}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="modal" onClick={(e) => {props.handleConfirm(); props.setShowModal(false)}}>
                    Confirm
                </Button>
                <Button variant="dark" onClick={(e) => props.setShowModal(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default WarningModal;