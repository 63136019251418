import React from "react";
import { useEffect , useState, useContext} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import ItemsDropdown from "./ItemsDropdown";
import { ReactComponent as UploadIcon } from '../assets/icon11_upload.svg';
import { FaCloudDownloadAlt } from "react-icons/fa";

import runtimeEnv from '@mars/heroku-js-runtime-env';

const SelectOrUploadFile = (props) => {
    const [addFile, setAddFile] = useState(false);
    const [newFile, setNewFile] = useState("");
    const [errors, setErrors] = useState(null);

    const env = runtimeEnv();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setNewFile(file);
        setErrors(null);
    };

    const handleFileUpload = () => {
        if (!newFile) {
            setErrors("Please select a file.");
            return;
        }

        if (props.extension.some(s => newFile.name.endsWith(s))) {
            props.handleUpload(newFile);
            console.log("Validation fine");
            setAddFile(false);
            setErrors(null);
        } else {
            setErrors("File needs to be " + props.extension.join(" or "));
            console.log("File needs to be in " + props.extension.join(" or "));
        }
    };

    const handleFileCancel = () => {
        setErrors(null);
        setAddFile(false);
    };
    return (
        <Container>
                {addFile &&
                    <Row className="d-flex align-items-center">
                        <Col sm={12} md={7}>
                            <Form>
                                <Form.Group>
                                    <Form.Control 
                                        type="file"
                                        className="rounded-pill"
                                        onChange={(e) => {setNewFile(e.target.files)}} 
                                        isInvalid={!!errors}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col sm={12} md={5}>
                            <Button 
                                variant="modal"
                                size="md"
                                className="w-50 rounded-pill"
                                onClick={(e) => {
                                    if(props.extension.some(s => newFile[0].name.endsWith(s))){
                                        props.handleUpload(newFile)
                                        console.log("Validation fine")
                                        setAddFile(false)
                                        setErrors(null)
                                    }else{
                                        setErrors("File needs to be " + props.extension.join(" or "))
                                        console.log("File needs to be in" + props.extension.join(" or "))
                                    }                                    
                                }
                            }>
                                Upload
                            </Button>
                            <Button 
                                variant="dark"
                                className="ms-2 rounded-pill"
                                size="md"
                                onClick={(e) => {
                                    setErrors(null), 
                                    setAddFile(false)
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                }
                {!addFile && 
                    <Row className="d-flex align-items-center">
                        <Col sm={12} md={7}>
                        <ItemsDropdown
                            selected={props.selected}
                            setSelected={props.setSelected}
                            filepath={true}
                            items={props.items.map(item => ({
                                ...item,
                                filepath: item.file,
                                file: (() => {
                                const filename = item.file.substr(item.file.lastIndexOf('/') + 1).split(".")[0];
                                // const version = item.version ? " (v" + item.version : "";
                                // return filename.length > 29 ? filename.substr(0, 29) + "..." + version : filename + version + ")";
                                return filename.length > 29 ? filename.substr(0, 29) + "..." : filename;

                                })(),
                            }))}
                            attr={"file"}
                            />
                        </Col>
                        <Col sm={12} md={5} className='px-0'>
                            <Button 
                                variant="none" 
                                size="md"
                                onClick={(e) => setAddFile(true)}
                            >
                                {<UploadIcon className='table-icon'/>}
                            </Button>
                            <Button 
                            variant="none"
                            size="md"
                            href={props.selected.name}
                            className='text-white bg-dark px-0 py-1 rounded'
                            >
                            <a href={props.selected.filepath} className='text-white download-icon p-1 detailview'>
                                <FaCloudDownloadAlt />
                            </a>
                            </Button>
                        </Col>
                    </Row>
                }
        </Container>
    )
};

export default SelectOrUploadFile;