import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import DjangoAPIClient from '../client';
import Image from 'react-bootstrap/Image'
import Scrapfinderlab_logo from '../assets/Scrapfinder logo02.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Navbar from 'react-bootstrap/Navbar';
import Navbar from '../components/Navbar';
import HomepageFooter from '../components/HomepageFooter'
import Alert from 'react-bootstrap/Alert';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'react-bootstrap';


const client = new DjangoAPIClient(config);

const HomepageExternal = ({title, isLoggedIn}) => {
  const navigate = useNavigate();

  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');

  
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
    document.activeElement.blur()
  };

  return (
    <>
      <Container fluid className='App bg-image rounded-bottom d-flex flex-column justify-content-between min-vh-100'>
        <Navbar></Navbar>
        {/* <Row className="rounded-top px-3 py-0" style={{backgroundColor: "white", minHeight: "45px", margin: "0 -12px" }}/> */}
        <Row className='justify-content-center align-items-center flex-column'>
          <h1 className='pt-2'>Welcome in</h1>
          <Image src={Scrapfinderlab_logo} className="logo"></Image>
          <Col sm={9} md={5} className='fw-semibold fs-5 pb-4'>
            Scrapfinder LAB is a web scraping platform for managing, scheduling and executing scraping tasks.
            {/* <br></br>Built by DEA for DEA. */}
          </Col>
        </Row>
        <Row className='pb-4'>
          <Col style={{borderRight: "solid black 3px"}}>
            <h3 className='fw-semibold'>DEA TEAM:</h3>
            <div className='fw-semibold fs-6 mb-4'>
              Scraping-related functionalities. Requires active account.
            </div>
            <div className="home-buttons">
                <Button variant="dark" className="me-4 mb-2" onClick={(e) => {
                  if(isLoggedIn===true){
                    navigate("/dea")}
                  else {
                    navigate("/login")
                  }}
                }>
                  Log in
                </Button>
                <Button variant="dark" className='mb-2' onClick={(e) => navigate("/register")}>
                  Sign up
                </Button>
            </div>
          </Col>
          <Col className='fw-semibold fs-4'>
            <h3 className='fw-semibold'>OTHER USERS:</h3>
            <div className='fw-semibold fs-6 mb-4'>
              Non-scraping related features. For BNG internal use only.
            </div>
            <div className="home-buttons">
                <Button variant="dark" className="me-4 mb-2" onClick={(e) => navigate("/eretailers-ext")}>
                  Eretailers list
                </Button>
                <Button variant="dark" className='mb-2' onClick={(e) => openInNewTab("https://briefing.scrapfinderlab.com/web-scraping-briefing/")}>
                  Briefing
                </Button>
            </div>
          </Col>
        </Row>
        <Row className="rounded-bottom px-3 py-0" style={{backgroundColor: "white", minHeight: "53px", margin: "0 -12px" }}/>
      </Container>
    </>
)
}

export default HomepageExternal;