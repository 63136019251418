import React, { useState, useEffect, useContext } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import Navbar from '../../components/Navbar';
import CreateReportModal from './CreateReportModal';
import ManageReportModal from './ManageReportModal';
import ReportsTable from './ReportsTable';
import Sidebar from '../../components/Sidebar';

import config from '../../config';
import DjangoAPIClient from '../../client';

const client = new DjangoAPIClient(config);


const ReportsPage = ({ title, userData }) => {
	const [refreshData, setRefreshData] = useState(0);

	const [selectedReport, setSelectedReport] = useState({});
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showManageReportModal, setShowManageReportModal] = useState(false);

	////////// Page title & mobile detection
	useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = title;
	}, [title]);

	useEffect(() => {
		if (!showCreateModal) {
			setRefreshData(refreshData + 1);
		}
	}, [showCreateModal])

	return (
		<>
			<Sidebar userData={userData} />
			<Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
				<Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
				<CreateReportModal
					client={client}
					showModal={showCreateModal}
					setShowModal={setShowCreateModal}
				/>
				<ManageReportModal
					client={client}
					showModal={showManageReportModal}
					setShowModal={setShowManageReportModal}
					refreshData={refreshData}
					setRefreshData={setRefreshData}
					report={selectedReport}
				/>

				<section>
					<Row className='px-3 py-1 bg-white d-flex align-items-center'>
						<Col sm={12} md={6}>
							<header className="bg-white pt-2">
								<h1 className='text-start'>My reports</h1>
							</header>
						</Col>
						<Col sm={12} md={6} className='d-flex justify-content-md-end justify-content-sm-start'>
							<Button
								className='rounded-pill'
								variant='pf23'
								onClick={(e) => setShowCreateModal(true)}
							>
								Add Report
							</Button>
						</Col>
					</Row>
					<Row className='p-3 '>
						<ReportsTable
							client={client}
							userId={userData.id}
							setSelectedReport={setSelectedReport}
							setShowManageModal={setShowManageReportModal}
							refreshData={refreshData}
							maxPagination={10}
						/>
					</Row>
				</section>
			</Container>
		</>
	)
}

export default ReportsPage;
