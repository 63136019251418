import config from './config';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';

var axios = require("axios");
var axiosDefaults = require("axios/lib/defaults");

var axios = require("axios");
var axiosDefaults = require("axios/lib/defaults");

axiosDefaults.xsrfCookieName = "csrftoken"
axiosDefaults.xsrfHeaderName = "X-CSRFToken"


class DjangoAPIClient {
	constructor(overrides) {
		this.config = {
			...config,
			...overrides,
		};
		this.authToken = config.authToken;

		this.login = this.login.bind(this);
		this.apiClient = this.getApiClient(this.config);
	}

	/* ----- Client Configuration ----- */

	/* Create Axios client instance pointing at the REST api backend */
	getApiClient(config) {
		const initialConfig = {
			baseURL: `${config.apiBasePath}`,
			credentials: 'include', // to allow cookies
			headers: {
				"Content-type": "application/json",
				// 'Access-Control-Allow-Origin': 'http://localhost:8000',
			}
		};
		const client = axios.create(initialConfig);

		// adding jwt token to headers ({Authorization: Bearer $token}) to authenticate request:
		client.interceptors.request.use(localStorageTokenInterceptor);

		return client;
	}
	/* ----- Authentication & User Operations ----- */

	/* Authenticate the user with the backend services.
	   * The same JWT should be valid for both the api and cms */

	login(email, password) {
		delete this.apiClient.defaults.headers['Authorization'];

		const form_data = {
			"email": email,
			"password": password
		}

		return this.apiClient
			.post('/api/users/login/', form_data)
			.then((resp) => {
				localStorage.setItem('token', JSON.stringify(resp.data));
				this.fetchUser()
				return resp;
			})
			.catch((error) => {
				console.log(error)
			});
	}

	// After loggin in the information about user are saved to localStorage
	fetchUser() {
		return this.apiClient.get('/api/users/profile/')
			.then(({ data }) => {
				return data;
			});
	}

	fetchUserData() {
		return this.apiClient.get('/api/users/profile/')
	}

	getSiteName() {
		return this.apiClient.get('/api/users/site/')
	}

	// getUserData() {
	// 	return this.apiClient.get(`/api/users/profile`);
	// }
	// updateUserProfile(email, first_name, last_name) {
	//   const UpdateUserData = {
	//     "email": email,
	//     "first_name": first_name,
	//     "last_nane": last_name
	//   };

	//   return this.apiClient.post(`/api/users/profile/`, UpdateUserData).then(
	//     (resp) => {
	//       console.log(resp.data)
	//       return resp.data;
	//     });
	// }

	register(email, password, password2) {
		const registerData = {
			"email": email,
			"password": password,
			"password2": password2,
		};

		return this.apiClient.post('/api/users/register/', registerData).then(
			(resp) => {
				console.log(resp.data)
				return resp.data;
			});
	}

	activateAccount(uidb64, token) {
		return this.apiClient.get(`api/users/activate/${uidb64}/${token}/`).then(
			(resp) => {
				console.log(resp.data)
				return resp.data;
			});
	}

	changePassword(password, password2) {
		const ChangePasswordData = {
			"password": password,
			"password2": password2,
		};

		return this.apiClient.post('/api/users/change-password/', ChangePasswordData).then(
			(resp) => {
				console.log(resp.data)
				return resp.data;
			});
	}

	sendResetPasswordEmail(email) {
		const SendEmailResetPasswordData = {
			"email": email,
		};

		return this.apiClient.post('/api/users/send-reset-password-email/', SendEmailResetPasswordData).then(
			(resp) => {
				console.log(resp.data)
				return resp.data;
			});
	}

	resetPassword(password, password2, uid, token) {
		const ResetEmailData = {
			"password": password,
			"password2": password2,
		};

		return this.apiClient.post(`api/users/reset-password/${uid}/${token}/`, ResetEmailData).then(
			(resp) => {
				console.log(resp.data)
				return resp.data;
			});
	}

	getUserGroups() {
		return this.apiClient.get(`api/users/groups/`);
	}

	getDocumentation() {
		return this.apiClient.get(`/api/users/documentation`);
	}

	getQuickstart() {
		return this.apiClient.get(`/api/users/quick-start`);
	}

	getQuestionsandAnswers() {
		return this.apiClient.get(`/api/users/frequently-asked-questions`);
	}

	getMySpiders(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`api/spiders/spiders/?${filtersString}`);
	}

	getSpider(spiderId) {
		return this.apiClient.get(`api/spiders/spiders/${spiderId}`);
	}

	createNewSpider(projectId, spiderName, mongoCollectionNameId, spiderConfigId, yamlConfigId, templateFileId, kwargs) {
		const data = {
			"project": projectId,
			"name": spiderName,
			"mongodb_collection": mongoCollectionNameId,
			"spider_config_file": spiderConfigId,
			"yaml_config_file": yamlConfigId,
			"template_file": templateFileId,
			"enabled": true,
			"kwargs": kwargs,
		}
		return this.apiClient.post(`api/spiders/spiders/`, data)
	}

	deleteSpider(spiderId) {
		return this.apiClient.delete(`api/spiders/spiders/${spiderId}`);
	}

	updateSpider(spiderId, spiderData) {
		return this.apiClient.put(`api/spiders/spiders/${spiderId}`, spiderData)
	}

	getMyProjects(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`api/projects/myprojects/?${filtersString}`);
	}

	getProjectDetails(projectId) {
		return this.apiClient.get(`api/projects/myprojects/${projectId}`);
	}

	createNewProject(projectName, is_shared, group) {
		const data = {
			"name": projectName,
			"is_shared": is_shared,
			"group": group
		}
		return this.apiClient.post(`api/projects/myprojects/`, data)
	}

	updateProject(projectId, newProjectName, group) {
		if (group.name === 'Private') {
			const data = {
				"name": newProjectName,
				"group": group.id,
				"is_shared": false
			}
			return this.apiClient.patch(`api/projects/myprojects/${projectId}`, data)
		}
		else {
			const data = {
				"name": newProjectName,
				"group": group.id,
				"is_shared": true
			}
			return this.apiClient.patch(`api/projects/myprojects/${projectId}`, data)
		}
	}

	editProjectName(projectId, newProjectName) {
		const data = {
			"name": newProjectName
		}
		return this.apiClient.patch(`api/projects/myprojects/${projectId}`, data)
	}

	deleteProject(projectId) {
		return this.apiClient.delete(`api/projects/myprojects/${projectId}`);
	}

	getMyCollections() {
		return this.apiClient.get(`api/spiders/mycollections/`);
	}

	createNewCollection(collection_name) {
		const data = {
			"collection_name": collection_name
		}
		return this.apiClient.post(`api/spiders/mycollections/`, data)
	}

	isFileInDB(fileType, projectName, fileName) {
		// fileType: spider-config, yaml-config, template-file
		const projectNameEncoded = encodeURIComponent(projectName)
		const fileNameEncoded = encodeURIComponent(fileName)
		return this.apiClient.get(`api/files/isfile?file_type=${fileType}&project_name=${projectNameEncoded}&file_name=${fileNameEncoded}`)
	}

	getUserFiles(filesType, activePage, sortColumn, sortDirection, filters) {
		// fileType: spider-config, yaml-config, template-file
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		if (filesType === "spider-configs") {
			return this.apiClient.get(`api/files/spider-configs/?${filtersString}`)
		} else if (filesType === "yaml-configs") {
			return this.apiClient.get(`api/files/yaml-configs/?${filtersString}`)
		} else if (filesType === "template-files") {
			return this.apiClient.get(`api/files/template-files/?${filtersString}`)
		}
	}

	deleteUserFile(fileType, fileId) {
		// fileType: spider-config, yaml-config, template-file
		if (fileType === "spider-configs") {
			return this.apiClient.delete(`api/files/spider-configs/${fileId}`)
		} else if (fileType === "yaml-configs") {
			return this.apiClient.delete(`api/files/yaml-configs/${fileId}`)
		} else if (fileType === "template-files") {
			return this.apiClient.delete(`api/files/template-files/${fileId}`)
		}
	}

	getMySpiderConfigs(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`api/files/spider-configs/?${filtersString}`)
	}

	uploadSpiderConfig(file, project) {
		const formData = new FormData();
		formData.append("file", file[0])
		formData.append("project", project)
		return this.apiClient.post(`api/files/spider-configs/`, formData)
	}

	getMyYamlConfigs(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`api/files/yaml-configs/?${filtersString}`)
	}

	uploadYamlConfig(file, project) {
		const formData = new FormData();
		formData.append("file", file[0])
		formData.append("project", project)
		return this.apiClient.post(`api/files/yaml-configs/`, formData)
	}

	getMyTemplateFiles(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`api/files/template-files/?${filtersString}`)
	}

	getYamlData() {
		return this.apiClient.get(`api/files/eretailers/`)
	}

	deleteSpiderConfig(spiderConfigId) {
		return this.apiClient.delete(`api/files/spider-configs/${spiderConfigId}`)
	}
	
	deleteYamlConfig(yamlConfigId) {
		return this.apiClient.delete(`api/files/yaml-configs/${yamlConfigId}`)
	}
	
	deleteTemplateFile(templateFileId) {
		return this.apiClient.delete(`api/files/yaml-configs/${templateFileId}`)
	}

	uploadTemplateFile(file, project) {
		const formData = new FormData();
		formData.append("file", file[0])
		formData.append("project", project)
		return this.apiClient.post(`api/files/template-files/`, formData)
	}

	getScheduledPeriodicTasks(spiderId) {
		return this.apiClient.get(`/api/tasks/periodic-tasks/?spider_id=${spiderId}&one_off=0`)
	}

	createNewCrontabSchedule(crontabExpression) {
		const values = crontabExpression.split(" ");
		const data = {
			"minute": values[0],
			"hour": values[1],
			"day_of_month": values[2],
			"month_of_year": values[3],
			"day_of_week": values[4],
		}
		return this.apiClient.post(`api/tasks/crontab-schedule/`, data)
	}

	createCrontabScheduledPeriodicTask(userId, spiderId, spiderRegion, crontabExpression) {
		return this.createNewCrontabSchedule(crontabExpression).then(
			(response) => {
				const data = {
					"name": new Date().toJSON() + "_userid=" + userId + "_spiderid=" + spiderId,
					"task": "add_spider_schedule",
					"args": `[${userId}, ${spiderId}]`,
					"kwargs":`{"spider_id":"${spiderId}", "region":"${spiderRegion}"}`,
					"enabled": true,
					"headers": "{}",
					"crontab": response.data.id
				}
				return this.apiClient.post(`api/tasks/periodic-tasks/`, data)
			}
		)
	}

	runOneOffPeriodicTask(spiderId) {
		return this.apiClient.post(`api/spiders/spiders/${spiderId}/run-now/`)
	}

	changeEnablePeriodicTask(periodicTaskId, enabled) {
		const data = {
			"enabled": enabled
		}
		return this.apiClient.patch(`api/tasks/periodic-tasks/${periodicTaskId}`, data);
	}

	deletePeriodicTask(periodicTaskId) {
		return this.apiClient.delete(`api/tasks/periodic-tasks/${periodicTaskId}`);
	}
	
	getTaskResultsDetails(taskResultId) {
		return this.apiClient.get(`/api/tasks/spider-task-results/${taskResultId}`)
	}

	getTaskResults(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`/api/tasks/spider-task-results/?${filtersString}`);
	}

	getTaskResultOutput(taskId) {
		return this.apiClient.get(`/api/tasks/spider-task-results-output/${taskId}/`)
	}

	deleteMongoDBTaskOutput(taskId) {
		return this.apiClient.get(`/api/tasks/delete-spider-task-results-output/${taskId}/`)
	}

	getTaskResultLogs(jobId) {
		if (jobId === null) {
			return this.apiClient.get(`/api/tasks/logs/`)
		}
		else {
			return this.apiClient.get(`/api/tasks/logs?job_id=${jobId}`)
		}
	}

	stopTaskResult(taskId) {
		return this.apiClient.get(`/api/tasks/spider-task-results/${taskId}/stop/`)
	}

	deleteTaskResult(taskResultId) {
		return this.apiClient.delete(`/api/tasks/delete-spider-task-results-output/${taskResultId}`)
	}

	// DROPDOWNS ENDPOINTS:
	getDropdownSpiders(projectId) {
		return this.apiClient.get(`/api/spiders/spiders/dropdown?project_id=${projectId}`)
	}

	getDropdownProjects() {
		return this.apiClient.get(`/api/projects/myprojects/dropdown`)
	}

	getDropdownSpiderConfigs(projectName) {
		return this.apiClient.get(`/api/files/spider-configs/dropdown?project_name=${projectName}`)
	}

	getDropdownYamlConfigs(projectName) {
		return this.apiClient.get(`/api/files/yaml-configs/dropdown?project_name=${projectName}`)
	}

	getDropdownTemplateFiles(projectName) {
		return this.apiClient.get(`/api/files/template-files/dropdown?project_name=${projectName}`)
	}

	getDepartments() {
		return this.apiClient.get(`/api/briefing/departments/`)
	}

	getComponents() {
		return this.apiClient.get(`/api/briefing/components/`)
	}

	getDashboardData(last_7_days, last_14_days, last_30_days, project_id) {
		if (last_7_days) {
			return this.apiClient.get(`/api/dashboard/my-statistics/?last_7_days=${last_7_days}&project_id=${project_id}`)
		}
		else if (last_14_days) {
			return this.apiClient.get(`/api/dashboard/my-statistics/?last_14_days=${last_14_days}&project_id=${project_id}`)
		}
		else if (last_30_days) {
			return this.apiClient.get(`/api/dashboard/my-statistics/?last_30_days=${last_30_days}&project_id=${project_id}`)
		}
		return this.apiClient.get(`/api/dashboard/my-statistics/?last_7_days=${last_7_days}&project_id=${project_id}`)
	}

	createNewBrief(name, department, email, float_number, e_retailers, components, extra_info) {
		const data = {
			"name": name,
			"department": department,
			"email": email,
			"float_number": float_number,
			"e_retailers": e_retailers,
			"components": components,
			"extra_info": extra_info
		}
		console.log(data)
		return this.apiClient.post(`api/briefing/briefing/`, data)
	}	

	getSpiderConfigPossibleKwargs(spiderId) {
		return this.apiClient.get(`/api/files/spider-configs/${spiderId}/kwargs/`)
	}

	// POWERBI ENDPOINTS: //
	addPowerBIReport(reportId) {
		const data = {
			"report_id": reportId
		}
		return this.apiClient.post(`/api/pbi/create-report/`, data)
	}

	getPowerBIReports(activePage, sortColumn, sortDirection, filters) {
		let filtersString = Object.entries(filters).map(x => x.join("=")).join("&")+`&page=${activePage}&ordering=${sortDirection}${sortColumn}`
		return this.apiClient.get(`/api/pbi/reports/?${filtersString}`)
	}

	getPowerBIReport(reportId) {
		return this.apiClient.get(`/api/pbi/report-details/${reportId}/`)
	}

	deletePowerBIReport(reportId) {
		return this.apiClient.delete(`/api/pbi/reports/${reportId}/`)
	}

	editPowerBIReport(reportId, reportName, groupId) {
		const data = {
			"report_name": reportName,
			"group": groupId
		}
		return this.apiClient.patch(`/api/pbi/report-details/${reportId}/`, data)
	}

	getEmbedParams(reportId) {
		return this.apiClient.get(`/api/pbi/get-embed-params/${reportId}/`)
	}

	runTestOneOffPeriodicTask(spiderId) {
		return this.apiClient.post(`api/spiders/spiders/${spiderId}/run-test-spider/`)
	}

	fetchRegionChoices() {
		return this.apiClient.get(`/api/spiders/region-choices/`)
	}

	getS3BucketScrapedDataSubfolder() {
		return this.apiClient.get(`/api/tasks/s3-bucket-objects/`)
	}

	getScrapedData(prefix, page, filters, pageSize, lowercased, partialMatch, showStatistic) {
		const queryParams = new URLSearchParams({
			prefix,
			page_number: page,
			page_size: pageSize,
			filters: JSON.stringify(filters),
			lowercased,
			partial_match: partialMatch,
			show_statistic: showStatistic
		}).toString();
	
		return this.apiClient.get(`api/tasks/s3-json-data/?${queryParams}`);
	}
	
	deleteScrapedData(prefix, filters, lowercased, partialMatch) {
		return this.apiClient.delete(`api/tasks/s3-json-data/?prefix=${prefix}`, {
			data: { filters, lowercased, partial_match: partialMatch }
		});
	}
	
	updateScrapedData(prefix, filters, updateData, lowercased, partialMatch) {
		return this.apiClient.put(`api/tasks/s3-json-data/?prefix=${prefix}`, {
			filters,
			update_data: updateData,
			lowercased,
			partial_match: partialMatch
		});
	}
	///////////////////////

	// getCeleryTasks(){
	//   return this.apiClient.get(`/api/tasks/celery-tasks/`)
	// }

	// Logging out is just deleting the jwt.
	logout() {
		// Add here any other data that needs to be deleted from local storage
		// on logout
		localStorage.removeItem('token');
	}

}


// every request is intercepted and has auth header injected.
function localStorageTokenInterceptor(config) {
	const headers = {};
	const tokenString = localStorage.getItem('token');

	if (tokenString) {
		const token = JSON.parse(tokenString)["token"];
		const decodedAccessToken = jwtDecode(token.access);
		const isAccessTokenValid =
			moment.unix(decodedAccessToken.exp).toDate() > new Date();

		if (isAccessTokenValid) {
			headers['Authorization'] = `Bearer ${token.access}`;
		} else {
			// alert('Your login session has expired');
			// console.log("Session expired")
		}
	}
	config['headers'] = headers;
	return config;
}

export default DjangoAPIClient;
