import {React, useState, useEffect, useContext} from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { ReactComponent as DeleteIcon } from '../assets/icon09_delete.svg';

import WarningModal from './modals/WarningModal';

// props.handleDelete -> function triggered with delete icon
// props.warningBodyText -> body text with warning before deleting

const DeleteIconWithWarning = (props) => {
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    
    return (
        <Container>
            <WarningModal 
                showModal={showDeleteWarning}
                setShowModal={setShowDeleteWarning}
                handleConfirm={props.handleDelete}
                bodyText={props.warningBodyText}
            />
            <Row>
                <Col>
                    <Button 
                        size="md" 
                        type="button"
                        variant="none"
                        onClick={(e) => setShowDeleteWarning(true)}
                    >
                        {<DeleteIcon className='table-icon'/>}
                    </Button>

                </Col>
            </Row>
        </Container>
    )
}

export default DeleteIconWithWarning;