// Import the 'config.json' file
import configData from './config.json';

const config = {
  // Use the data from 'config.json' instead of environment variables
  apiBasePath: configData.apiBasePath || '',
  reactAppMode: configData.reactAppMode || '',
};


export default config;