import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Table, Card, Spinner, Form, Button, Modal } from 'react-bootstrap';
import config from '../config';
import DjangoAPIClient from '../client';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import PaginationComponent from '../components/ApiPagination';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const client = new DjangoAPIClient(config);

const ScrapedData = ({ title, userData }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [prefix, setPrefix] = useState('');
    const [subfolders, setSubfolders] = useState([]);
    const [filters, setFilters] = useState([]);
    const [updateData, setUpdateData] = useState('{}');
    const [pageSize, setPageSize] = useState(10);
    const [lowercased, setLowercased] = useState(false);
    const [partialMatch, setPartialMatch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [noDataMessage, setNoDataMessage] = useState('');
    const [editableRow, setEditableRow] = useState(null);
    const [updatedData, setUpdatedData] = useState({});
    const [numberOfResults, setNumberOfResults] = useState(0);
    const [expandedRows, setExpandedRows] = useState([]);
    const [linkAsText, setLinkAsText] = useState(true);
    const [loadingStatistics, setLoadingStatistics] = useState(false);
    const [showStatistics, setShowStatistics] = useState(false);
    const [columnStatistics, setColumnStatistics] = useState({});
    const [showStatisticsModal, setShowStatisticsModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateFields, setUpdateFields] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = title;
        fetchSubfolders();
    }, [title]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;
        const urlPrefix = params.get('prefix') || '';
        setCurrentPage(page);
        setPrefix(urlPrefix);
        if (urlPrefix) {
            fetchData(page, urlPrefix);
        }
    }, [window.location.search]);

    const fetchSubfolders = () => {
        client.getS3BucketScrapedDataSubfolder()
            .then(response => {
                console.log(response.data);
                setSubfolders(response.data);
            })
            .catch(error => {
                setError(error.message);
            });
    };

    const fetchData = (page = 1, prefixValue = prefix) => {
        if (!prefixValue) {
            setNoDataMessage('No prefix provided.');
            setData([]);
            return;
        }
        setLoading(true);
        setNoDataMessage('');
        console.log(prefixValue);
        const filterObject = filters.reduce((acc, filter) => {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        client.getScrapedData(prefixValue, page, filterObject, pageSize, lowercased, partialMatch, false)
            .then(response => {
                if (typeof response.data.data === 'string') {
                    setNoDataMessage(response.data.data);
                    setData([]);
                } else {
                    setData(response.data.data);
                    setLastPage(response.data.last_page);
                    setNumberOfResults(response.data.number_of_records);
                }
                setLoading(false);
                console.log(response.data);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    };

    const fetchStatistics = () => {
        if (!prefix) {
            setNoDataMessage('Please select a subfolder.');
            return;
        }
        setLoadingStatistics(true);
        const filterObject = filters.reduce((acc, filter) => {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        client.getScrapedData(prefix, currentPage, filterObject, pageSize, lowercased, partialMatch, true)
            .then(response => {
                setColumnStatistics(response.data.column_statistics || {});
                setLoadingStatistics(false);
                console.log(response.data);
            })
            .catch(error => {
                setError(error.message);
                setLoadingStatistics(false);
            });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        navigate(`?page=${newPage}&prefix=${prefix}`);
    };

    const handleFetchData = () => {
        if (!prefix) {
            setNoDataMessage('Please select a subfolder.');
            return;
        }
        fetchData(1, prefix);
        navigate(`?page=1&prefix=${prefix}`);
        setShowFilterModal(false);
    };

    const handleAddFilter = () => {
        setFilters([...filters, { key: '', value: '' }]);
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const handleFilterChange = (index, key, value) => {
        const newFilters = filters.map((filter, i) => i === index ? { ...filter, [key]: value } : filter);
        setFilters(newFilters);
    };

    const handleDelete = (item) => {
        const deleteData = {
            prefix,
            filters: item,
            lowercased,
            partialMatch
        };

        client.deleteScrapedData(deleteData.prefix, deleteData.filters, deleteData.lowercased, deleteData.partialMatch)
            .then(response => {
                console.log(response.data);
                fetchData(currentPage, prefix);
            })
            .catch(error => {
                setError(error.message);
            });
    };

    const handleBulkDelete = () => {
        const filtersData = filters.reduce((acc, filter) => {
            acc[filter.key] = filter.value;
            return acc;
        }, {});

        const deleteData = {
            prefix,
            filters: filtersData,
            lowercased,
            partialMatch
        };

        client.deleteScrapedData(deleteData.prefix, deleteData.filters, deleteData.lowercased, deleteData.partialMatch)
            .then(response => {
                console.log(response.data);
                fetchData(currentPage, prefix);
            })
            .catch(error => {
                setError(error.message);
            });
    };

    const handleUpdate = (rowIndex) => {
        setEditableRow(rowIndex);
        setUpdatedData(data[rowIndex]);
    };

    const handleSave = (rowIndex) => {
        const filters = data[rowIndex];
        const updateData = updatedData;

        client.updateScrapedData(prefix, filters, updateData, lowercased, partialMatch)
            .then(response => {
                console.log(response.data);
                setEditableRow(null);
                fetchData(currentPage, prefix);
            })
            .catch(error => {
                setError(error.message);
            });
    };

    const handleAddUpdateField = () => {
        setUpdateFields([...updateFields, { key: '', value: '' }]);
    };

    const handleRemoveUpdateField = (index) => {
        const newUpdateFields = updateFields.filter((_, i) => i !== index);
        setUpdateFields(newUpdateFields);
    };

    const handleUpdateFieldChange = (index, key, value) => {
        const newUpdateFields = updateFields.map((field, i) => i === index ? { ...field, [key]: value } : field);
        setUpdateFields(newUpdateFields);
    };

    const handleBulkUpdate = () => {
        const filtersData = filters.reduce((acc, filter) => {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        const updateDataParsed = updateFields.reduce((acc, field) => {
            acc[field.key] = field.value;
            return acc;
        }, {});

        client.updateScrapedData(prefix, filtersData, updateDataParsed, lowercased, partialMatch)
            .then(response => {
                console.log(response.data);
                fetchData(currentPage, prefix);
                setShowUpdateModal(false);
            })
            .catch(error => {
                setError(error.message);
            });
    };

    const handleChange = (e, key) => {
        setUpdatedData({
            ...updatedData,
            [key]: e.target.value
        });
    };

    const toggleRow = (rowIndex) => {
        const currentExpandedRows = expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowIndex);

        const newExpandedRows = isRowCurrentlyExpanded
            ? currentExpandedRows.filter(id => id !== rowIndex)
            : currentExpandedRows.concat(rowIndex);

        setExpandedRows(newExpandedRows);
    };

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const truncateText = (text, maxLength = 50) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const handleToggleStatistics = () => {
        setShowStatistics(prevShowStatistics => !prevShowStatistics);
        if (!showStatistics) {
            fetchStatistics();
        }
        setShowStatisticsModal(!showStatisticsModal);
    };

    const renderTableRows = () => {
        return data.map((item, rowIndex) => (
            <React.Fragment key={rowIndex}>
                <tr>
                    <td onClick={() => toggleRow(rowIndex)} className="table-cell">
                        {expandedRows.includes(rowIndex) ? <FaChevronDown /> : <FaChevronRight />}
                    </td>
                    {Object.entries(item).map(([key, val], colIndex) => (
                        <td key={colIndex} className="table-cell">
                            {editableRow === rowIndex ? (
                                <input
                                    type="text"
                                    value={updatedData[key]}
                                    onChange={(e) => handleChange(e, key)}
                                    className="table-cell"
                                />
                            ) : (
                                typeof val === 'string' && isValidUrl(val) ? (
                                    <a href={val} target="_blank" rel="noopener noreferrer" className="table-cell">
                                        {linkAsText ? 'link' : val}
                                    </a>
                                ) : (
                                    typeof val === 'string' ? truncateText(val) :
                                    typeof val === 'boolean' ? val.toString() :
                                    typeof val === 'object' && val !== null && '$oid' in val ? val.$oid :
                                    JSON.stringify(val)
                                )
                            )}
                        </td>
                    ))}
                    <td className="table-cell">
                        {editableRow === rowIndex ? (
                            <>
                                <Button variant='success' onClick={() => handleSave(rowIndex)}>Save</Button>
                                <Button variant='secondary' onClick={() => setEditableRow(null)}>Cancel</Button>
                            </>
                        ) : (
                            <Button onClick={() => handleUpdate(rowIndex)}>Update</Button>
                        )}
                        <Button variant='danger' onClick={() => handleDelete(item)}>Delete</Button>
                    </td>
                </tr>
                {expandedRows.includes(rowIndex) && (
                    <tr>
                        <td colSpan={Object.keys(item).length + 2}>
                            <Row className="expanded-row-content text-start">
                                <pre className='bg-light text-black'>{JSON.stringify(item, null, 2)}</pre>
                            </Row>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        ));
    };

    const renderTableHeader = () => {
        if (data.length === 0) return null;
        const uniqueColumns = new Set();
        data.forEach(item => {
            Object.keys(item).forEach(key => uniqueColumns.add(key));
        });
        return (
            <tr>
                <th></th> {/* Add an empty header for the arrow column */}
                {[...uniqueColumns].map((col, index) => (
                    <th key={index}>{col}</th>
                ))}
                <th>Actions</th>
            </tr>
        );
    };

    const renderStatisticsTable = () => {
        return (
            <Modal show={showStatisticsModal} onHide={() => {
                setShowStatisticsModal(false);
                setShowStatistics(false);
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Column Statistics</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingStatistics ? (
                        <Spinner animation="border" />
                    ) : (
                        <Table striped bordered hover responsive variant='light'>
                            <thead>
                                <tr>
                                    <th>Column</th>
                                    <th>Count</th>
                                    <th>Missing</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(columnStatistics).map(([key, value], index) => (
                                    <tr key={index}>
                                        <td>{key}</td>
                                        <td>{value}</td>
                                        <td>{numberOfResults - value}</td>
                                        <td>{((value / numberOfResults) * 100).toFixed(2)}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowStatisticsModal(false);
                        setShowStatistics(false);
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className='App with-sidebar bg-light rounded-bottom d-flex flex-column min-vh-100'>
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <Row className="App-header bg-white">
                    <Col>
                        <h1>Scraped Data</h1>
                    </Col>
                </Row>
                <Row className="mt-3 d-flex justify-content-center">
                    <Col sm={6} md={3}>
                        <Form.Group controlId="prefixInput" className="text-center">
                            <Form.Label>Select S3 Project</Form.Label>
                            <Form.Control
                                as="select"
                                value={prefix}
                                onChange={(e) => setPrefix(e.target.value)}
                                className="mx-auto select-arrow"
                            >
                                <option value="">Select a subfolder</option>
                                {subfolders.map((subfolder, index) => (
                                    <option key={index} value={subfolder}>
                                        {subfolder}
                                    </option>
                                ))}
                            </Form.Control>
                            {noDataMessage && (
                                <div className="mt-3 text-danger">
                                    <p>{noDataMessage}</p>
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col sm={6} md={3}>
                        <Form.Group controlId="pageSizeInput">
                            <Form.Label>Page Size</Form.Label>
                            <Form.Control
                                type="number"
                                value={pageSize}
                                min={1}
                                max={1000}
                                onChange={(e) => setPageSize(Math.min(parseInt(e.target.value), 1000))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col sm={6} md={3}>
                        <Button variant="pf23" onClick={handleFetchData} className="mt-2">
                            Show Data
                        </Button>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={6} xl={3}>
                        <Card className="mb-1 h-100">
                            <Card.Header>Filter Data</Card.Header>
                            <Card.Body>
                                <Button variant="primary" onClick={() => setShowFilterModal(true)}>Filter Data</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <Card className="mb-1 h-100">
                            <Card.Header>Update Data</Card.Header>
                            <Card.Body>
                                <Button variant="info" onClick={() => setShowUpdateModal(true)}>Update Data</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                        <Card className="mb-1 h-100">
                            <Card.Header>Delete Data</Card.Header>
                            <Card.Body>
                                <Button variant="danger" onClick={handleBulkDelete}>
                                    Delete All Filtered Data
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} xl={3}>
                    <Card className="mb-1 h-100">
                        <Card.Header>Extra features</Card.Header>
                        <Card.Body>
                            <div className="mb-2">
                                <Button
                                    variant="dark"
                                    onClick={handleToggleStatistics}
                                >
                                    {showStatistics ? "Hide Statistics" : "Show Statistics"}
                                </Button>
                            </div>
                            <div className="mb-2">
                                <Button
                                    variant={linkAsText ? "info" : "secondary"}
                                    onClick={() => setLinkAsText(!linkAsText)}
                                >
                                    {linkAsText ? "Show URLs" : "Hyperlinks"}
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                {showStatistics && (
                    <div>
                        {renderStatisticsTable()}
                    </div>
                )}
                </Row>
                {loading && (
                    <Row className="text-center">
                        <Col>
                            <Spinner animation="border" />
                        </Col>
                    </Row>
                )}
                {error && (
                    <Row className="text-center">
                        <Col>
                            <p style={{ color: 'red' }}>{error}</p>
                        </Col>
                    </Row>
                )}
                {!loading && !error && data.length > 0 && (
                    <>
                        <Row>
                            <Col xs={12} className="text-start pb-3">
                                <h5><strong>Total number of results: {numberOfResults}</strong></h5>
                            </Col>
                            <Col>
                                <Table striped bordered hover responsive variant='light'>
                                    <thead>
                                        {renderTableHeader()}
                                    </thead>
                                    <tbody>
                                        {renderTableRows()}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col>
                                <PaginationComponent
                                    pageLimit={pageSize}
                                    countAllResults={lastPage * pageSize}
                                    activePage={currentPage}
                                    setActivePage={handlePageChange}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {filters.map((filter, index) => (
                                <Row key={index} className="mb-2">
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Key"
                                            value={filter.key}
                                            onChange={(e) => handleFilterChange(index, 'key', e.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            placeholder="Value"
                                            value={filter.value}
                                            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="danger" onClick={() => handleRemoveFilter(index)}>Remove</Button>
                                    </Col>
                                </Row>
                            ))}
                            <Button variant="pf23" onClick={handleAddFilter}>Add Filter</Button>
                            <Form.Group controlId="lowercasedInput" className="mt-3">
                            <Form.Check
                                type="checkbox"
                                label="Lowercased"
                                checked={lowercased}
                                onChange={(e) => setLowercased(e.target.checked)}
                                className='text-start'
                            />
                        </Form.Group>
                        <Form.Group controlId="partialMatchInput" className="mt-2">
                            <Form.Check
                                type="checkbox"
                                label="Partial Match"
                                checked={partialMatch}
                                onChange={(e) => setPartialMatch(e.target.checked)}
                                className='text-start'
                            />
                        </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="dark" onClick={handleFetchData} className="mt-2">
                            Apply filters
                        </Button>
                        <Button variant="secondary" onClick={() => setShowFilterModal(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {updateFields.map((field, index) => (
                            <Row key={index} className="mb-2">
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Key"
                                        value={field.key}
                                        onChange={(e) => handleUpdateFieldChange(index, 'key', e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        type="text"
                                        placeholder="Value"
                                        value={field.value}
                                        onChange={(e) => handleUpdateFieldChange(index, 'value', e.target.value)}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <Button variant="danger" onClick={() => handleRemoveUpdateField(index)}>Remove</Button>
                                </Col>
                            </Row>
                        ))}
                        <Button variant="pf23" onClick={handleAddUpdateField}>Add Update Field</Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleBulkUpdate} className="mt-2">
                        Update All Filtered Data
                    </Button>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            </Container>
        </>
    );
};

export default ScrapedData;