import React, { useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import FilesTable from '../components/tables/FilesTable';
import UploadFileModal from '../components/modals/UploadFileModal';

import DjangoAPIClient from '../client';
import config from '../config';

const client = new DjangoAPIClient(config);


const UserFiles = ({ title, userData }) => {
    const [key, setKey] = useState('spider-configs');
    const [groupNameFilters, setGroupNameFilters] = useState(["Public files", "My files", "My team files"]);

    const [refreshData, setRefreshData] = useState(0);

    const [showUploadFileModal, setShowUploadFileModal] = useState(false);

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = title;
    }, [title]);

    function handleFileUpload(fileType, projectName, file) {
        if (fileType === "spider-config") {
            client.uploadSpiderConfig(file, projectName).then(
                (response) => {
                    setKey("spider-configs")
                    setRefreshData(refreshData + 1);
                }
            );
        } else if (fileType === "yaml-config") {
            client.uploadYamlConfig(file, projectName).then(
                (response) => {
                    setKey("yaml-configs")
                    setRefreshData(refreshData + 1);
                }
            );
        } else if (fileType === "template-file") {
            client.uploadTemplateFile(file, projectName).then(
                (response) => {
                    setKey("template-files")
                    setRefreshData(refreshData + 1);
                }
            );
        }
    }

    function handleGroupNameFilterChange(event) {
        const value = event.target.value;
        let newFilters = [...groupNameFilters];
        if (event.target.checked) {
            newFilters.push(value);
        } else {
            newFilters = newFilters.filter((filter) => filter !== value);
        }
        if (newFilters.length === 0) {
            newFilters = ["Public files", "My files", "My team files"];
        }
        setGroupNameFilters(newFilters);
    }

    return (
        <>
            <Sidebar userData={userData} />
            <Container fluid className="App with-sidebar bg-light-pf23 rounded-bottom min-vh-100">
                <Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
                <UploadFileModal
                    client={client}
                    showModal={showUploadFileModal}
                    setShowModal={setShowUploadFileModal}
                    handleUpload={handleFileUpload}
                // error={error}
                />
                <section></section>
                <Row className='px-3 py-1 bg-white d-flex align-items-center'>
                    <Col sm={12} md={6}>
                        <header className="bg-white pt-2">
                            <h1 className='text-start'>Project files</h1>
                        </header>
                    </Col>
                    <Col sm={12} md={6} className='d-flex justify-content-md-end justify-content-sm-start'>
                        <Button
                            className='rounded-pill'
                            variant='pf23'
                            onClick={(e) => setShowUploadFileModal(true)}
                        >
                            Upload file
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-start filter-files-checkboxes pt-2 px-4'>
                        <h5>Filter by group name:</h5>
                        <label className='pe-2'>
                            <input type="checkbox" value="Public files" checked={groupNameFilters.includes('Public files')} onChange={handleGroupNameFilterChange} />
                            Public files
                        </label>
                        <label className='px-2'>
                            <input type="checkbox" value="My files" checked={groupNameFilters.includes('My files')} onChange={handleGroupNameFilterChange} />
                            My files
                        </label>
                        <label className='px-2'>
                            <input type="checkbox" value="My team files" checked={groupNameFilters.includes('My team files')} onChange={handleGroupNameFilterChange} />
                            My team files
                        </label>
                    </Col>
                </Row>
                <Row className='user-files px-3'>
                    <Tabs
                        defaultActiveKey="spider-configs"
                        id="justify-tab-files"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3 fs-5 mt-3 border rounded-0 user-files-tabs px-0"
                        justify
                    >
                        <Tab eventKey="spider-configs" title="Python">
                            <FilesTable
                                client={client}
                                userId={userData.id}
                                filesType="spider-configs"
                                groupNameFilters={groupNameFilters}
                                maxPagination={10}
                                selectedKey={key}
                                refreshData={refreshData}
                            />
                        </Tab>
                        <Tab eventKey="yaml-configs" title="Yaml">
                            <FilesTable
                                client={client}
                                userId={userData.id}
                                filesType="yaml-configs"
                                groupNameFilters={groupNameFilters}
                                maxPagination={10}
                                selectedKey={key}
                                refreshData={refreshData}
                            />
                        </Tab>
                        <Tab eventKey="template-files" title="Template">
                            <FilesTable
                                client={client}
                                userId={userData.id}
                                filesType="template-files"
                                groupNameFilters={groupNameFilters}
                                maxPagination={10}
                                selectedKey={key}
                                refreshData={refreshData}
                            />
                        </Tab>
                    </Tabs>
                </Row>
            </Container>
        </>
    )
}

export default UserFiles;
