import React from "react";
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// props.showModal, props.setShowModal -> useEffect() with show/hide modal
// props.handleContinue -> function triggered on 'continue' button

const ProjectCreatedModal = (props) => {
    const navigate = useNavigate();

    return (
        <Modal show={props.showModal} backdrop="static" onHide={(e) => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>New project created</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your new project is created. You can now continue to manage project's spiders or stay on the current page.
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="dark"
                    onClick={(e) => navigate("../my-spiders")}
                >
                    Continue
                </Button>
                <Button 
                variant="modal"
                    onClick={(e) => props.setShowModal(false)}
                >
                    Stay here
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default ProjectCreatedModal;