import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Navbar from '../../components/Navbar';
import ListGroup from 'react-bootstrap/ListGroup';

import config from '../../config';
import Sidebar from '../../components/Sidebar';


const ProfilePage = ({ title, userData }) => {
	const { apiBasePath } = config;

	useEffect(() => {
		document.title = title;
	}, [title]);

	return (
		<>
			<Sidebar userData={userData} />
			<Container fluid className='App with-sidebar bg-light-pf23 rounded-bottom min-vh-100'>
				<Navbar userData={userData} goBackButton={true} loggingButtons={true}></Navbar>
				<Row className='justify-content-center align-items-center App-header'>
					<Col lg={4} md={6} xs={10}>
						<h1>Profile page</h1>
						<Form>
							<Form.Group className="mb-3">
								<Form.Label>User ID</Form.Label>
								<Form.Control disabled type="text" placeholder="Password" value={userData.id} />
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>First name</Form.Label>
								<Form.Control disabled type="text" placeholder="First name" value={userData.first_name} />
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Last name</Form.Label>
								<Form.Control disabled type="text" placeholder="Last name" value={userData.last_name} />
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Email address</Form.Label>
								<Form.Control disabled type="text" placeholder="Enter email" value={userData.email} />
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Groups</Form.Label>
								<Form.Control disabled type="text" placeholder="Groups" value={userData.group_names} />
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label>Password</Form.Label>
								<Form.Text className="text-muted">
									<Form.Control disabled type="text" placeholder="<algorithm>$<iterations>$<salt>$<hash>" value='' />
									You canot see your password because it is using PBKDF2 hashing algorithm. You can change the password using  <Link to="/change-password" className='pf23-color'>this form</Link>.
								</Form.Text>
								<Row>
									<Col>
										<Form.Label>
											Admin
										</Form.Label>
										<Form.Control disabled type="text" placeholder="Superuser" value={userData.is_superuser} />
									</Col>
								</Row>
								{userData.is_superuser && (
									<ListGroup variant="pf23" className='pb-2'>
										<span className='py-3'>Admin links</span>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}sflab-admin`} target='_blank'>Admin panel</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/projects/myprojects`} target='_blank'>Projects</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/spiders/spiders`} target='_blank'>Spiders</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/files/spider-configs`} target='_blank'>Spider Config files</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/files/yaml-configs`} target='_blank'>Yaml config files</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/files/template-files`} target='_blank'>Template files</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/files/eretailers`} target='_blank'>Eretailers</ListGroup.Item>
										<ListGroup.Item action variant='pf23' href={`${apiBasePath}api/tasks/task-results`} target='_blank'>Task results</ListGroup.Item>
									</ListGroup>
								)}
							</Form.Group>
						</Form>
					</Col>
				</Row>
			</Container></>
	)
}

export default ProfilePage;
