import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import moment from 'moment';
import 'moment-timezone'; 

import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Modal from 'react-bootstrap/Modal';

import { CSVLink } from "react-csv";
import Spinner from 'react-bootstrap/Spinner';
import Col from "react-bootstrap/esm/Col";
import OutputTable from "../OutputTable";
import { ReactComponent as DeleteIcon } from '../../assets/icon09_delete.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Alert from 'react-bootstrap/Alert';
import TaskResultsTable from "../tables/TaskResultsTable";

import Delayed from "../DelayedComponent";
// props.client
// props.task
// props.showModal, props.setShowModal -> useEffect() with show/hide modal

const logsInterval = 10 //sec

const DetailedTaskResultModal = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isDataDownloaded, setIsDataDownloaded] = useState(false);

    const [taskResultData, setTaskResultData] = useState({});
    const [taskLogs, setTaskLogs] = useState([]);
    const [outputData, setOutputData] = useState([]);

    const [timeDiff, setTimeDiff] = useState("")
    const bottomRef = useRef(null);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            fetchTaskResultsData(props.task.task_id, setIsLoading, setTaskResultData);
            
        }
    }, [props.task, props.showModal])

    useEffect(() => {
        if (props.showModal) {
            calculateTimeDiff(setTimeDiff)
            const intervalId = ["STARTED", "SENT", "SUBMITTED"].includes(TaskResultsTable?.status) ? setInterval(calculateTimeDiff, 60000) : setInterval(calculateTimeDiff, 600000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [taskResultData])
    
    function fetchTaskResultsData(taskId, setIsLoading, setTaskResultData) {
        setIsLoading(true);
        props.client.getTaskResultsDetails(taskId).then(
            (response) => {
                setTaskResultData(response.data);
                setIsLoading(false);
            }
        )
    }

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [taskLogs])

    const fetchTaskLogs = useCallback(async () => {
        if (taskResultData && props.showModal) {
            console.log("fetching logs ...")
            props.client.getTaskResultLogs(taskResultData.job_id).then(
                (response) => {
                    if (response.data.length != taskLogs.length) {
                        setTaskLogs(response.data)
                    }
                }
            )
        }
    })

    useEffect(() => {
        if (taskResultData && props.showModal) {
            fetchTaskLogs();
        }
    }, [taskResultData])

    useEffect(() => {
        // fetchTaskLogs();
        const interval = setInterval(() => {
            if (taskResultData.status == "STARTED") {
                fetchTaskLogs()
            }
        }, logsInterval * 1000);
        return () => clearInterval(interval);
    }, [fetchTaskLogs])

    function fetchOutputData(taskId) {
        setIsFetchingData(true)
        setIsDataDownloaded(false)
        props.client.getTaskResultOutput(taskId)
            .then((response) => {
                // var handleObj = x => x.map(a => a.value);
                // var results = response.data.map(handleObj);
                // return results
                setOutputData(response.data);
                setIsFetchingData(false);
                setIsDataDownloaded(true);
            })
            .catch((error) => {
                console.log(error)
            });
    }
    
    function calculateTimeDiff(setTimeDiff) {
        // Set the time zone to Europe/Warsaw
        moment.tz.setDefault('Europe/Warsaw');
      
        const startMillis = taskResultData?.created_at
          ? moment(taskResultData.created_at, 'DD-MM-YYYY HH:mm').valueOf()
          : null;
      
        const finishMillis =
          taskResultData?.status === 'STARTED' || taskResultData?.status === 'SENT' || taskResultData?.status === 'SUBMITTED'
            ? moment().valueOf()
            : taskResultData?.done_at
              ? moment(taskResultData.done_at, 'DD-MM-YYYY HH:mm:ss').valueOf()
              : null;
      
        if (startMillis !== null && finishMillis !== null) {
          const diffMillis = finishMillis - startMillis;
          const diffDate = moment.duration(diffMillis);
      
          const hours = diffDate.hours();
          const minutes = diffDate.minutes();
      
          const formattedTimeDiff = hours + 'h ' + minutes + 'min';
      
          setTimeDiff(formattedTimeDiff);
        } else {
          setTimeDiff('Calculating duration...');
        }
      }
      

    const DeleteOutputIcon = () => {
        return (
            <OverlayTrigger trigger="click" rootClose placement="bottom"
                overlay={
                    <Popover id="popover-basic">
                        <Popover.Header as="h3">Delete the output?</Popover.Header>
                        <Popover.Body>
                            <Row className="px-3">
                                You won't be able to restore it.
                            </Row>
                            <Row className="mt-3 px-3">
                                <Col>
                                    <Button variant="modal" onClick={(e) => {
                                        props.client.deleteMongoDBTaskOutput(props.taskResult.task_id);
                                        setShowAlert(true);
                                        document.body.click();
                                        setOutputData([]);
                                        setIsDataDownloaded(false);
                                        setTimeout(() => {
                                            setShowAlert(false);
                                        }, 1500);
                                    }}>
                                        Confirm
                                    </Button>
                                </Col>
                                <Col>
                                    <Button variant="dark" onClick={(e) => { document.body.click() }}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button
                    size="md"
                    type="button"
                    variant="none"
                    onClick={() => { }}
                >
                    {<DeleteIcon className='table-icon' />}
                </Button>
            </OverlayTrigger>
        )
    }


    function closeModal() {
        props.setShowModal(false);
        setIsDataDownloaded(false);
        setIsLoading(true);
        setTaskLogs([])
    }

    return (
        <Modal size="lg" show={props.showModal} onHide={(e) => closeModal()}>
            <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                Output deleted successfully.
            </Alert>
            <Modal.Header closeButton>
                <Modal.Title>Task result detailed view</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading &&
                    <Container>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Task ID:
                            </Col>
                            <Col>
                                {taskResultData.task_id}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Job ID:
                            </Col>
                            <Col>
                                {taskResultData.job_id ? taskResultData.job_id : "N/A"}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Schedule:
                            </Col>
                            <Col >
                                {taskResultData.crontab_string ? taskResultData.crontab_string : "oneoff"}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                User:
                            </Col>
                            <Col >
                                {taskResultData.user_email}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Date started:
                            </Col>
                            <Col >
                                {taskResultData.created_at}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Date finished:
                            </Col>
                            <Col >
                                {["STARTED", "SENT", "SUBMITTED"].includes(taskResultData.status) &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                                {!["STARTED", "SENT", "SUBMITTED"].includes(taskResultData.status) && taskResultData.done_at}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Duration:
                            </Col>
                            <Col >
                                {timeDiff}
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                MongoDB / Collection name:
                            </Col>
                            <Col>
                                {taskResultData.mongodb_database} | {taskResultData.mongodb_collection}
                            </Col>
                        </Row>
                        <Row className="my-4" style={{ backgroundColor: "gray", color: "white", overflowY: "scroll", height: "12rem" }}>
                            <Delayed waitBeforeShow={300}>
                                {isLoading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <>
                                        <div style={{ whiteSpace: "pre-wrap" }}>
                                        {taskLogs.length === 0 ? (
                                            <div>Logs are not available</div>
                                        ) : (
                                            taskLogs.map((logs, index) => (
                                                <div key={index}>{"> "}{logs}</div>
                                            ))
                                        )}
                                        </div>
                                        <div ref={bottomRef} />
                                    </>
                                )}
                            </Delayed>
                        </Row>
                        <Row className="my-4">
                            <Col className="d-flex align-items-center">
                                Results from MongoDB:
                            </Col>
                            <Col>
                                {!isDataDownloaded && taskResultData.status == "STARTED" &&
                                    <Button
                                        variant='dark'
                                        size="md"
                                        type="button"
                                        className="rounded-pill"
                                        disabled
                                    >
                                        Scrapping data ...
                                    </Button>
                                }
                                {!isFetchingData && !isDataDownloaded && taskResultData.status != "STARTED" &&
                                    <>
                                    <Button
                                        variant='modal'
                                        size="md"
                                        type="button"
                                        className="rounded-pill"
                                        onClick={(e) => {
                                            fetchOutputData(taskResultData.task_id);
                                        }}
                                    >
                                        Load
                                    </Button>
                                    {/* <DeleteOutputIcon /> */}
                                    </>
                                }
                                {isFetchingData &&
                                    <Button
                                        variant='modal'
                                        size="md"
                                        type="button"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            style={{ 'color': 'var(--pf23-color)' }}
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                }
                                {isDataDownloaded &&
                                    <>
                                    <CSVLink
                                        className="btn btn-modal btn-md"
                                        style={{ backgroundColor: 'var(--pf23-color)', color: 'white', borderRadius: '50rem' }}
                                        size="md"
                                        // Render the CSVLink component with the function to convert object values to stringified format with a ';' delimiter
                                        data={outputData.map(row => {
                                            return Object.entries(row).reduce((acc, [key, value]) => {
                                                if (typeof value === 'object') {
                                                    return { ...acc, [key]: JSON.stringify(value).replace(/,/g, ';') }
                                                }
                                                return { ...acc, [key]: value }
                                            }, {})
                                        })}
                                        filename={taskResultData.task_id + ".csv"}>
                                        Save to CSV
                                    </CSVLink>
                                    {/* <DeleteOutputIcon /> */}
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row className="overflow-auto sample-output">
                            {isDataDownloaded &&
                                <OutputTable
                                    outputData={outputData}
                                />
                            }
                        </Row>
                    </Container>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="dark"
                    className="rounded-pill"
                    onClick={(e) => closeModal()}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default DetailedTaskResultModal;