import {React, useState, useEffect, useContext} from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { ReactComponent as RunIcon } from '../assets/icon07_run.svg';

import WarningModal from './modals/WarningModal';

// props.handleRun -> function triggered with delete icon
// props.warningBodyText -> body text with warning before deleting

const RunIconWithWarning = (props) => {
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    
    return (
        <Container>
            <WarningModal 
                showModal={showDeleteWarning}
                setShowModal={setShowDeleteWarning}
                handleConfirm={props.handleRun}
                bodyText={props.warningBodyText}
                backdrop="static"
            />
            <Row>
                <Col>
                    <Button
                        variant='none'
                        size="md"
                        type="button"
                        onClick={(e) => setShowDeleteWarning(true)}
                    >
                        {<RunIcon className='table-icon'/>}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default RunIconWithWarning;