import React from "react";
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';

const HomepageFooter = () => {
    const navigate = useNavigate()
    
    const toDocumentation = () => {
        navigate("/documentation")
      }
      const toQuickstart = () => {
        navigate("/quick-start")
      }
      const toFAQ = () => {
        navigate("/frequently-asked-questions")
      }
      // const toEretailer = () => {
      //   navigate("/eretailers")
      // }

    return(
        <Row>
        <div className='bg-white d-flex flex-md-row flex-column justify-content-between bg-light-pf23 rounded-bottom fw-bold hyperlinks p-4 fs-5'>
          <a role="button" onClick={toQuickstart} className='text-dark text-uppercase'>Quickstart</a>
          <a role="button" onClick={toDocumentation} className='text-dark text-uppercase documentation'>Documentation</a>
          <a role="button" onClick={toFAQ} className='text-dark text-uppercase'>FAQ</a>
          {/* <a role="button" onClick={toEretailer} className='text-dark text-uppercase'>Eretailers</a> */}
        </div>
      </Row>
    );
}

export default HomepageFooter;
