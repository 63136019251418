import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const PageNotFound= ({title}) =>{
    const navigate = useNavigate()

    useEffect(() => {
      // This will run when the page first loads and whenever the title changes
      document.title = title;
    }, [title]);

    function toHomePage(){
        navigate("/")
      }

    return(
    <section className='App bg-light min-vh-100 d-flex flex-column align-items-center justify-content-center'>
        <h1>404 Error</h1>
        <h1>Page Not Found</h1>
        <div><Button variant='pf23' onClick={toHomePage} className='mt-3'>Go to homepage</Button></div>
    </section>
    )
}
  
export default PageNotFound;